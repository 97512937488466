import { FC } from "react";

import LandingHeader from "./Header/LandingHeader";
import ServicePreview from "./ServicePreview/ServicePreview";
import Features from "./Features/Features";
import TargetUsers from "./TargetUsers/TargetUsers";
import JoinUs from "./JoinUs/JoinUs";
import Footer from "./Footer/LandingFooter";

import "./Landing.scss";

const LandingPage: FC = () => {
  return (
    <div className="landing-wrapper">
      <LandingHeader />
      <div className="landing-container">
        <ServicePreview />
        <Features />
        <TargetUsers />
        <JoinUs />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
