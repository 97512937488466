import { FC, useEffect, useRef, useState } from "react";
import "./Tooltip.scss";
import StatusLabel from "../PageTableField/StatusLabel";
import Button from "../Inputs/Button/Button";

interface IProps {
  open: boolean;
  id: string;
  status: string;
  handleClose: () => void;
  handleSave: (status: string) => void;
}

const Tooltip: FC<IProps> = ({ open, id, status, handleClose, handleSave }) => {
  const [inputValue, setInputValue] = useState<string>(status);

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const outsideClick = (ev: MouseEvent) => {
      if (
        ev.target instanceof HTMLElement &&
        !tooltipRef.current?.contains(ev.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", outsideClick);
    return () => document.removeEventListener("mousedown", outsideClick);
  }, [handleClose]);

  if (!open) {
    return null;
  }

  return (
    <div ref={tooltipRef} className="status-label__tooltip-container">
      <div className="tooltip tooltip__menu">
        <div className="tooltip__menu-item">
          <div className="status-modal">
            <h5 className="status-modal__title">Выбрать статус:</h5>
            <fieldset>
              <label htmlFor="radioInputActive" className="status-modal__label">
                <input
                  type="radio"
                  id="radioInputActive"
                  className="status-modal__radio"
                  onChange={(evt) => setInputValue(evt.target.value)}
                  value="ACTIVE"
                  checked={inputValue === "ACTIVE"}
                />
                <span className="status-modal__radiomark" />

                <StatusLabel status={"ACTIVE"} />
              </label>
              <label
                htmlFor="radioInputInactive"
                className="status-modal__label"
              >
                <input
                  type="radio"
                  id="radioInputInactive"
                  className="status-modal__radio"
                  onChange={(evt) => setInputValue(evt.target.value)}
                  value="INACTIVE"
                  checked={inputValue === "INACTIVE"}
                />
                <span className="status-modal__radiomark">{}</span>
                <StatusLabel status={"INACTIVE"} />
              </label>

              <div className="status-modal__button-container">
                <Button
                  onClick={handleClose}
                  isDisabled={false}
                  label="Отмена"
                  color="light-blue"
                  width="fit-content"
                />
                <Button
                  onClick={() => handleSave(inputValue)}
                  isDisabled={false}
                  label="Сохранить"
                  color={"blue"}
                  width="fit-content"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
