export const _data = {
  poolId1: [
    {
      reqCount: 10,
      timeStamp: "Sat Jul 23 00:00:00",
    },
    {
      reqCount: 13,
      timeStamp: "Sat Jul 23 01:00:00",
    },
    {
      reqCount: 14,
      timeStamp: "Sat Jul 23 02:00:00",
    },
    {
      reqCount: 8,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 03:00:00",
    },
    {
      reqCount: 12,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 04:00:00",
    },
    {
      reqCount: 40,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 05:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 06:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 07:00:00",
    },
    {
      reqCount: 11,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 08:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 09:00:00",
    },
    {
      reqCount: 31,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 10:00:00",
    },
    {
      reqCount: 25,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 11:00:00",
    },
    {
      reqCount: 101,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 12:00:00",
    },
    {
      reqCount: 12,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 13:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 14:00:00",
    },
    {
      reqCount: 45,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 15:00:00",
    },
    {
      reqCount: 65,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 16:00:00",
    },
    {
      reqCount: 35,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 17:00:00",
    },
    {
      reqCount: 95,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 18:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 19:00:00",
    },
    {
      reqCount: 12,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 20:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 21:00:00",
    },
    {
      reqCount: 3,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 22:00:00",
    },
    {
      reqCount: 25,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 23:00:00",
    },
  ],
  poolId2: [
    {
      reqCount: 10,
      timeStamp: "Sat Jul 23 00:00:00",
    },
    {
      reqCount: 13,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 01:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 02:00:00",
    },
    {
      reqCount: 8,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 03:00:00",
    },
    {
      reqCount: 62,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 04:00:00",
    },
    {
      reqCount: 90,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 05:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 06:00:00",
    },
    {
      reqCount: 143,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 07:00:00",
    },
    {
      reqCount: 11,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 08:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 09:00:00",
    },
    {
      reqCount: 11,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 10:00:00",
    },
    {
      reqCount: 25,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 11:00:00",
    },
    {
      reqCount: 31,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 12:00:00",
    },
    {
      reqCount: 121,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 13:00:00",
    },
    {
      reqCount: 54,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 14:00:00",
    },
    {
      reqCount: 35,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 15:00:00",
    },
    {
      reqCount: 5,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 16:00:00",
    },
    {
      reqCount: 55,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 17:00:00",
    },
    {
      reqCount: 95,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 18:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 19:00:00",
    },
    {
      reqCount: 88,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 20:00:00",
    },
    {
      reqCount: 103,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 21:00:00",
    },
    {
      reqCount: 32,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 22:00:00",
    },
    {
      reqCount: 25,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 23:00:00",
    },
  ],
  poolId3: [
    {
      reqCount: 10,
      timeStamp: "Sat Jul 23 00:00:00",
    },
    {
      reqCount: 131,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 01:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 02:00:00",
    },
    {
      reqCount: 34,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 03:00:00",
    },
    {
      reqCount: 12,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 04:00:00",
    },
    {
      reqCount: 81,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 05:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 06:00:00",
    },
    {
      reqCount: 14,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 07:00:00",
    },
    {
      reqCount: 23,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 08:00:00",
    },
    {
      reqCount: 10,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 09:00:00",
    },
    {
      reqCount: 31,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 10:00:00",
    },
    {
      reqCount: 21,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 11:00:00",
    },
    {
      reqCount: 1,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 12:00:00",
    },
    {
      reqCount: 12,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 13:00:00",
    },
    {
      reqCount: 141,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 14:00:00",
    },
    {
      reqCount: 89,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 15:00:00",
    },
    {
      reqCount: 65,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 16:00:00",
    },
    {
      reqCount: 35,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 17:00:00",
    },
    {
      reqCount: 105,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 18:00:00",
    },
    {
      reqCount: 1,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 19:00:00",
    },
    {
      reqCount: 112,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 20:00:00",
    },
    {
      reqCount: 30,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 21:00:00",
    },
    {
      reqCount: 20,
      userPoolId: "safds-1313-sads12",
      timeStamp: "Sat Jul 23 22:00:00",
    },
    {
      reqCount: 100,
      userPoolId: "safsdfds-1313-sads12",
      timeStamp: "Sat Jul 23 23:00:00",
    },
  ],
};

export const options = {
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        stepSize: 20,
      },
    },
  },
};

export const colors = ["82, 130, 253", "41, 165, 75", "223, 37, 31", "69, 148, 139", "85,89,157"];
export interface IChartItem {
    reqCount: number,
    userPoolId?: string,
    timeStamp?: number,

}

export const dataPerMonth = [
  {
    reqCount: 10,
    timeStamp: "Sat Jul 1 00:00:00",
  },
  {
    reqCount: 13,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 2 01:00:00",
  },
  {
    reqCount: 14,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 3 02:00:00",
  },
  {
    reqCount: 8,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 4 03:00:00",
  },
  {
    reqCount: 12,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 5 04:00:00",
  },
  {
    reqCount: 80,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 6 05:00:00",
  },
  {
    reqCount: 10,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 7 06:00:00",
  },
  {
    reqCount: 14,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 8 07:00:00",
  },
  {
    reqCount: 11,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 9 08:00:00",
  },
  {
    reqCount: 10,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 10 09:00:00",
  },
  {
    reqCount: 31,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 11 10:00:00",
  },
  {
    reqCount: 25,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 12 11:00:00",
  },
  {
    reqCount: 101,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 13 12:00:00",
  },
  {
    reqCount: 12,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 14 13:00:00",
  },
  {
    reqCount: 14,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 15 14:00:00",
  },
  {
    reqCount: 45,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 16 15:00:00",
  },
  {
    reqCount: 65,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 17 16:00:00",
  },
  {
    reqCount: 35,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 18 17:00:00",
  },
  {
    reqCount: 95,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 19 18:00:00",
  },
  {
    reqCount: 14,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 20 19:00:00",
  },
  {
    reqCount: 12,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 21 20:00:00",
  },
  {
    reqCount: 10,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 22 21:00:00",
  },
  {
    reqCount: 3,
    userPoolId: "safds-1313-sads12",
    timeStamp: "Sat Jul 23 22:00:00",
  },
  {
    reqCount: 25,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 24 23:00:00",
  },
  {
    reqCount: 21,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 25 23:00:00",
  },
  {
    reqCount: 3,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 26 23:00:00",
  },
  {
    reqCount: 1,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 27 23:00:00",
  },
  {
    reqCount: 31,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 28 23:00:00",
  },
  {
    reqCount: 25,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 29 23:00:00",
  },
  {
    reqCount: 25,
    userPoolId: "safsdfds-1313-sads12",
    timeStamp: "Sat Jul 30 23:00:00",
  },
];
