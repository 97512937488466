export const lang: LangType = {
  Eng: {
    navmenu: {
      dashboard: 'Dashboard',
      pools: 'User Pools',
      users: 'Users',
      policy: 'Policy',
      binding: 'Binding',
      documentation: 'Documentation'
    },
    dashboard: {
      welcomes: 'Wellcome',
      pools: 'User Pools',
      users: 'Users',
      policy: 'Policy',
      load: 'Load schedule',
      poolsDropdown: 'Select a user pool'
    },
  },
  Рус: {
      navmenu: {
        dashboard: 'Сводная информация',
        pools: 'Пуллы пользователей',
        users: 'Пользователи',
        policy: 'Правила',
        binding: 'Привязка',
        documentation: 'Документация'
      },
      dashboard: {
        welcomes: 'Добро пожаловать',
        pools: 'Пуллы пользователей',
        users: 'Пользователи',
        policy: 'Правила',
        load: 'График нагрузки',
        poolsDropdown: 'Выберете пулл пользователей'
      },
   }
}

type LangType = {
  [key: string]: any
};