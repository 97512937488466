import React, { FC } from "react";
import { TablePagination as TablePaginationMUi } from "@mui/base/TablePagination";
import { useAppDispatch } from "../../store/store";

import "./TablePagination.scss";

const TablePagination: FC<IProps> = ({ pagination }) => {
  const dispatch = useAppDispatch();

  const { total_items, per_page, current_page, setFilters } = pagination;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      setFilters({
        filters: {
          current_page: newPage,
        },
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setFilters({
        filters: {
          current_page: 0,
          per_page: parseInt(event.target.value, 10),
        },
      })
    );
  };

  return (
    <>
      <div className="table-pagination">
        <table aria-label="custom pagination table">
          <tfoot>
            <tr>
              <TablePaginationMUi
                className="table-pagination__item"
                rowsPerPageOptions={[10, 20, 50, 100]}
                colSpan={3}
                count={total_items}
                rowsPerPage={per_page}
                page={current_page}
                slotProps={{
                  select: {
                    "aria-label": "Строк на станице",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                labelRowsPerPage={"Строк на станице"}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default TablePagination;

interface IProps {
  total_items?: number;
  handlePaginationChangePage?: (perPage: number, currentPage: number) => void;
  pagination?: any;
}
