import React, { FC, useState } from "react";
import cn from "classnames";
import { useAppSelector } from "../../../../store/store";
import { IRefreshedApiKeyRequest } from "../../../../store/reducers/pools/pools-types";
import {
  getIsLoading,
  getPoolById,
  getRefreshApiKey,
} from "../../../../store/reducers/pools/pools-selectors";
import Button from "../../../../components/Inputs/Button/Button";

const ModalApiKeyContent: FC<IProps> = ({ handleSubmit, handleClose }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { poolById } = useAppSelector(getPoolById);
  const isLoading = useAppSelector(getIsLoading);
  const refreshApiKey = useAppSelector(getRefreshApiKey);

  if (isCopied) {
    setTimeout(setIsCopied, 3000);
  }

  const handleSaveToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).catch((e) => console.error(e));
  };

  const handleCopyClick = () => {
    poolById?.user_pool_api_key &&
      handleSaveToClipboard(
        refreshApiKey?.api_key || poolById?.user_pool_api_key
      );
    setIsCopied(true);
  };

  return (
    <div className="modal-with-inputs modal-with-inputs_type_apikey">
      {poolById && (
        <>
          <h4 className="modal-with-inputs__modal-title">
            Ваш user pool api key
          </h4>

          <div className="modal-with-inputs__copy-container">
            <input
              className="modal-with-inputs__copy-input"
              type="text"
              value={
                isLoading
                  ? ""
                  : refreshApiKey?.api_key || poolById?.user_pool_api_key
              }
            />
            <Button
              onClick={handleCopyClick}
              width="fixed"
              color={isCopied ? "green" : "light-blue"}
              label={isCopied ? "Скопировано" : "Копировать"}
              alignSelf="normal"
            />
          </div>

          <div className="modal-with-inputs__copy-message-container">
            <p
              className={`modal-with-inputs__copy-message ${cn(
                "successful-refreshed",
                {
                  isRefreshed: refreshApiKey?.status === 200,
                }
              )}`}
            >
              Ваш api key успешно обновлен!
            </p>
            <p
              className={`modal-with-inputs__copy-message ${cn(
                "updating-refreshed",
                {
                  isLoading,
                }
              )}`}
            >
              Обновление api key...
            </p>
          </div>

          <div className="modal-with-inputs__modal-button-container">
            <Button
              onClick={handleClose}
              label={refreshApiKey?.status === 200 ? "Закрыть" : "Отмена"}
              isDisabled={false}
              color="white"
              width="fixed"
            />

            <div
              className={`${cn("successful-refreshed-button", {
                isRefreshed: refreshApiKey?.status === 200,
              })}`}
            >
              <Button
                onClick={() => {
                  handleSubmit({
                    user_pool_uuid: poolById.id,
                    api_key: poolById.user_pool_api_key,
                  });
                }}
                label="Обновить ключ"
                width="fit-content"
                color="blue"
                isDisabled={false}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ModalApiKeyContent;

interface IProps {
  handleSubmit: (apiKeyValues: IRefreshedApiKeyRequest) => void;
  handleClose: () => void;
}
