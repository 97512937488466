import React, { FC } from "react";

import ModalUserPoolsInputs from "../ModalUserPools/ModalContent/ModalUserPoolsInputs";
import ModalContainer from "../ModalContainer";
import ConfigContent from "./ModalContent/ConfigContent";

import { useAppDispatch } from "../../../store/store";
import {
  updateHttpPolicy,
  updateOpenapiPolicyFields,
  updatePolicyGroup,
} from "../../../store/reducers/policy/policy-reducer";
import {
  IHttpPolicy,
  IModifiedHttpMethods,
  IOpenapiPolicyRequest,
  IPoliciesGroupRequest,
  ISwaggerSpec,
} from "../../../store/reducers/policy/policy-types";
import "./ModalPolicy.scss";

const ModalConfigureAndEditPolicy: FC<IPros> = ({
  isModalOpen,
  handleClose,
  policiesById = null,
  modalType,
}) => {
  const dispatch = useAppDispatch();

  const onModalClose = () => {
    handleClose();
    // setModalState(initialState);
  };

  const handleSubmit = (value: IModifiedHttpMethods | ISwaggerSpec) => {
    if (policiesById && policiesById.type === "http") {
      dispatch(
        updateHttpPolicy({
          id: policiesById.id,
          body: {
            ...value,
            title: policiesById.title,
            user_pool_id: policiesById.user_pool_id,
          },
        }),
      );
      onModalClose();
    }
    if (
      policiesById &&
      policiesById.type === "openapi" &&
      "defaults" in policiesById
    ) {
      dispatch(
        updateOpenapiPolicyFields({
          id: policiesById.id,
          body: {
            title: policiesById.title,
            id: policiesById.id,
            user_pool_id: policiesById.user_pool_id,
            owner_id: policiesById.owner_id,
            type: "openapi",
            defaults: policiesById.defaults,
            endpoints: value as ISwaggerSpec,
          },
        }),
      );
      onModalClose();
    }
  };

  const handleEditHttpTitle = (inputValue: string, _: string) => {
    if (policiesById && policiesById.type === "http") {
      const request = { ...policiesById };

      dispatch(
        updateHttpPolicy({
          id: request.id,
          body: {
            ...request,
            title: inputValue,
            user_pool_id: policiesById.user_pool_id,
          },
        }),
      );
      onModalClose();
    }
  };

  const handleEditOpenapiTitle = (value: string, _: string) => {
    if (
      value &&
      policiesById &&
      "defaults" in policiesById &&
      "endpoints" in policiesById
    )
      dispatch(
        updateOpenapiPolicyFields({
          id: policiesById.id,
          body: {
            title: value,
            user_pool_id: policiesById.user_pool_id,
            owner_id: policiesById.owner_id,
            type: "openapi",
            id: policiesById.id,
            defaults: policiesById.defaults,
            endpoints: policiesById.endpoints,
          },
        }),
      );
    onModalClose();
  };

  const handleEditGroupTitle = (inputValue: string, _: string) => {
    if (policiesById && inputValue)
      dispatch(
        updatePolicyGroup({
          id: policiesById.id,
          body: {
            title: inputValue,
            user_pool_id: policiesById.user_pool_id,
          },
        }),
      );
    onModalClose();
  };

  return (
    <div>
      <ModalContainer isOpen={isModalOpen} handleClose={onModalClose}>
        {modalType === "configurePolicy" &&
          policiesById &&
          (policiesById.type === "openapi" || policiesById.type === "http") && (
            <ConfigContent
              width="45rem"
              onSubmit={handleSubmit}
              policyItem={policiesById}
              handleClose={handleClose}
            />
          )}

        {modalType === "editPolicy" &&
          policiesById &&
          policiesById.type === "http" && (
            <ModalUserPoolsInputs
              handleSubmit={handleEditHttpTitle}
              modalType="editHttpPolicy"
              title={policiesById.title}
              handleClose={handleClose}
            />
          )}

        {modalType === "editPolicy" &&
          policiesById &&
          policiesById.type === "openapi" && (
            <ModalUserPoolsInputs
              handleSubmit={handleEditOpenapiTitle}
              modalType="editOpenapiPolicy"
              title={policiesById.title}
              handleClose={handleClose}
            />
          )}

        {modalType === "editPolicy" &&
          policiesById &&
          policiesById.type === "group" && (
            <ModalUserPoolsInputs
              handleSubmit={handleEditGroupTitle}
              modalType="editGroupPolicy"
              title={policiesById.title}
              handleClose={handleClose}
            />
          )}
      </ModalContainer>
    </div>
  );
};

export default ModalConfigureAndEditPolicy;

interface IPros {
  isModalOpen: boolean;
  handleClose: () => void;
  policiesById?:
    | IOpenapiPolicyRequest
    | IHttpPolicy
    | IPoliciesGroupRequest
    | null;
  modalType: "configurePolicy" | "editPolicy";
}
