import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";

import SearchHeader from "../../components/PagesHeader/SearchHeader";
import PageTable from "../../components/PageTable/PageTable";
import ModalUsers from "../../components/Modals/ModalUsers/ModalUsers";
import Preloader from "../../components/Preloader/Preloader";

import { requestPools } from "../../store/reducers/pools/pools-reducer";
import {
  clearUsersState,
  requestUsersByPool,
  setFilters,
  updateUserStatus,
} from "../../store/reducers/users/users-reducer";
import { getPools } from "../../store/reducers/pools/pools-selectors";
import {
  users,
  usersFilters,
  usersIsLoading,
} from "../../store/reducers/users/users-selectors";

import {
  IConstantFilters,
  RowStatus,
  TypePageTable,
  filterUsers,
  mapPoolsToFilters,
} from "../../constants/constants";

import "./Users.scss";

export type ModalState = {
  isOpen: boolean;
  detail: boolean;
};

const Users: FC = () => {
  const dispatch = useAppDispatch();

  const userFilters = useAppSelector(usersFilters);
  const userIsLoading = useAppSelector(usersIsLoading);
  const usersList = useAppSelector(users);
  const { pools } = useAppSelector(getPools);

  const [updateStatusData, setUpdateStatusData] = useState<RowStatus>();
  const [modal, setModal] = useState<ModalState>({
    isOpen: false,
    detail: false,
  });

  const editUserStatus = (status: RowStatus) => {
    setUpdateStatusData(status);
    setModal({
      isOpen: true,
      detail: true,
    });
  };

  const onModalClose = () =>
    setModal({
      isOpen: false,
      detail: false,
    });

  const onModalInputSubmit = (input: string, modalType: string) => {
    if (
      modalType === "details" &&
      updateStatusData?.PoolId &&
      updateStatusData?.UserId
    ) {
      dispatch(
        updateUserStatus({
          poolId: updateStatusData.PoolId,
          userId: updateStatusData.UserId,
          body: {
            new_status: updateStatusData.status,
            details: input.trim(),
          },
        })
      );
      setModal({
        isOpen: false,
        detail: false,
      });
    }
  };

  const filtersPools: IConstantFilters[] | null = useMemo(() => {
    return mapPoolsToFilters(pools);
  }, [pools]);

  useEffect(() => {
    dispatch(requestPools({ per_page: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(requestUsersByPool(userFilters));
  }, [dispatch, userFilters]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch]
  );

  return userFilters.user_pools_ids ? (
    <div className="users page">
      <SearchHeader
        primaryFilters={filterUsers}
        secondaryFilters={{
          label: "Пул",
          type: "user_pools_ids",
          list: filtersPools,
        }}
        setFilters={setFilters}
      />

      {usersList?.users?.length > 0 && (
        <PageTable
          dataTable={usersList?.users}
          type={TypePageTable?.users}
          handleEdit={() => console.log("users-edit")}
          handleStatus={editUserStatus}
        />
      )}

      <ModalUsers
        modal={modal}
        onModalClose={onModalClose}
        onModalInputSubmit={onModalInputSubmit}
      />

      <Preloader isLoading={userIsLoading} />
    </div>
  ) : (
    <h1 className="users__waiting-title">Загрузка данных...</h1>
  );
};

export default Users;
