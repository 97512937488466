import React, { FC } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { atelierLakesideLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { nnfx } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";

const str = `const options = {
  url, // URL сервера аутентификации
  key, // Ключ для заголовка x-api-key
  owner_id, // ID владельца
  user_pool_id, // ID пула пользователей
};

const authService = new AuthService(options);`;
const str2 = `const body = {
  username,
  email,
  phone,
  password,
};

authService.register(body);`;
const str3 = `const body = {
  username,
  password,
};

authService.login(body);`;
const str4 = `register(url, key, body); 
login(url, key, body);
logout(url, refreshToken);
checkAuth(url, accessToken);
refresh(url, refreshToken);
auth(url, accessToken, refreshToken);`;

const str5 = `const config = {
  url, // URL сервера аутентификации
  key, // Ключ для заголовка x-api-key
  owner_id, // ID владельца
  user_pool_id, // ID пула пользователей
};

export default function App() {
  return (
    <ProviderAuth config={config}>
      <Example />
    </ProviderAuth>
  );
}`;

const str6 = `const { isAuth, isLoading, isError, error, data, refetch } = useRegister({
  username,
  email,
  phone,
  password,
});`;
const str7 = `const { isAuth, isLoading, isError, error, data, refetch } = useLogin({
  username,
  password,
});
`;
const str8 = `const { isAuth, isLoading, isError, error, data, refetch } = useLogout();`;
const str9 = `const { isAuth, isLoading, isError, error, data, refetch } = useAuth();`;
const str10 = `const config = {
  url, // URL сервера аутентификации
  key, // Ключ для заголовка x-api-key
  owner_id, // ID владельца
  user_pool_id, // ID пула пользователей
};

const {
  isAuth,
  isError,
  isLoading,
  error,
  tokens,
  data,
  getLogin,
  getLogout,
  getAuth,
  getTokens,
} = useAuthorization(config);`;

const DocTitle = ({ title }: { title: string }) => (
  <h3 style={{ margin: "1rem 0" }}>
    <strong>{title}</strong>
  </h3>
);

const JavaScriptDoc: FC = () => {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <h2 style={{ marginBottom: "1.5rem" }}>JavaScript библиотека</h2>
      <h4 style={{ marginBottom: "1rem" }}>
        Библиотека для взаимодействия с сервисом аутентификации
      </h4>
      <DocTitle title={"stl-auth-core"} />
      Пакет stl-auth-core для JavaScript.
      <DocTitle title={"Установка:"} />
      npm install stl-auth-core
      <DocTitle title={"Интеграция в проект:"} />
      Сервис может использоваться двумя способами:
      <DocTitle title={"1. Создание объекта"} />
      <SyntaxHighlighter language="javascript" style={vs}>
        {str}
      </SyntaxHighlighter>
      <p style={{ margin: "1rem 0" }}>
        Взаимодействие с сервисом осуществляется через методы объекта
        authService: <br />
        <li style={{ paddingLeft: "1rem" }}>Регистрация пользователя:</li>
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str2}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Вход в приложение:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str3}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Выход из приложения:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        authService.logout(refreshToken);
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>
        Проверка состояния текущей авторизации:
      </li>
      <SyntaxHighlighter language="javascript" style={vs}>
        authService.checkAuth(accessToken);
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Обновление токенов авторизации:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        authService.refresh(refreshToken);
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>
        Авторизационный метод, который проверяет текущий статус авторизации и, в
        случае истечения токена, выполняет запрос на получение новых токенов:
      </li>
      <SyntaxHighlighter language="javascript" style={vs}>
        authService.refresh(accessToken, refreshToken);
      </SyntaxHighlighter>
      <DocTitle
        title={
          "2. Использование независимых функций, повторяющих функционал первого способа:"
        }
      />
      <SyntaxHighlighter language="javascript" style={vs}>
        {str4}
      </SyntaxHighlighter>
      <DocTitle title={"stl-auth-react"} />
      Пакет stl-auth-react для React.
      <DocTitle title={"Установка:"} />
      <p style={{ padding: "0 0 1rem 0" }}>npm install stl-auth-react</p>
      Сервис может использоваться двумя способами:
      <DocTitle
        title={"1. Взаимодействие с сервисом с использованием хуков:"}
      />
      <p style={{ padding: "1rem 0" }}>
        Для интеграции в проект необходимо установить ProviderAuth:
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str5}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Регистрация пользователя:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str6}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Вход в приложение:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str7}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>Выход из приложения:</li>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str8}
      </SyntaxHighlighter>
      <li style={{ padding: "1rem" }}>
        Проверка состояния текущей авторизации и обновление токенов авторизации:
      </li>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str9}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>
        При использовании хуков useLogin и useAuth, в случае успешного
        выполнения, происходит запись токенов в cookie с ключами access_token и
        refresh_token.
      </p>
      <DocTitle
        title={"2. Взаимодействие с сервисом с использованием единого хука:"}
      />
      <SyntaxHighlighter language="javascript" style={vs}>
        {str10}
      </SyntaxHighlighter>
    </div>
  );
};

export default JavaScriptDoc;
