import { FC } from "react";
import { ModalUserPoolsState } from "../../../pages/UserPools/UserPools";
import ModalContainer from "../ModalContainer";
import ModalUserPoolsContent from "./ModalContent/ModalUserPoolsContent";
import ModalUserPoolsInputs from "./ModalContent/ModalUserPoolsInputs";
import { useAppSelector } from "../../../store/store";
import {
  getCreatedPool,
  getStatePools,
} from "../../../store/reducers/pools/pools-selectors";

const ModalUserPools: FC<ModalUserPoolsProps> = ({
  modal,
  onModalClose,
  createUserPoolSubmit,
}) => {
  const { isLoading } = useAppSelector(getStatePools);

  const newPool = useAppSelector(getCreatedPool);

  return (
    <ModalContainer isOpen={modal.isOpen} handleClose={onModalClose}>
      {modal.createSuccess && (
        <ModalUserPoolsContent
          isLoading={isLoading}
          onClick={onModalClose}
          userPool={newPool?.id}
        />
      )}

      {modal.create && (
        <ModalUserPoolsInputs
          modalType="create"
          handleSubmit={createUserPoolSubmit}
          handleClose={onModalClose}
        />
      )}
    </ModalContainer>
  );
};

export default ModalUserPools;

interface ModalUserPoolsProps {
  modal: ModalUserPoolsState;
  onModalClose: () => void;
  createUserPoolSubmit: (name: string) => void;
}
