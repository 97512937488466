import { FC } from "react";
// import cn from "classnames";

import UserPoolsStatusCell from "../../../../pages/UserPools/UserPoolsStatusCell/UserPoolsStatusCell";

import "./CustomTableCell.scss";

const CustomTableCell: FC<IProps> = ({ rowData, cellType, pageType }) => {
  const isPoolStatusCell = pageType === "pools" && cellType === "status";

  return (
    <div className="custom-table-cell">
      {isPoolStatusCell && <UserPoolsStatusCell rowData={rowData} />}
    </div>
  );
};

interface IProps {
  rowData: any;
  cellType: any;
  pageType: string;
}
export default CustomTableCell;
