import { Dispatch, FC, SetStateAction } from "react";
import cn from "classnames";
import "./StatusLabel.scss";
import { statusParser } from "../../constants/constants";

const StatusLabel: FC<IProps> = ({
  status,
  isClickable = false,
  onClick,
  ref,
}) => {
  const { color, label } = statusParser(status);

  return isClickable ? (
    <div className="status-label-container" ref={ref}>
      <div className={cn("status-label", [color])} onClick={onClick}>
        {label}
      </div>
    </div>
  ) : (
    <div className={cn("status-label", [color])}>{label}</div>
  );
};

export default StatusLabel;

interface IProps {
  status: string;
  isClickable?: boolean;
  onClick?: () => void;
  ref?: Dispatch<SetStateAction<any>>;
}
