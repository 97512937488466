import instance from './api'

import type { APIResponse } from './api'

import { ISignUpBody, IUserInfo, ISignInBody, ISignInResponce } from '../store/reducers/auth/auth-types'
import { getAccessToken, getRefreshToken } from '../store/functions'

const authApi = {
    async signUpOwner(body: ISignUpBody) {
        return await instance.post<IUserInfo>('owners/register', body)

    },

    async signInOwner(body: ISignInBody) {
        return await instance.post<ISignInResponce>('owners/login', body)
    },

    async getMyProfile() {
        const response = await instance.get<IUserInfo>('owners/me', {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
        return response
    },

    async refreshToken() {
		const response = await instance.get<ISignInResponce>('/owners/refresh', { headers: {
            Authorization: `Bearer ${getRefreshToken()}`,
        },})
		return response
	},
    async logOutOwner() {
		const response = await instance.post<APIResponse<string>>('/owners/logout', {})

		return response
	},
}
export default authApi
