import { FC } from "react";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";

import "./LangDropdown.scss";

const LangDropdown: FC<IProps> = ({ setLanguage }) => {
  const hanldeChangeLang = (value: string) => {
    setLanguage(value);
    window.location.reload();
  };
  return (
    <Dropdown>
      <MenuButton className="trigger-button">
        <i className="trigger-button__icon"></i>
      </MenuButton>
      <Menu className="dropdown">
        <MenuItem
          className="dropdown-item"
          onClick={() => hanldeChangeLang("Рус")}
        >
          <div className="dropdown-item__text">Русский</div>
        </MenuItem>
        <MenuItem
          className="dropdown-item"
          onClick={() => hanldeChangeLang("Eng")}
        >
          <div className="dropdown-item__text">English</div>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default LangDropdown;

interface IProps {
  setLanguage: (arg: string) => void;
}
