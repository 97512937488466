import axios from "axios";
import { toast } from "react-toastify";

export const leaveFeedback = ({id, text, grade, username}: any) =>
axios
  .post(
    "https://d5d3heetosevvh9cjrae.apigw.yandexcloud.net/api/v0/a4eab010-9833-432d-aff4-f3006be32ba0/16ec4708-5698-4572-a5a4-aed3d86da26e/reviews",
    { user_id: id, text, rating: grade, username },
    {
      headers: {
        " x-api-key": "9d02a625-65ba-47a5-9308-170d7c10510d",
      },
    }
  )
  .then((response) => {
    if (response.status === 200) {
      toast("Отзыв успешно отправлен. Спасибо за обратную связь!");
    }
  })
  .catch((error) => {
    toast.error("Что-то пошло не так, извините. Попробуйте ещё раз.");
  });