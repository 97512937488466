import { FC } from "react";
import { ModalUserPoolsState } from "../../../pages/UserPools/UserPools";
// import ModalContainer from "../ModalContainer";
import ModalUserPoolsContent from "./ModalContent/ModalUserPoolsContent";
import ModalUserPoolsInputs from "./ModalContent/ModalUserPoolsInputs";
import { useAppSelector } from "../../../store/store";
import {
  getCreatedPool,
  getStatePools,
} from "../../../store/reducers/pools/pools-selectors";
import ModalApiKeyContent from "./ModalContent/ModalApiKeyContent";
import { IRefreshedApiKeyRequest } from "../../../store/reducers/pools/pools-types";
import ModalContainer from "../../../components/Modals/ModalContainer";

const ModalUserPools: FC<ModalUserPoolsProps> = ({
  modal,
  onModalClose,
  onModalInputSubmit,
  createUserPoolSubmit,
  onModalApiKeySubmit,
}) => {
  const {
    isLoading,
    poolById: currentUserPoolById,
    isLoadingPoolById,
  } = useAppSelector(getStatePools);
  const newPool = useAppSelector(getCreatedPool);
  return (
    <ModalContainer isOpen={modal.isOpen} handleClose={onModalClose}>
      {/* {modal.createSuccess && (
        <ModalUserPoolsContent
          isLoading={isLoading}
          onClick={onModalClose}
          userPool={newPool?.id}
        />
      )}

      {modal.create && (
        <ModalUserPoolsInputs
          modalType="create"
          handleSubmit={createUserPoolSubmit}
          handleClose={onModalClose}
        />
      )} */}

      {modal.info && (
        <ModalUserPoolsContent
          isLoading={isLoadingPoolById}
          currentUserPool={currentUserPoolById}
          onClick={onModalClose}
        />
      )}

      {modal.detail && (
        <ModalUserPoolsInputs
          modalType="details"
          handleSubmit={onModalInputSubmit}
          handleClose={onModalClose}
        />
      )}

      {modal.title && (
        <ModalUserPoolsInputs
          modalType="editUserPool"
          handleSubmit={onModalInputSubmit}
          handleClose={onModalClose}
        />
      )}

      {modal.apiKey && onModalApiKeySubmit && (
        <ModalApiKeyContent
          handleSubmit={onModalApiKeySubmit}
          handleClose={onModalClose}
        />
      )}
    </ModalContainer>
  );
};

export default ModalUserPools;

interface ModalUserPoolsProps {
  modal: any;
  onModalClose: () => void;
  onModalInputSubmit: (input: string, modalType: string) => void;
  createUserPoolSubmit?: (name: string) => void;
  onModalApiKeySubmit?: (apiKeyValues: IRefreshedApiKeyRequest) => void;
}
