import React, { FC } from "react";
import cn from "classnames";

import "./DashboardCard.scss";

const DashboardCard: FC<IProps> = ({ color = "white", title, value }) => {
  return (
    <div className={cn("dashboard-card", [color])}>
      <div>{title}</div>
      <div className="dashboard-card__value">{value}</div>
    </div>
  );
};

export default DashboardCard;

interface IProps {
  color?: "white" | "blue";
  title: string;
  value: number;
}
