import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import InputText from "../../../../components/Inputs/InputText/InputText";
import InputPassword from "../../../../components/Inputs/InputPassword/InputPassword";
import Button from "../../../../components/Inputs/Button/Button";

import { signUpOwner } from "../../../../store/reducers/auth/auth-reducer";
import { getRegistration } from "../../../../store/reducers/auth/auth-selectors";

import "./SignUpStepTwo.scss";

const SignUpStepTwo: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { name: username, mail: email, phone } = useSelector(getRegistration);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const handleSignUp = () => {
    if (!isSubmitDisabled) {
      dispatch(
        signUpOwner({
          username,
          email,
          phone,
          first_name: firstName,
          second_name: surname,
          last_name: lastName,
          password: pass,
        })
      );
      navigate("/");
    }
  };

  const isSubmitDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.surname ||
    !!errors.pass ||
    !!errors.confirmPass;

  const isButtonDisabled = !(
    !!firstName &&
    !!lastName &&
    !!pass &&
    !!confirmPass
  );

  const nameValidation = {
    pattern: /^[a-zA-Zа-яА-Я\s]+$/,
    required: true,
  };

  return (
    <form className="step-two" onSubmit={handleSubmit(handleSignUp)}>
      <InputText
        value={lastName}
        onChange={setLastName}
        label="Фамилия"
        placeholder="Введите фамилия пользователя"
        errorMsg="Неверная фамилия пользователя"
        isError={!!errors.lastName}
        useForm={{ ...register("lastName", nameValidation) }}
      />
      <InputText
        value={firstName}
        onChange={setFirstName}
        label="Имя"
        placeholder="Введите имя пользователя"
        errorMsg="Неверное имя пользователя"
        isError={!!errors.firstName}
        useForm={{ ...register("firstName", nameValidation) }}
      />
      <InputText
        value={surname}
        onChange={setSurname}
        label="Отчество"
        placeholder="Введите отчество пользователя"
        errorMsg="Неверное отчество пользователя"
        isError={!!errors.surname}
        useForm={{ ...register("surname", nameValidation) }}
      />
      <div className="pass-wrapper">
        <InputPassword
          value={pass}
          onChange={setPass}
          label="Введите пароль"
          placeholder="Введите пароль"
          errorMsg="Пароли должны совпадать"
          isError={!!errors.pass}
          useForm={{ ...register("pass", { required: true }) }}
        />
        <InputPassword
          value={confirmPass}
          onChange={setConfirmPass}
          label="Повторите пароль"
          placeholder="Повторите пароль"
          errorMsg="Пароли должны совпадать"
          isError={!!errors.confirmPass}
          useForm={{
            ...register("confirmPass", {
              required: true,
              validate: {
                matchPassword: (value) =>
                  value === watch("pass") || "Пароли не совпадают",
              },
            }),
          }}
        />
      </div>
      <Button
        size="big"
        label="Зарегистрироваться"
        type="submit"
        isDisabled={isButtonDisabled}
      />
    </form>
  );
};

export default SignUpStepTwo;
