import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../store/store";
import cn from "classnames";

import Icon from "../Icon/Icon";
import Input from "../Inputs/Input/Input";
import Button from "../Inputs/Button/Button";
import { DropDown } from "../DropDown/DropDown";

import arrow from "../../assets/media/icons/arrow-down.svg";
import searchIcon from "../../assets/media/icons/search.svg";
import filterIcon from "../../assets/media/icons/filter.svg";
import close from "../../assets/media/icons/close.svg";
import reset from "../../assets/media/icons/cancel-icon.svg";

import "./SearchHeader.scss";

type Props = {
  primaryFilters: any;
  secondaryFilters?: any;
  setFilters?: any;
  button?: {
    label?: string;
    onClick: () => void;
  };
};

const SearchHeader: FC<Props> = ({
  primaryFilters,
  secondaryFilters,
  setFilters,
  button,
}) => {
  const [primaryDropdownList, setPrimaryDropdownList] = useState<any>([]);
  const [secondaryDropdownList, setSecondaryDropdownList] = useState<any>([]);
  const [localFilters, setLocalFilters] = useState<any>({});

  const disapatch = useAppDispatch();

  const handleFilterApply = () => {
    disapatch(setFilters({ filters: localFilters }));
    if (isFiltersCleared()) {
      setLocalFilters({});
    }
  };

  const handleSetLocalFilters = (value: any) => {
    setLocalFilters((prev: any) => ({
      ...prev,
      ...value,
    }));
  };

  const handleSearch = (value: any) => {
    handleSetLocalFilters({ [primaryFilters.searchParam]: value });
  };

  const handleChangeFilter = (type: string) => (value: any) => {
    if (localFilters[type]) {
      handleSetLocalFilters({ [type]: [...localFilters[type], value] });
    } else {
      handleSetLocalFilters({ [type]: [value] });
    }
  };

  const handleChangePoolsFilter = (type: string) => (value: any) => {
    if (localFilters[type]) {
      if (localFilters[type].length === secondaryFilters?.list.length) {
        handleSetLocalFilters({ [type]: [value] });
      } else {
        handleSetLocalFilters({ [type]: [...localFilters[type], value] });
      }
    } else {
      handleSetLocalFilters({ [type]: [value] });
    }
  };

  /*
    todo refactoring
  */
  const handleClearFilterItem = (
    type: string,
    value: any,
    activeFilters: any[]
  ) => {
    if (type === "user_pools_ids" && localFilters[type].length === 1) {
      handleSetLocalFilters({ [type]: secondaryFilters?.list });
    } else {
      handleSetLocalFilters({
        [type]: activeFilters.filter((item: any) => item.id !== value.id),
      });
    }
  };

  const handleClearFilterGroup = (type: string) => () => {
    handleSetLocalFilters({ [type]: [] });
  };

  const handleClearPools = (type: string) => () => {
    handleSetLocalFilters({ [type]: secondaryFilters?.list });
  };

  const onClickSearchIcon = () => {
    document.getElementById("search-input")?.focus();
  };

  const handleResetInput = () =>
    handleSetLocalFilters({ [primaryFilters.searchParam]: "" });

  useEffect(() => {
    /*
      фильтрую применённые фильтры из списка дропдауна 
    */
    if (localFilters[primaryFilters.type]) {
      setPrimaryDropdownList(
        primaryFilters?.list?.filter(
          (item: any) =>
            !localFilters[primaryFilters.type]
              .map((item: any) => item.id)
              .includes(item.id)
        )
      );
    } else {
      setPrimaryDropdownList(primaryFilters?.list);
    }
  }, [localFilters, primaryFilters]);

  useEffect(() => {
    /*
      фильтрую применённые фильтры из списка дропдауна 
    */
    /*
      todo refactoring
    */
    if (secondaryFilters?.list?.length > 0) {
      if (
        (localFilters[secondaryFilters?.type] &&
          localFilters[secondaryFilters?.type].length ===
            secondaryFilters?.list.length) ||
        !localFilters[secondaryFilters?.type]
      ) {
        setSecondaryDropdownList(secondaryFilters?.list);
      } else {
        setSecondaryDropdownList(
          secondaryFilters?.list.filter(
            (item: any) =>
              !localFilters[secondaryFilters?.type]
                .map((item: any) => item.id)
                .includes(item.id)
          )
        );
      }
    }
  }, [secondaryFilters, localFilters]);

  /*
    todo refactoring
  */
  /*
  определяю сброс всех фильтров для подсветки кнопки "применить"
  (нужно понимать разницу между не выбранными пустыми филтрами
    и сброшенными пустыми фильтрами) refactoring refactoring refactoring
*/
  function isFiltersCleared() {
    const isFiltersApplyed = Object.keys(localFilters).reduce(
      (a: any, b: any) => !!a.length || !!b.length,
      false
    );
    if (
      localFilters[secondaryFilters?.type] &&
      localFilters[secondaryFilters?.type]?.length !==
        secondaryFilters?.list?.length
    ) {
      return (
        !Object.values(localFilters)
          .map((item: any) => !!item?.length)
          .reduce((a: any, b: any) => a || b, false) && isFiltersApplyed
      );
    } else {
      const _obj = { ...localFilters };
      delete _obj.user_pools_ids;
      return (
        !Object.values(_obj)
          .map((item: any) => !!item?.length)
          .reduce((a: any, b: any) => a || b, false) && isFiltersApplyed
      );
    }
  }

  const isFiltersActive =
    /*
    проверка на заполненные поля
  */
    (localFilters[primaryFilters?.searchParam] &&
      localFilters[primaryFilters?.searchParam].length) ||
    (localFilters[primaryFilters?.type] &&
      localFilters[primaryFilters?.type].length) ||
    (localFilters[secondaryFilters?.type] &&
      localFilters[secondaryFilters?.type].length !==
        secondaryFilters?.list.length);

  return (
    <div className="search-header">
      <div className="search">
        <div className="search-container">
          <Icon
            className="icon-search"
            size={"1.25"}
            icon={searchIcon}
            onClick={onClickSearchIcon}
          />
          <Input
            id="search-input"
            className="container-input"
            placeholder="Введите название"
            onChange={handleSearch}
            value={localFilters[primaryFilters.searchParam]}
          />
          <button
            className={`search-container__reset-button ${cn("reset-button", {
              isResetButtonActive: !!localFilters[primaryFilters.searchParam],
            })}`}
            onClick={handleResetInput}
          >
            <Icon icon={reset} width={1.25} height={1.25} />
          </button>
          <span className="line"></span>
          <DropDown
            items={primaryDropdownList}
            onSelect={handleChangeFilter(primaryFilters.type)}
            element={
              <div className="filter">
                <Icon
                  className="icon-filter"
                  icon={filterIcon}
                  width={1}
                  height={1}
                />
                <>{primaryFilters.label}</>

                <Icon
                  className="icon-arrow"
                  icon={arrow}
                  width={0.8}
                  height={0.4}
                />
              </div>
            }
          />

          {secondaryFilters?.list?.length > 0 && (
            <>
              <span className="line"></span>
              <DropDown
                items={secondaryDropdownList}
                onSelect={handleChangePoolsFilter(secondaryFilters?.type)}
                element={
                  <div className="second-filter">
                    <Icon
                      className="icon-filter"
                      icon={filterIcon}
                      width={1}
                      height={1}
                    />
                    <p>{secondaryFilters.label}</p>

                    <Icon
                      className="icon-arrow"
                      icon={arrow}
                      width={0.8}
                      height={0.4}
                    />
                  </div>
                }
              />
            </>
          )}
          <Button
            className="container-button"
            label="Применить"
            onClick={handleFilterApply}
            size="big"
            width="fit-content"
            isDisabled={!isFiltersActive && !isFiltersCleared()}
          />
        </div>
        {button && (
          <Button
            className="search-button"
            label={button.label}
            size="big"
            width="fit-content"
            onClick={() => button.onClick()}
          />
        )}
      </div>

      <div className="filters">
        {localFilters[primaryFilters.type] &&
          localFilters[primaryFilters.type].length > 1 && (
            <div className="selected">
              <Icon
                className="icon-close"
                icon={close}
                size={"0.9265"}
                onClick={handleClearFilterGroup(primaryFilters.type)}
              />
              <p>Очистить</p>
            </div>
          )}
        {localFilters[primaryFilters.type] &&
          localFilters[primaryFilters.type].map((item: any) => (
            <div className="selected" key={item.id}>
              <Icon
                className="icon-close"
                icon={close}
                size={"0.9265"}
                onClick={() =>
                  handleClearFilterItem(
                    primaryFilters.type,
                    item,
                    localFilters[primaryFilters.type]
                  )
                }
              />
              <p>{item.label}</p>
            </div>
          ))}
      </div>
      {localFilters[secondaryFilters?.type] &&
        secondaryFilters.list.length !==
          localFilters[secondaryFilters?.type].length && (
          <div className="filters">
            {localFilters[secondaryFilters?.type].length > 1 && (
              <div className="selected">
                <Icon
                  className="icon-close"
                  icon={close}
                  size={"0.9265"}
                  onClick={handleClearPools(secondaryFilters?.type)}
                />
                <p>Очистить</p>
              </div>
            )}
            {localFilters[secondaryFilters?.type]?.map((item: any) => (
              <div className="selected" key={item.id}>
                <Icon
                  className="icon-close"
                  icon={close}
                  size={"0.9265"}
                  onClick={() =>
                    handleClearFilterItem(
                      secondaryFilters?.type,
                      item,
                      localFilters[secondaryFilters?.type]
                    )
                  }
                />
                <p>{!!item.label.length ? item.label : item.id}</p>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default SearchHeader;
