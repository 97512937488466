import React, { FC, useState } from "react";
import cn from "classnames";

const ConfigTableField: FC<IProps> = ({
  title = "",
  method,
  handleRadioButtonClick,
  radioInputValue,
  type = "openapi",
}) => {
  return (
    <li
      className={`modal-policy__config-field ${cn("config-container-color", [
        method.toLowerCase(),
      ])}`}
    >
      <div className="modal-policy__config-title-container">
        <div
          className={`modal-policy__config-method ${cn("config-method-color", [
            method.toLowerCase(),
          ])}`}
        >
          {method}
        </div>
        {type && type !== "http" && (
          <h3 className="modal-policy__config-title">{title}</h3>
        )}
      </div>

      <div className="modal-policy__config-checkbox-container">
        <label
          htmlFor={`${title}-${method}-true`}
          className="modal-policy__config-label"
        >
          <input
            className="modal-policy__config_input_true"
            type="radio"
            value="true"
            id={`${title}-${method}-true`}
            onChange={() => handleRadioButtonClick(title, method, true)}
            checked={radioInputValue}
          />
          <span className="modal-policy__config-radiomark modal-policy__config-radiomark_type_true">
            True
          </span>
        </label>
        <label
          htmlFor={`${title}-${method}-false`}
          className="modal-policy__config-label modal-policy__config-label_type_false"
        >
          <input
            className="modal-policy__config_input_false"
            type="radio"
            value="false"
            id={`${title}-${method}-false`}
            onChange={() => handleRadioButtonClick(title, method, false)}
            checked={!radioInputValue}
          />
          <span className="modal-policy__config-radiomark modal-policy__config-radiomark_type_false">
            False
          </span>
        </label>
      </div>
    </li>
  );
};

export default ConfigTableField;

interface IProps {
  title?: string;
  method: string;
  radioInputValue: boolean;
  handleRadioButtonClick: (
    title: string,
    method: string,
    value: boolean,
  ) => void;
  type?: string;
}
