import { FC, useEffect } from "react";
import { useAppDispatch } from "../../store/store";
import "./AppRouters.scss";

import Header from "../Header/Header";
import PagesContainer from "../PagesContainer/PagesContainer";
import PagesRouter from "../PagesRouter/PagesRouter";
import Navbar from "../Navbar/Navbar";

import { getMyProfile } from "../../store/reducers/auth/auth-reducer";

const AppRouter: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);

  return (
    <>
      <Header />
      <main className="main">
        {
          <>
            <Navbar />
            <PagesContainer>
              <PagesRouter />
            </PagesContainer>
          </>
        }
      </main>
    </>
  );
};
export default AppRouter;
