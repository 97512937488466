import { FC } from "react";
import cn from "classnames";

import "./CommonTableHeaderTitle.scss";
const CommonTableHeaderTitle: FC<IProps> = ({ title }) => {
  const { key, label } = title;
  const isJustifyCenter = key === "status" || key === "users_count";

  return (
    <div
      className={cn("common-table-header-title", {
        justifyCenter: isJustifyCenter,
      })}
    >
      {label}
    </div>
  );
};

interface IProps {
  title: any;
}
export default CommonTableHeaderTitle;
