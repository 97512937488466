import React, { ChangeEvent, FC, useState } from "react";
import ModalButton from "./ModalButton";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";
import Button from "../../../Inputs/Button/Button";
import { readFileAsync } from "../../../../utils/readFiles";
import {
  ApiPolicyWithFileKeys,
  IInput,
  inputModalContent,
} from "../../../../constants/constants";
import "../ModalPolicy.scss";

export type apiPolicyWithFileType = {
  [key in ApiPolicyWithFileKeys]: string;
};

const OpenApiWithFileContent: FC<IProps> = ({
  width,
  handleNewPolicyWithFileSubmit,
  handleClose,
}) => {
  const [apiWithFile, setApiWithFile] = useState<apiPolicyWithFileType>({
    fileValue: "",
  });
  const [fileName, setFileName] = useState("");
  const [isInputValid, setIsInputValid] = useState(true);

  const handleValueApiWithFileChange = (value: string, inputName: string) => {
    setApiWithFile((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = evt.target.files?.[0];

    if (selectedFile && selectedFile.type === "application/json") {
      try {
        setFileName(selectedFile.name);
        const fileContents = await readFileAsync(selectedFile);

        setApiWithFile((prev) => ({
          ...prev,
          fileValue: fileContents,
        }));
      } catch (error) {
        console.error("Ошибка при чтении файла:", error);
      }
    } else {
      console.error("no files here");
    }
  };

  const handleButtonSubmit = () => {
    const isInputValid = !!apiWithFile.fileValue;
    setIsInputValid(isInputValid);

    if (isInputValid) handleNewPolicyWithFileSubmit(apiWithFile);
  };

  return (
    <div className="modal-policy modal-policy__with-input" style={{ width }}>
      <h4 className="modal-policy__modal-title">
        {inputModalContent["apiPolicyWithFile"]["title"]}
      </h4>
      {inputModalContent["apiPolicyWithFile"].inputs?.map(
        (input: IInput, index) => {
          return (
            <ModalInput
              index={index}
              key={input.placeholder}
              placeholder={
                input.JSONPlaceholder
                  ? apiWithFile.fileValue
                    ? `${fileName}  ${input.JSONPlaceholderSuccess!}`
                    : input.JSONPlaceholderDownload!
                  : input.placeholder
              }
              value={apiWithFile[input.name as ApiPolicyWithFileKeys]}
              onChange={handleValueApiWithFileChange}
              type={input.type}
              inputName={input.name}
              handleFileChange={handleFileChange}
              isInputValid={isInputValid}
            />
          );
        },
      )}

      <div className="modal-policy__modal-button-container">
        <Button
          onClick={handleClose}
          label="Отмена"
          isDisabled={false}
          color="light-blue"
        />

        <ModalButton
          handleClick={handleButtonSubmit}
          label={inputModalContent["apiPolicyWithFile"]["buttonLabel"]}
          width="fit-content"
        />
      </div>
    </div>
  );
};

export default OpenApiWithFileContent;

interface IProps {
  width: string | number;
  handleNewPolicyWithFileSubmit: (arg: apiPolicyWithFileType) => void;
  handleClose: () => void;
}
