import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../../components/Logo/Logo";

import "./SignInGreetings.scss";
import Button from "../../../components/Inputs/Button/Button";

const SignInGreetings: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate("/signup");

  return (
    <div className="sign-in-greetings">
      <Logo />
      <div className="greetings">Добро пожаловать!</div>
      <div className="info">
        Мы обеспечим единый доступ ко всем вашим продуктам, объединяя
        пользователей в одном месте.
      </div>
      <div className="noaccaunt">Нет аккаунта?</div>
      <Button
        size="big"
        label="Зарегистрироваться"
        color="white"
        onClick={handleNavigate}
      />
    </div>
  );
};

export default SignInGreetings;
