import { FC } from "react";
import cn from "classnames";

import "./Button.scss";
import Icon from "../../Icon/Icon";

const Button: FC<IProps> = ({
  className,
  color = "blue",
  size,
  width = "fixed",
  type = "button",
  label,
  onClick,
  isDisabled = false,
  iconLeft,
  iconRight,
  marginTop,
  alignSelf,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={cn([
        className,
        "button",
        [color, size, width],
        {
          "with-icon": iconLeft || iconRight,
          disabled: isDisabled,
        },
      ])}
      style={{ marginTop, alignSelf }}
      disabled={isDisabled}
    >
      {iconLeft ? <Icon size="3" icon={iconLeft} /> : null}
      {label}
      {iconRight ? <Icon size="3" icon={iconRight} /> : null}
    </button>
  );
};

export default Button;

interface IProps {
  className?: string;
  color?: "white" | "blue" | "light-blue" | "green";
  size?: "big" | "small";
  width?: "fixed" | "fit-content";
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  marginTop?: string;
  alignSelf?: string;
  type?: "button" | "submit" | "reset" | undefined;
}
