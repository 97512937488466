import React, { FC } from "react";
import Button from "../../../Inputs/Button/Button";

const ModalButton: FC<IProps> = ({
  handleClick,
  label,
  width = "fixed",
  marginTop = "0",
  alignSelf = "start",
  color = "blue",
  isDisabled = false,
}) => {
  return (
    <Button
      onClick={handleClick}
      color={color}
      isDisabled={isDisabled}
      label={label}
      width={width}
      marginTop={marginTop}
      alignSelf={alignSelf}
    />
  );
};

export default ModalButton;

interface IProps {
  handleClick: () => void;
  label?: string;
  width?: "fixed" | "fit-content";
  marginTop?: string;
  alignSelf?: string;
  color?: "white" | "blue" | "light-blue";
  isDisabled?: boolean;
}
