import React, { FC, useState } from "react";

import ModalContainer from "../ModalContainer";
import NewPolicyContent from "./ModalContent/NewPolicyContent";
import OpenApiContent from "./ModalContent/OpenApiContent";
import ConfigContent from "./ModalContent/ConfigContent";
import OpenApiWithFileContent, {
  apiPolicyWithFileType,
} from "./ModalContent/OpenApiWithFileContent";
import OpenApiWithSwaggerContent from "./ModalContent/OpenApiWithSwaggerContent";
import GroupContent from "./ModalContent/GroupContent";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  apiPolicyWithSwaggerType,
  GroupSelectedItemsType,
  ISwaggerSpec,
} from "../../../store/reducers/policy/policy-types";
import {
  createHttpPolicy,
  createOpenapiPolicy,
  createPolicyGroup,
  sendSpecificationJson,
  sendSpecificationUrl,
} from "../../../store/reducers/policy/policy-reducer";
import { IUserPool } from "../../../store/reducers/pools/pools-types";
import {
  getParsedOpenapiPolicyFromUrl,
  getSwaggerSetup,
  policySpecFromFile,
} from "../../../store/reducers/policy/policy-selectors";
import { defaultOpenHttpAndApiMethods } from "../../../constants/constants";

const hasEmptyFields = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === "" && obj["owner_id"] !== "") {
      return false;
    }
  }
  return true;
};

const initialState = {
  title: "",
  isNewPolicyModal: true,
  // isHttpPolicyModal: false,
  isHttpPolicyMethodsModal: false,
  isOpenApiModal: false,
  isOpenApiModalWithFile: false,
  isOpenApiModalWithSwagger: false,
  isGroupModal: false,
  isStructureModalOpen: false,
  isOpenApiModalFromFileSpec: false,
  isOpenApiModalFromUrl: false,
};

const ModalNewPolicy: FC<IProps> = ({ isModalOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const openApiPolicyFromFile = useAppSelector(policySpecFromFile);
  const parsedOpenapiPolicyFromUrl = useAppSelector(
    getParsedOpenapiPolicyFromUrl
  );
  const getSwaggerSetupFromRequest = useAppSelector(getSwaggerSetup);
  const [modalState, setModalState] = useState(initialState);
  const [createPolicy, setCreatePolicy] = useState({
    title: "",
    userPoolId: "",
    policy: "",
    owner_id: "",
  });

  const onModalClose = () => {
    handleClose();
    setModalState(initialState);
  };

  const handleNewPolicyClick = (
    value: string,
    userPool: IUserPool,
    radioInputValue: string
  ) => {
    setCreatePolicy({
      title: value,
      userPoolId: userPool.id,
      policy: radioInputValue,
      owner_id: userPool.owner_id,
    });

    if (value && radioInputValue)
      setModalState((prev) => ({
        ...prev,
        isHttpPolicyMethodsModal: radioInputValue === "HTTP",
        isOpenApiModal: radioInputValue === "OpenAPI",
        isGroupModal: radioInputValue === "Группа",
        isNewPolicyModal: false,
        title: value ? value : prev.title,
      }));
  };

  const handleApiPolicyClick = (radioValue: string) => {
    setModalState((prev) => ({
      ...prev,
      isOpenApiModalWithFile: radioValue === "С помощью файла",
      isOpenApiModalWithSwagger: radioValue === "Доступ к сваггеру",
      isOpenApiModal: false,
    }));
  };

  const handeHttpPolicyMethodsSubmit = (value: { [key: string]: boolean }) => {
    if (value) {
      dispatch(
        createHttpPolicy({
          title: createPolicy.title,
          user_pool_id: createPolicy.userPoolId,
          ...value,
        })
      );

      onModalClose();
    }
  };

  const handleGroupPolicySubmit = (values: GroupSelectedItemsType[]) => {
    dispatch(
      createPolicyGroup({
        title: createPolicy.title,
        user_pool_id: createPolicy.userPoolId,
        policy_ids: values.map((policy) => policy.id),
      })
    );

    onModalClose();
  };

  const handleNewPolicyWithFileSubmit = (result: apiPolicyWithFileType) => {
    if (result.fileValue) {
      dispatch(
        sendSpecificationJson({
          ...JSON.parse(result.fileValue),
        })
      );

      setModalState((prev) => ({
        ...prev,
        isOpenApiModalWithFile: false,
        isOpenApiModalFromFileSpec: true,
      }));
    }
  };

  const handleOpenApiModalFromFileSpecSubmit = (value: ISwaggerSpec) => {
    Object.keys(value).length > 0 && getSwaggerSetupFromRequest
      ? dispatch(
          createOpenapiPolicy({
            owner_id: createPolicy.owner_id,
            type: "openapi",
            title: createPolicy.title,
            user_pool_id: createPolicy.userPoolId,
            endpoints: value,
            defaults: defaultOpenHttpAndApiMethods,
            swagger_setup: {
              openapi_spec_url: getSwaggerSetupFromRequest?.openapi_spec_url,
              login: getSwaggerSetupFromRequest.login,
              password: getSwaggerSetupFromRequest.password,
            },
          })
        )
      : dispatch(
          createOpenapiPolicy({
            owner_id: createPolicy.owner_id,
            type: "openapi",
            title: createPolicy.title,
            user_pool_id: createPolicy.userPoolId,
            endpoints: value,
            defaults: defaultOpenHttpAndApiMethods,
          })
        );

    onModalClose();
  };

  const handleNewPolicyWithSwaggerSubmit = (
    result: apiPolicyWithSwaggerType
  ) => {
    if (hasEmptyFields(result)) {
      dispatch(
        sendSpecificationUrl({
          url: result.openapi_spec_url,
          login: result.login,
          password: result.password,
        })
      );
      setModalState((prev) => ({
        ...prev,
        isOpenApiModalWithSwagger: false,
        isOpenApiModalFromUrl: true,
      }));
    }
  };

  return (
    <div>
      <ModalContainer isOpen={isModalOpen} handleClose={onModalClose}>
        <>
          {/*New policy modal*/}
          {modalState.isNewPolicyModal && (
            <NewPolicyContent
              width="30.125rem"
              handleNewPolicyClick={handleNewPolicyClick}
              handleClose={onModalClose}
            />
          )}

          {/*Http policy modal*/}
          {modalState.isHttpPolicyMethodsModal && (
            <ConfigContent
              onSubmit={handeHttpPolicyMethodsSubmit}
              title="Сконфигурируйте ваши policy"
              handleClose={onModalClose}
              modalType="http"
            />
          )}

          {/*Open API modal*/}
          {modalState.isOpenApiModal && (
            <OpenApiContent
              width="31.5rem"
              handleApiPolicyClick={handleApiPolicyClick}
              handleClose={onModalClose}
            />
          )}

          {/*Group modal*/}
          {modalState.isGroupModal && (
            <GroupContent
              width="45rem"
              onSubmit={handleGroupPolicySubmit}
              handleClose={onModalClose}
              currentUserPoolId={createPolicy.userPoolId}
            />
          )}

          {/*Open API with file modal*/}
          {modalState.isOpenApiModalWithFile && (
            <OpenApiWithFileContent
              width="37.5rem"
              handleNewPolicyWithFileSubmit={handleNewPolicyWithFileSubmit}
              handleClose={onModalClose}
            />
          )}

          {/*Open API with Swagger*/}
          {modalState.isOpenApiModalWithSwagger && (
            <OpenApiWithSwaggerContent
              width="37.5rem"
              handleNewPolicyWithSwaggerSubmit={
                handleNewPolicyWithSwaggerSubmit
              }
              handleClose={onModalClose}
            />
          )}

          {/*Configure Open API from json file*/}
          {modalState.isOpenApiModalFromFileSpec && (
            <ConfigContent
              onSubmit={handleOpenApiModalFromFileSpecSubmit}
              policyItemFromFileOrUrl={openApiPolicyFromFile}
              title="Сконфигурируйте ваши policy"
              width="45rem"
              handleClose={onModalClose}
            />
          )}

          {/*Configure parsed Open API from URL*/}
          {modalState.isOpenApiModalFromUrl && (
            <ConfigContent
              onSubmit={handleOpenApiModalFromFileSpecSubmit}
              policyItemFromFileOrUrl={parsedOpenapiPolicyFromUrl}
              title="Сконфигурируйте ваши policy"
              width="45rem"
              handleClose={onModalClose}
            />
          )}
        </>
      </ModalContainer>
    </div>
  );
};

interface IProps {
  isModalOpen: boolean;
  handleClose: () => void;
}

export default ModalNewPolicy;
