export const setLang= ({lang} :{lang: string}) => {
    localStorage.setItem("lang", lang)
}

export const getLang = (): string => {
    return localStorage.getItem("lang") as string;
}

export const setAccessToken = ({accessToken} :{accessToken: string}) => {
    localStorage.setItem("accessToken", accessToken)
}

export const getAccessToken = () => {
    return localStorage.getItem("accessToken");
}

export const removeAccessToken = () => {
    return localStorage.removeItem("accessToken");
}

export const setRefreshToken = ({refreshToken} :{refreshToken: string}) => {
    localStorage.setItem("refreshToken", refreshToken)
}

export const getRefreshToken = () => {
    return localStorage.getItem("refreshToken");
}

export const removeRefreshToken = () => {
    return localStorage.removeItem("refreshToken");
}

export const getTokenLifeTime = () => {
    const token = getAccessToken()

    if (token) {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const expirationTimeInSeconds = tokenData.exp;
        return Math.floor((expirationTimeInSeconds - currentTimeInSeconds) / 60);
    } else return 0
}