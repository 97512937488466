import { RootState } from "../../store";

export const getIsLoading = ({ policy: { isLoading } }: RootState) => isLoading;
export const getPolicies = ({ policy: { policies, filters } }: RootState) => ({
  policies,
  filters
});
export const getPolicyById = ({ policy: { policyById } }: RootState) =>
  policyById;

export const policySpecFromFile = ({
  policy: { openApiPolicyFromFile },
}: RootState) => openApiPolicyFromFile;

export const getParsedOpenapiPolicyFromUrl = ({
  policy: { parsedOpenapiPolicyFromUrl },
}: RootState) => parsedOpenapiPolicyFromUrl;
export const getIsOpenApiUpdateSuccess = ({
  policy: { isOpenApiUpdateSuccess },
}: RootState) => isOpenApiUpdateSuccess;
export const getSwaggerSetup = ({ policy: { swaggerSetup } }: RootState) =>
  swaggerSetup;
