import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Inputs/Button/Button";

import arrowIcon from "../../../assets/media/icons/arrow-to-rigth.svg";
import join from "../../../assets/images/join.png";

import "./JoinUs.scss";

const JoinUs = () => {
  const navigate = useNavigate();

  return (
    <div className="join-us-user-pool">
      <div className="join-us-section">
        <div className="join-us-section__header">
          Присоединяйтесь к нам прямо сейчас!
        </div>
        <div className="join-us-section__text">
          Не упустите возможность упростить управление <br /> доступом и
          повысить безопасность своих продуктов.{" "}
        </div>
        {/* <div> */}
        <Button
          onClick={() => navigate("/signup")}
          label="Зарегистрироваться"
          size="big"
          color="blue"
          iconRight={arrowIcon}
        />
        {/* </div> */}
      </div>
      <div className="user-pool-section">
        <img className="user-pool-section__image" src={join} alt="logo" />
      </div>
    </div>
  );
};
export default JoinUs;
