import { FC, ReactNode } from "react";
import Modal from "@mui/material/Modal";
import "./ModalContainer.scss";
import cn from "classnames";

const ModalContainer: FC<IProps> = ({
  isOpen,
  handleClose,
  children,
  size = "medium",
}) => {
  return (
    <div className="modal">
      <Modal open={isOpen} onClose={handleClose}>
        <div className={`modal__container ${cn("modal-size", [size])}`}>
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default ModalContainer;

interface IProps {
  isOpen: boolean;
  handleClose?: () => void;
  children: ReactNode;
  size?: "medium" | "small";
}
