import { FC, useState } from "react";
import "./PageTableField.scss";
import Icon from "../Icon/Icon";
import editIcon from "../../assets/media/icons/edit.svg";
import infoIcon from "../../assets/media/icons/info.svg";
import apiKey from "../../assets/media/icons/key-icon.svg";
import gear from "../../assets/media/icons/gear.svg";
import urlIcon from "../../assets/media/icons/link-icon.svg";
import Tooltip from "../Tooltip/Tooltip";
import StatusLabel from "./StatusLabel";
import { RowStatus, TypePageTable } from "../../constants/constants";
import { IUser, IUserPool } from "../../store/reducers/pools/pools-types";
import {
  IOpenapiPolicyRequest,
  IOwnerPolicy,
} from "../../store/reducers/policy/policy-types";
import { useAppSelector } from "../../store/store";
import { getStatePools } from "../../store/reducers/pools/pools-selectors";

const PageTableField: FC<IProps<any>> = ({
  type,
  tableRow,
  isClickable = false,
  handleInfo,
  handleEdit,
  handleApiKey,
  handleStatus,
  handleConfig,
  handleUpdateOpenApiFromUrlOrSpec,
  handleGroupConfig,
}) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const {
    pools: { pools },
  } = useAppSelector(getStatePools);

  const isUrlButtonVisible =
    handleUpdateOpenApiFromUrlOrSpec &&
    "type" in tableRow &&
    tableRow.type === "openapi";

  const handleNewStatus = (statusInput: string) => {
    if (type === TypePageTable.userPool) {
      handleStatus?.({
        status: statusInput,
        PoolId: tableRow.id,
      });
    }

    if (type === TypePageTable.users) {
      handleStatus?.({
        status: statusInput,
        PoolId: (tableRow as IUser).user_pool_id,
        UserId: tableRow.id,
      });
    }

    if (type === TypePageTable.policy) {
      handleStatus?.({
        status: statusInput,
        policyId: tableRow.id,
      });
      setIsStatusModalOpen(false);
    }
  };

  return (
    <ul
      className="page-table-field"
      style={{
        gridTemplateColumns:
          type === TypePageTable.policy ? "500px 250px 1fr 290px" : "",
      }}
    >
      <li className="page-table-field__name">
        {type === TypePageTable.userPool || type === TypePageTable.policy ? (
          (tableRow as IUserPool | IOwnerPolicy).title
        ) : (
          <>
            <h3 className="page-table-field__username">
              {(tableRow as IUser).username}
            </h3>
            <p className="page-table-field__email">
              {(tableRow as IUser).email}
            </p>
          </>
        )}
      </li>
      {type !== TypePageTable.userPool && (
        <li className="page-table-field__quantity">
          {(type === TypePageTable.policy && (tableRow as IOwnerPolicy).type) ||
            (type === TypePageTable.users &&
              pools?.find((pool: IUserPool) => {
                return "user_pool_id" in tableRow
                  ? pool.id === tableRow.user_pool_id
                  : "";
              })?.title)}
        </li>
      )}
      {type === TypePageTable.userPool && (
        <li className="page-table-field__quantity">{tableRow.users_count}</li>
      )}

      {type === TypePageTable.policy && (
        <li className="page-table-field__quantity">
          {pools?.find((pool) => pool.id === tableRow.user_pool_id)?.title}
        </li>
      )}
      <li className="page-table-field__status">
        {type === TypePageTable.userPool && (
          <button
            className="page-table-field__button"
            onClick={() => handleInfo?.(tableRow.id)}
          >
            <Icon icon={infoIcon} width={1.25} height={1.25} />
          </button>
        )}

        {type !== TypePageTable.users && (
          <button
            className="page-table-field__button"
            onClick={() => handleEdit(tableRow.id)}
          >
            <Icon icon={editIcon} width={1.25} height={1.25} />
          </button>
        )}

        {type === TypePageTable.policy && "type" in tableRow && (
          <button
            className="page-table-field__button"
            onClick={
              tableRow.type === "group"
                ? () => handleGroupConfig?.(tableRow.id)
                : () => handleConfig?.(tableRow.id)
            }
          >
            <Icon icon={gear} width={1.25} height={1.25} />
          </button>
        )}

        {type === TypePageTable.policy &&
          (isUrlButtonVisible ? (
            <button
              className="page-table-field__button"
              onClick={() =>
                handleUpdateOpenApiFromUrlOrSpec?.(
                  tableRow as IOpenapiPolicyRequest
                )
              }
            >
              <Icon icon={urlIcon} width={1.25} height={1.25} />
            </button>
          ) : (
            <div className="page-table-field__empty-container"></div>
          ))}

        {type === TypePageTable.userPool && handleApiKey && (
          <button
            className="page-table-field__button"
            onClick={() => handleApiKey(tableRow as IUserPool)}
          >
            <Icon icon={apiKey} width={1.25} height={1.25} />
          </button>
        )}

        <div className="status-label-container">
          <StatusLabel
            status={tableRow.status as "" | "ACTIVE" | "INACTIVE" | "BLOCKED"}
            isClickable={isClickable}
            onClick={() => setIsStatusModalOpen(true)}
          />

          <Tooltip
            open={isStatusModalOpen}
            id={tableRow.id}
            status={tableRow.status as "" | "ACTIVE" | "INACTIVE" | "BLOCKED"}
            handleClose={() => setIsStatusModalOpen(false)}
            handleSave={handleNewStatus}
          />
        </div>
      </li>
    </ul>
  );
};

export default PageTableField;

interface IProps<T> {
  type: TypePageTable;
  tableRow: T;
  isClickable: boolean;
  handleInfo?: (id: string) => void;
  handleEdit: (id: string) => void;
  handleApiKey?: (tableRow: IUserPool) => void;
  handleUpdateOpenApiFromUrlOrSpec?: (tableRow: IOpenapiPolicyRequest) => void;
  handleConfig?: (id: string) => void;
  handleGroupConfig?: (id: string) => void;
  handleStatus?: (status: RowStatus) => void;
}
