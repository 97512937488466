const data = [
  {
    label: "Унификация доступа",
    description:
      "Наш сервис обеспечивает простоту и гибкость использования, позволяя вам управлять доступом к различным продуктам легко и без лишних сложностей.",
  },
  {
    label: "Бессерверность технологий",
    description:
      "Благодаря бессерверным технологиям вы платите только за реально использованные ресурсы, а не за постоянно поддерживаемую инфраструктуру.",
  },
  {
    label: "Соответствие законодательству",
    description:
      "Мы гордимся тем, что наш продукт соответствует Российскому законодательству, обеспечивая надежную защиту данных всех пользователей.",
  },
  {
    label: "Мощь API",
    description:
      "Вам доступна возможность работы через API, давая вам больше контроля над функциональностью и интеграцией.",
  },
  {
    label: "Простота интеграции",
    description:
      "Наш сервис предоставляет библиотеки для основных языков программирования и обладает гибкой модульной системой, что позволяет быстро и легко интегрировать его в ваш продукт.",
  },
];

export default data;
