import React, { FC } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { atelierLakesideLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { nnfx } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";

const str = `package main

import (
	"fmt"

	"github.com/stlprojects/STLock-golib/authclient"
)

func main() {
	endpoint := "https://example.com"
	userPoolID := "userpool123"
	ownerID := "owner123"
	apiKey := "your_api_key"

	client := authclient.NewAuthClient(endpoint, userPoolID, ownerID, apiKey)

	username := "test"
	password := "test"
	result, statusCode := client.AuthorizeUser(username, password)
	fmt.Println(result, statusCode)

	// ... (Другие возможности)
}

`;
const str2 = `POST http://localhost:8000/register
Body {
    "username": "test",
    "email": "test@gmail.com",
    "phone": "79111111111",
    "password": "test"
}
`;
const str3 = `{
  "policy_ids": [],
  "user_pool_id": "0e2173b5-b55f-4ea4-bec5-450e2c12a458",
  "owner_id": "b3893a06-5931-4160-9e62-938ab173b6c8",
  "username": "test6",
  "email": "test6@gmail.com",
  "phone": "79520546024",
  "id": "a351d4db-4e7f-48ef-b667-e7af3a480086",
  "status": "ACTIVE",
  "creation_timestamp": 1693303651
}
`;
const str4 = `POST http://localhost:8000/login
Body {
    "username": "test",
    "password": "test"
}
`;

const str5 = `{
  "access_token": {JWT},
  "refresh_token": {JWT}
}
`;

const str6 = `POST http://localhost:8000/refresh
Body {
    "refresh_token": {JWT}
}
`;
const str7 = `{
  "access_token": {JWT},
  "refresh_token": {JWT}
}

`;
const str8 = `POST http://localhost:8000/logout
Body {
    "refresh_token": {JWT}
}
`;
const str9 = `STATUS_CODE: 200`;

const DocTitle = ({ title }: { title: string }) => (
  <h3 style={{ margin: "1rem 0" }}>
    <strong>{title}</strong>
  </h3>
);

const GoDocs: FC = () => {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <h2 style={{ marginBottom: "1.5rem" }}>Golang библиотека</h2>
      <DocTitle title={"STLock-golib"} />
      <p style={{ padding: "1rem 0" }}>
        STLock-golib - это библиотека на Golang для управления аутентификацией и
        авторизацией с использованием пула пользователей и JWT-токенов. команда
        разработки: <strong>Smart Techno Lab:</strong>
      </p>
      <DocTitle title={"Возможности"} />
      <li style={{ padding: " 0 1rem" }}>
        <strong>Авторизация:</strong>
        Авторизация: Позволяет авторизовать пользователей с использованием их
        имени пользователя и пароля.
      </li>
      <li style={{ padding: " 0 1rem" }}>
        <strong>Обновление токена:</strong>
        Позволяет обновить просроченный токен с помощью действующего токена
        обновления.
      </li>
      <li style={{ padding: " 0 1rem" }}>
        <strong> Регистрация пользователя: </strong>
        Позволяет зарегистрировать новых пользователей с указанием их учетных
        данных.
      </li>
      <li style={{ padding: " 0 1rem" }}>
        <strong> Декодирование токена: </strong>
        Раскодирует JWT-токены для извлечения данных.
      </li>
      <li style={{ padding: " 0 1rem" }}>
        <strong> Функции политики:</strong>
        Позволяет применять пользовательские политики для управления доступом.
      </li>
      <DocTitle title={"Установка"} />
      <SyntaxHighlighter language="javascript" style={vs}>
        {"go get github.com/stlprojects/STLock-golib/authclient"}
      </SyntaxHighlighter>
      <DocTitle title={"Использование"} />
      <SyntaxHighlighter language="javascript" style={vs}>
        {str}
      </SyntaxHighlighter>
      <DocTitle title={"Использование"} />
      <DocTitle
        title={
          "AuthorizeUser(username string, password string) (map[string]interface{}, int)"
        }
      />
      <p style={{ padding: "0 0 1rem 0" }}>
        Авторизует пользователя с заданными именем пользователя и паролем:
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`username := "test"
password := "test"
result, statusCode := client.AuthorizeUser(username, password)
fmt.Println(result, statusCode)
`}
      </SyntaxHighlighter>
      <DocTitle
        title={"DoRefresh(refreshToken string) (map[string]interface{}, int)"}
      />
      <p style={{ padding: "0 0 1rem 0" }}>
        Обновляет просроченный токен с использованием действующего токена
        обновления.
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`refreshToken := "your_refresh_token"
result, statusCode := client.DoRefresh(refreshToken)
fmt.Println(result, statusCode)
`}
      </SyntaxHighlighter>
      <DocTitle
        title={
          "Register(username string, email string, phone string, password string) (map[string]interface{}, int)"
        }
      />
      <p style={{ padding: "0 0 1rem 0" }}>
        Регистрирует нового пользователя с указанными данными.
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`username := "test"
email := "test@example.com"
phone := "+1234567890"
password := "test"
result, statusCode := client.Register(username, email, phone, password)
fmt.Println(result, statusCode)
`}
      </SyntaxHighlighter>
      <DocTitle title={"Unauthorized(refreshToken string) int"} />
      <p style={{ padding: "0 0 1rem 0" }}>
        Отзывает права доступа текущего токена.
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`refreshToken := "your_refresh_token"
statusCode := client.Unauthorized(refreshToken)
fmt.Println(statusCode)
`}
      </SyntaxHighlighter>
      <DocTitle title={"Decode(tokenString string) (jwt.MapClaims, error)"} />
      <p style={{ padding: "0 0 1rem 0" }}>
        Декодирует JWT-токен для извлечения данных.
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`tokenString := "your_jwt_токен"
claims, err := client.Decode(tokenString)
if err != nil {
    fmt.Println("Error decoding token:", err)
} else {
    fmt.Println("Decoded Token Claims:", claims)
}
`}
      </SyntaxHighlighter>
      <DocTitle title={"Policy(policyFunc func()) func()"} />
      <p style={{ padding: "0 0 1rem 0" }}>
        Применяет пользовательскую политику для управления доступом.
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {`policyFunc := func() {
    fmt.Println("Policy function executed.")
}
policyWrapper := client.Policy(policyFunc)
policyWrapper()
`}
      </SyntaxHighlighter>
      <DocTitle title={"Тестирование"} />
      <p style={{ padding: "0 0 1rem 0" }}>
        Чтобы запустить тесты, воспользуйтесь следующей командой:
      </p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {"go test github.com/stlprojects/STLock-golib/authclient"}
      </SyntaxHighlighter>
    </div>
  );
};

export default GoDocs;
