import { FC } from "react";

import "./Subtitle.scss";

interface IProps {
  text: string;
}

const Subtitle: FC<IProps> = ({ text }) => {
  return <div className="common-subtitle">{text}</div>;
};

export default Subtitle;
