import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";

import Autocomplete from "../../components/Autocomplete/Autocomplete";
import BindingTable from "./BindingTable/BindingTable";
import Preloader from "../../components/Preloader/Preloader";

import {
  clearPoolsState,
  getPoolsWhithUsers,
} from "../../store/reducers/pools/pools-reducer";
import { pools } from "../..//store/reducers/pools/pools-selectors";
import { getIsLoading } from "../../store/reducers/policy/policy-selectors";
import { IUserPool } from "../../store/reducers/pools/pools-types";

import "./Binding.scss";
import {
  users,
  usersIsLoading,
} from "../../store/reducers/users/users-selectors";
import Subtitle from "../../components/Subtitle/Subtitle";

const Binding: FC = () => {
  const [selectedPool, setSelectedPool] = useState<IUserPool | null>(null);
  const [selectedUser, setSelectedUser] = useState<IUserPool | null>(null);

  const { pools: userPools, isLoading: isPoolsLoading } = useAppSelector(pools);
  const usersByPool = useAppSelector(users);

  const isPolicyLoading = useAppSelector(getIsLoading);
  const isUsersLoading = useAppSelector(usersIsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    /*
    Сразу просим Пуллы для дропдауна
  */
    dispatch(getPoolsWhithUsers());
  }, [dispatch]);

  useEffect(() => {
    /*
    первый пулл из списка делаем активным в дропдауне
  */
    if (userPools) {
      setSelectedPool(userPools[0]);
    }
  }, [userPools, setSelectedPool]);

  useEffect(
    () => () => {
      dispatch(clearPoolsState());
    },
    [dispatch]
  );

  return (
    <div className="binding page">
      <h1>Привязать policy к пользователю</h1>
      <div className="search-wrapper">
        <div className="binding-dropdown">
          <Subtitle text="Выбрать user pool" />
          <Autocomplete
            placeholder="Введите название user pool"
            values={userPools as IUserPool[]}
            value={selectedPool}
            setValue={setSelectedPool}
            isInputValid
          />
        </div>

        <div className="binding-dropdown">
          <Subtitle text="Выбрать пользователя" />
          <Autocomplete
            placeholder="Введите username пользователя"
            values={usersByPool?.users as IUserPool[]}
            value={selectedUser}
            setValue={setSelectedUser}
            isInputValid
          />
        </div>
      </div>
      <BindingTable selectedPool={selectedPool} selectedUser={selectedUser} />
      <Preloader
        isLoading={isPoolsLoading || isPolicyLoading || isUsersLoading}
      />
    </div>
  );
};

export default Binding;
