import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";

import SearchHeader from "../../components/PagesHeader/SearchHeader";
import Preloader from "../../components/Preloader/Preloader";
import ModalUserPools from "../../components/Modals/ModalUserPools/ModalUserPools";
import CommonTable from "../../components/CommonTable/CommonTable";

import {
  clearCreatedPool,
  clearPoolsState,
  createPool,
  requestPools,
  setFilters,
  setRefreshedApiKey,
} from "../../store/reducers/pools/pools-reducer";
import {
  getIsLoading,
  getPools,
} from "../../store/reducers/pools/pools-selectors";

import { IUserPoolsRequestBody } from "../../store/reducers/pools/pools-types";

import { filtersUsersPool } from "../../constants/constants";
import { poolsTableHeaders } from "./constants";

import "./UserPools.scss";

export type ModalUserPoolsState = {
  isOpen: boolean;
  create: boolean;
  createSuccess: boolean;
};

const initialState = {
  isOpen: false,
  create: false,
  createSuccess: false,
};

const UserPools: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const { pools, pagination, filters: poolsFilters } = useAppSelector(getPools);

  const poolPagination = {
    ...pagination,
    current_page: poolsFilters.current_page,
    per_page: poolsFilters.per_page,
    setFilters,
  };

  const [modal, setModal] = useState<ModalUserPoolsState>(initialState);

  const onCreateUsersPool = () => {
    setModal(() => ({
      ...initialState,
      isOpen: true,
      create: true,
    }));
  };

  const onModalClose = () => {
    dispatch(clearCreatedPool());
    dispatch(setRefreshedApiKey({ refreshedApiKey: null }));
    setModal(initialState);
  };

  const createUserPoolSubmit = (name: string) => {
    dispatch(
      createPool({
        title: name,
        access_lifetime_min: 60 * 10,
        refresh_lifetime_min: 60 * 60 * 24 * 30,
      })
    );
    setModal(() => ({
      ...initialState,
      isOpen: true,
      createSuccess: true,
    }));
  };

  useEffect(() => {
    dispatch(requestPools(poolsFilters as IUserPoolsRequestBody));
  }, [dispatch, poolsFilters]);

  useEffect(
    () => () => {
      dispatch(clearPoolsState());
    },
    [dispatch]
  );

  return (
    <div className="user-pools page">
      {pools && poolsFilters && (
        <>
          <SearchHeader
            primaryFilters={filtersUsersPool}
            setFilters={setFilters}
            button={{ label: "Создать user pool", onClick: onCreateUsersPool }}
          />
          <CommonTable
            headerTitles={poolsTableHeaders}
            tableRows={pools}
            pageType={"pools"}
            pagination={poolPagination}
          />
          <ModalUserPools
            modal={modal}
            onModalClose={onModalClose}
            createUserPoolSubmit={createUserPoolSubmit}
          />
        </>
      )}

      <Preloader isLoading={isLoading} />
    </div>
  );
};

export default UserPools;
