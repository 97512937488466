import { FC, useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./TargetUsers.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperNavButtons from "./SwiperNavBtn/SwiperNavBtn";
import SwiperBullet from "./SwiperBullet/SwiperBullet";

import data from "./TargetUsers-data";

const TargetUsers: FC = () => {
  const [slide, setSlide] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const setDeviceValue = useCallback(() => {
    setIsMobile(document.documentElement.offsetWidth < 761);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDeviceValue);

    return () => window.removeEventListener("resize", setDeviceValue);
  }, [setDeviceValue]);

  return (
    <div className="target-users">
      <div className="target-users-info">
        <h2 className="target-users-info__header">
          Кому это может быть интересно:
        </h2>
        <div className="target-users-info__bullets">
          {data.map((item, index) => (
            <SwiperBullet key={item.label} slide={slide} index={index} />
          ))}
        </div>
      </div>
      <div className="target-users-cards">
        <Swiper
          // @ts-ignore
          slidesPerView={isMobile ? 1 : 2}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          loop={true}
          slideActiveClass="target-users-cards__active-card"
          slideNextClass="none"
          slidePrevClass="target-users-cards__active-card"
          onActiveIndexChange={(swiper: any) => setSlide(swiper.realIndex)}
        >
          <SwiperNavButtons />
          {[...data, ...data].map((current, index) => (
            <SwiperSlide key={index}>
              <div className="target-users-cards__item">
                <div className="target-users-cards__item__label">
                  {current.label}
                </div>
                <div className="target-users-cards__item__description">
                  {current.description}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default TargetUsers;
