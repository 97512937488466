import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { BaseThunk, SliceActions } from "../../store";
import { toast } from 'react-toastify';

import policyApi from "../../../api/policy-api";

// import { requestUsersByPool } from "../pools/pools-reducer";

import {
  IPolicesState,
  IPoliciesGroupRequest,
  IPoliciesGroupPatch,
  IPoliciesBindPost,
  IOpenapiPolicyRequest,
  ISwaggerSpec,
  IHttpPolicy,
  IHttpPolicyCreate,
  IOpenapiPolicyCreate,
  IPolicyRequestBody,
  IPaginationFilters,
  ISpecificationUrlCreate,
  IOpenapiPolicyPatch,
  IParsedOpenapiPolicyFromUrl,
  ISwaggerSetup,
  IPoliciesGroupCreate,
  IPoliciesGroupStatusPatch,
  IPoliciesGroupAddAndRemove,
} from "./policy-types";
import { requestUsersByPool } from "../users/users-reducer";

const initialState: IPolicesState = {
  isLoading: false,
  policies: {
    policies: null, 
    pagination: {
      total_pages: 0, 
      total_items: 0,
    }
  },
  filters: { 
    policy_types: [],
    title:"",
    current_page: 0,
    per_page: 10,
  },
  policyById: null,
  isOpenApiUpdateSuccess: false,
  openApiPolicyFromFile: null,
  parsedOpenapiPolicyFromUrl: null,
  swaggerSetup: null,
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading;
    },
    setPolicies(
      state,
      action: PayloadAction<{
        policies: (
          | IOpenapiPolicyRequest
          | IHttpPolicy
          | IPoliciesGroupRequest
        
        )[] | any;
      }>,
    ) {
      state.policies.policies = action.payload.policies.polices;
      state.policies.pagination = {
        total_pages: action.payload.policies.page.total_pages, 
        total_items:action.payload.policies.page.total_items
      };
    },
    setPolicyById(
      state,
      action: PayloadAction<{
        policyById:
          | IOpenapiPolicyRequest
          | IHttpPolicy
          | IPoliciesGroupRequest
          | null;
      }>,
    ) {
      state.policyById = action.payload.policyById;
    },
    clearPolicyById(
      state,
      action: PayloadAction<{
        policyById:
          | IOpenapiPolicyRequest
          | IHttpPolicy
          | IPoliciesGroupRequest
          | null;
      }>,
    ) {
      state.policyById = action.payload.policyById;
    },
    setOpenApiPolicyFromFile(
      state,
      action: PayloadAction<{ openApiPolicyFromFile: ISwaggerSpec }>,
    ) {
      state.openApiPolicyFromFile = action.payload.openApiPolicyFromFile;
    },
    setFilters(state, action: PayloadAction<{ filters: any }>) {
      state.filters = {...state.filters, ...action.payload.filters};
    },
    setParsedOpenapiPolicyFromUrl(
      state,
      action: PayloadAction<{
        parsedOpenapiPolicyFromUrl: IParsedOpenapiPolicyFromUrl | null;
      }>,
    ) {
      state.parsedOpenapiPolicyFromUrl =
        action.payload.parsedOpenapiPolicyFromUrl;
    },
    setIsOpenApiUpdateSuccess(
      state,
      action: PayloadAction<{
        isOpenApiUpdateSuccess: boolean;
      }>,
    ) {
      state.isOpenApiUpdateSuccess = action.payload.isOpenApiUpdateSuccess;
    },
    setSwaggerSetup(
      state,
      action: PayloadAction<{ swaggerSetup: ISwaggerSetup | null }>,
    ) {
      state.swaggerSetup = action.payload.swaggerSetup;
    },
  },
});

const { reducer, actions } = policySlice;

export const {
  setIsLoading,
  setPolicies,
  setPolicyById,
  clearPolicyById,
  setOpenApiPolicyFromFile,
  setFilters,
  setParsedOpenapiPolicyFromUrl,
  setIsOpenApiUpdateSuccess,
  setSwaggerSetup,
} = actions;

/* Thunk */
// Сброс сваггер стейта
export const clearPolicyBySwagger = (): Thunk => (dispatch) => {
  dispatch(clearPolicyById({ policyById: null }));
  dispatch(setSwaggerSetup({ swaggerSetup: null }));
  dispatch(setParsedOpenapiPolicyFromUrl({ parsedOpenapiPolicyFromUrl: null }));
};

/* Все policies */
export const requestPolicies =
  ({
    current_page = 1,
    per_page = 20,
    title = "",
    policy_types = [],
  }: IPolicyRequestBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.getAllPolicies({
      current_page,
      per_page,
      title,
      policy_types,
    });

    if (status === 200) {
      dispatch(setPolicies({ policies: data }));
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* policy по айди */
export const requestPolicyById =
  ({ id }: { id: string }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.getPolicyById({ id });
    if (status === 200) {
      dispatch(setPolicyById({ policyById: data }));
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* новый http policy */
export const createHttpPolicy =
  (body: IHttpPolicyCreate): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.postHttpPolicy(body);
    if (status > 200 && status < 300) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicies({}));
      toast('Новый набор http правил успешно создан')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить http policy */
export const updateHttpPolicy =
  ({ id, body }: { id: string; body: IHttpPolicyCreate }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchHttpPolicy({ id, body });
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      toast('Набор правил обновлён')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* отправить спецификации по урлу */
export const sendSpecificationUrl =
  (body: ISpecificationUrlCreate): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await policyApi.postOpenapiUrlSpec({
      url: body.url,
    });

    if (status === 201) {
      const { url: openapi_spec_url, password, login } = body;

      dispatch(
        setParsedOpenapiPolicyFromUrl({ parsedOpenapiPolicyFromUrl: data }),
      );
      dispatch(
        setSwaggerSetup({
          swaggerSetup: {
            openapi_spec_url,
            password,
            login,
          },
        }),
      );
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* отправить спецификации JSON/string */
export const sendSpecificationJson =
  (body: {}): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await policyApi.postOpenapiSpec(body);

    if (status > 200 && status < 300) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(setOpenApiPolicyFromFile({ openApiPolicyFromFile: data }));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* создать openapi policy */
export const createOpenapiPolicy =
  (body: IOpenapiPolicyCreate): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.postOpenapiPolicy(body);
    if (status > 200 && status < 300) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicies({}));
      toast('Набор правил openapi создан')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить openapi policy */
export const updateOpenapiPolicyFields =
  ({ id, body }: { id: string; body: IOpenapiPolicyPatch }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchOpenapiPolicyFields({
      id,
      body,
    });
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      toast('Набор правил обновлён')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить openapi policy по URL (with swagger)*/
export const updateOpenapiPolicyByUrl =
  ({ id }: { id: string }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await policyApi.patchOpenapiPolicyByUrl({
      id,
    });

    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      dispatch(setIsOpenApiUpdateSuccess({ isOpenApiUpdateSuccess: true }));
      toast('Набор правил обновлён')
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить openapi policy по Spec (.json file) */
export const updateOpenapiPolicyBySpec =
  ({ id, body }: { id: string; body: any }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { endpoints } = body;
    const { status, data: parsedOpenApiPolicy } =
      await policyApi.postOpenapiSpec(endpoints);

    if (status === 201) {
      dispatch(
        setOpenApiPolicyFromFile({
          openApiPolicyFromFile: parsedOpenApiPolicy,
        }),
      );

      const { status, data } = await policyApi.patchOpenapiPolicyBySpec({
        id,
        body: {
          ...body,
          endpoints: parsedOpenApiPolicy,
        },
      });
      toast('Набор правил обновлён')

      if (status === 200) {
        dispatch(setPolicyById({ policyById: data }));
        dispatch(setIsOpenApiUpdateSuccess({ isOpenApiUpdateSuccess: true }));
        dispatch(requestPolicies({}));
        toast('Набор правил обновлён')
      }
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* новая группа policy */
export const createPolicyGroup =
  (body: IPoliciesGroupCreate): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { title, user_pool_id } = body;
    const { status, data } = await policyApi.postPoliciesGroup({
      title,
      user_pool_id,
    });

    if (status === 201) {
      const { policy_ids } = body;
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(
        addPoliciesToGroup({
          id: data.id,
          body: {
            policy_ids,
          },
        }),
      );
      toast('Группа наборов правил создана')
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить группу policy */
export const updatePolicyGroup =
  ({ id, body }: { id: string; body: IPoliciesGroupPatch }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchPoliciesGroup({ id, body });
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      // dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      toast('Группа наборов правил обновлена')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* добавить policies в группу policy */
export const addPoliciesToGroup =
  ({ id, body }: { id: string; body: IPoliciesGroupAddAndRemove }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchAddPoliciesGroup({
      id,
      body,
    });
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      // dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      toast('Наборов правил добавлен в группу')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* удалить policies из группы policy */
export const removePoliciesFromGroup =
  ({ id, body }: { id: string; body: IPoliciesGroupAddAndRemove }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchRemovePoliciesGroup({
      id,
      body,
    });
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      // dispatch(requestPolicyById({ id }));
      dispatch(requestPolicies({}));
      toast('Наборов правил удалён из группы')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* изменить статусы policies */
export const updatePoliciesStatuses =
  (body: IPoliciesGroupStatusPatch): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.patchStatusPolicies(body);
    if (status === 200) {
      /*todo уточнить по дизайну и дописать логику(если надо)*/
      dispatch(requestPolicies({}));
      toast('Статус изменён')
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

/* привязать policy к юзеру */
export const bindPolicyToUser =
  ({ id, body }: { id: any; body: IPoliciesBindPost }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const { status, data } = await policyApi.postBindPolicyToUser({
      id: id.id,
      body,
    });
    if (status === 200) {
      dispatch(requestUsersByPool({ user_pools_ids: [id] }));
      toast('Набор правил привязан к пользователю')
    } 
    dispatch(setIsLoading({ isLoading: false }));
  };

/* отвзяать policy от юзера */
export const unBindPolicyFromUser =
  ({ id, body }: { id: any; body: IPoliciesBindPost }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));
    const res = await policyApi.deletePolicyFromBindUserPool({
      id: id.id,
      body,
    });
    if (res.status === 200) {
      dispatch(requestUsersByPool({ user_pools_ids: [id] }));
      toast('Набор правил отвязан от пользователя')
    } else {
      console.log(res);
      
    }
    dispatch(setIsLoading({ isLoading: false }));
  };

export default reducer;

type Actions = SliceActions<typeof actions>;
type Thunk = BaseThunk<Actions, void>;
