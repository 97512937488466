import { FC, ReactNode } from "react";

import "./PagesContainer.scss";

const PagesContainer: FC<IProps> = ({ children }) => {
  return <div className="pages-container">{children}</div>;
};

export default PagesContainer;

interface IProps {
  children: ReactNode;
}
