import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { DropDownMenu } from "../Inputs/DropDownMenu/DropDownMenu";

import { useAppSelector } from "../../store/store";
import { getMe } from "../../store/reducers/auth/auth-selectors";

import logo from "../../assets/images/header/logo.svg";
import themeLightIcon from "../../assets/images/header/theme-light-icon.svg";
import notificationLightIcon from "../../assets/images/header/notification-light-icon.svg";
import themeDarkIcon from "../../assets/images/header/theme-dark-icon.svg";
import notificationDarkIcon from "../../assets/images/header/notification-dark-icon.svg";
import userPicture from "../../assets/media/avatar-icons/avatar-icon-1.svg";

import "./Header.scss";
import LangDropdown from "./LangDropdown/LangDropdown";
import { getLang, setLang } from "../../store/functions";

const Header: FC = () => {
  const [lang, setLanguage] = useState(getLang());
  const [isLight, setIsLight] = useState<boolean>(true);
  const userInfo = useAppSelector(getMe);

  useEffect(() => {
    if (lang) {
      setLang({ lang });
    }
  }, [lang]);

  return (
    <div className="header">
      <NavLink to="/">
        <img src={logo} alt="logo" />
      </NavLink>
      <div className="header-info-section">
        <div className="header-info-section__handlers">
          <div className="lang-select">
            <p>{lang ?? "Рус"}</p>
            <LangDropdown setLanguage={setLanguage} />
          </div>
          <img
            className="handler-icon-theme"
            src={isLight ? themeLightIcon : themeDarkIcon}
            alt="theme"
            onClick={() => setIsLight(!isLight)}
          />
          <img
            className="handler-icon-notification"
            src={isLight ? notificationLightIcon : notificationDarkIcon}
            alt="notification"
          />
        </div>
        <div className="header-user-section">
          <div className="header-user-section__info">
            <div className="user-name">
              {`${userInfo.first_name}` || "Name"}
            </div>
            <div className="user-email">
              {`${userInfo.email}` || "name@gmail.com"}
            </div>
          </div>
          <div className="header-user-section__settings">
            <img src={userPicture} alt="user_picture" />
            <DropDownMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
