import { RootState } from "../../store";

export const getIsLoading = ({ pools: { isLoading } }: RootState) => isLoading;
export const getPools = ({
  pools: { pools: { pools, pagination }, isLoading, filters },
}: RootState) => {
  return { pools, pagination, isLoading, filters };
};
export const getStatePools = (state: RootState) => state.pools;
export const pools = ({
  pools: { pools: { pools, pagination }, isLoading, filters, usersByPool },
}: RootState) => {
  return { pools, pagination, isLoading, filters, usersByPool };
};
export const getCurrenUserPoolId = ({
  pools: { currenUserPoolId },
}: RootState) => currenUserPoolId;
export const getPoolById = ({
  pools: { isLoadingPoolById, poolById },
}: RootState) => {
  return { isLoadingPoolById, poolById };
};
export const getCreatedPool = ({ pools: { createdPool } }: RootState) =>
  createdPool;
export const getRefreshApiKey = ({ pools: { refreshedApiKey } }: RootState) =>
  refreshedApiKey;
