import { FC } from "react";
import { ModalState } from "../../../pages/Users/Users";
import ModalContainer from "../ModalContainer";
import UserInputs from "./ModalContent/UsersInputs";

const ModalUsers: FC<ModalUsersProps> = ({
  modal,
  onModalClose,
  onModalInputSubmit,
}) => {
  return (
    <ModalContainer isOpen={modal.isOpen} handleClose={onModalClose}>
      {modal.detail && (
        <UserInputs
          modalType="details"
          handleSubmit={onModalInputSubmit}
          handleClose={onModalClose}
        />
      )}
    </ModalContainer>
  );
};

export default ModalUsers;

interface ModalUsersProps {
  modal: ModalState;
  onModalClose: () => void;
  onModalInputSubmit: (input: string, modalType: string) => void;
}
