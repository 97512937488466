import React, { FC } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { atelierLakesideLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { nnfx } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";

const str = `from fastapi import FastAPI, HTTPException, Body, Query
from stlock import AuthClient

# создание экземпляра класса
AC = AuthClient(
    endpoint="https://d5du1ra5pgq6gr2ba1jc.apigw.yandexcloud.net/",
    user_pool_id="USER_POOL_ID",
    owner_id="OWNER_ID",
    api_key="API_KEY"
)
app = FastAPI()


# Регистрация пользователя
@app.post("/register", status_code=201)
def register_user(username=Body(...), email=Body(...), phone=Body(...), password=Body(...)):
   data, status_code = AC.register(username, email, phone, password)
   if status_code >= 400:
       raise HTTPException(status_code, data)
   return data


# Авторизация пользователя
@app.post('/login')
def login(username: str = Body(...), password: str = Body(...)):
   data, status_code = AC.authorize_user(username, password)
   if status_code >= 400:
       raise HTTPException(status_code, data)
   return data


# Обмен refresh токена но новые refresh и access токены
@app.post("/refresh")
def do_refresh_token(refresh_token=Body(...)):
    data, status_code = AC.do_refresh(refresh_token['refresh_token'])
    if status_code >= 400:
        raise HTTPException(status_code, data)
    return data


# Отозвать refresh token для выхода из аккаунта
@app.post("/logout")
def do_refresh_token(refresh_token=Body(...)):
    data, status_code = AC.unauthorized(refresh_token['refresh_token'])
    if status_code >= 400:
        raise HTTPException(status_code, data)
    return data


# Получение информации из токена
@app.get("/user")
def get_user_info(token: str = Query(...)):
    user_data = AC.decode(token)
    return user_data
`;
const str2 = `POST http://localhost:8000/register
Body {
    "username": "test",
    "email": "test@gmail.com",
    "phone": "79111111111",
    "password": "test"
}
`;
const str3 = `{
  "policy_ids": [],
  "user_pool_id": "0e2173b5-b55f-4ea4-bec5-450e2c12a458",
  "owner_id": "b3893a06-5931-4160-9e62-938ab173b6c8",
  "username": "test6",
  "email": "test6@gmail.com",
  "phone": "79520546024",
  "id": "a351d4db-4e7f-48ef-b667-e7af3a480086",
  "status": "ACTIVE",
  "creation_timestamp": 1693303651
}
`;
const str4 = `POST http://localhost:8000/login
Body {
    "username": "test",
    "password": "test"
}
`;

const str5 = `{
  "access_token": {JWT},
  "refresh_token": {JWT}
}
`;

const str6 = `POST http://localhost:8000/refresh
Body {
    "refresh_token": {JWT}
}
`;
const str7 = `{
  "access_token": {JWT},
  "refresh_token": {JWT}
}

`;
const str8 = `POST http://localhost:8000/logout
Body {
    "refresh_token": {JWT}
}
`;
const str9 = `STATUS_CODE: 200`;

const DocTitle = ({ title }: { title: string }) => (
  <h3 style={{ margin: "1rem 0" }}>
    <strong>{title}</strong>
  </h3>
);

const Python: FC = () => {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <h2 style={{ marginBottom: "1.5rem" }}>Python библиотека</h2>
      <h4 style={{ marginBottom: "1rem" }}>
        Библиотека для сервиса авторизации <strong>STLock</strong> (powered by{" "}
        <strong>SmartTechnoLab</strong>) <br />
        <strong>Статус:</strong> В разработке
      </h4>
      <DocTitle title={"Библиотека Python STLock"} />
      <DocTitle title={"Быстрый старт"} />
      Пример с библиотекой FastAPI
      <br />
      <br />
      pip install stlock
      <br />
      pip install fastapi
      <br />
      pip install uvicorn
      <br />
      <p style={{ padding: "1rem 0" }}>main.py:</p>
      <SyntaxHighlighter language="python" style={vs}>
        {str}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Для запуска сервера:</p>
      <p style={{ padding: "1rem 0" }}>uvicorn main:app</p>
      <DocTitle title={"Описание методов:"} />
      <p style={{ padding: "1rem 0" }}>
        AC.register(username, email, phone, password)
        <br />
        Создаёт нового пользователя в базе данных
      </p>
      <p style={{ padding: "1rem 0" }}>
        AC.authorize_user(username, password)
        <br />
        Авторизирует запрос на логин пользователя, выдаёт access & refresh
        токены
      </p>
      <p style={{ padding: "1rem 0" }}>
        AC.decode(access_token)
        <br />
        Декодирует токен, возвращает словарь с данными о пользователе
      </p>
      <p style={{ padding: "1rem 0" }}>
        AC.do_refresh(refresh_token)
        <br />
        Обменивает refresh token на новый access и refresh токены
      </p>
      <p style={{ padding: "1rem 0" }}>
        AC.unauthorized(refresh_token)
        <br />
        Отзывает refresh_token
      </p>
      <DocTitle title={"примеры запросов :"} />
      <p style={{ padding: "1rem 0" }}>создание пользователя:</p>
      <p style={{ padding: "1rem 0" }}>Input:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str2}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Output:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str3}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Авторизация пользователя:</p>
      <p style={{ padding: "1rem 0" }}>Input:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str4}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Output:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str5}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Обновление токена:</p>
      <p style={{ padding: "1rem 0" }}>Input:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str6}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Output:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str7}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Отзыв refresh токена:</p>
      <p style={{ padding: "1rem 0" }}>Input:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str8}
      </SyntaxHighlighter>
      <p style={{ padding: "1rem 0" }}>Output:</p>
      <SyntaxHighlighter language="javascript" style={vs}>
        {str9}
      </SyntaxHighlighter>
    </div>
  );
};

export default Python;
