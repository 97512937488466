import { FC, useState } from "react";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";
import Button from "../../../Inputs/Button/Button";
import { inputModalContent } from "../../../../constants/constants";
import "./UserInputs.scss";

const UserInputs: FC<Props> = ({
  width,
  modalType,
  handleSubmit,
  handleClose,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setIsInputValid] = useState(true);

  const handleButtonSubmit = () => {
    const isInputValid = !!inputValue;
    setIsInputValid(isInputValid);

    if (isInputValid) handleSubmit(inputValue, modalType);
  };

  return (
    <div className="modal-with-inputs" style={{ width }}>
      <h4 className="modal-with-inputs__modal-title">
        {inputModalContent[modalType]["title"]}
      </h4>
      <ModalInput
        placeholder={inputModalContent[modalType]["placeholder"]}
        value={inputValue}
        onChange={setInputValue}
        isInputValid={isInputValid}
      />
      <div className="modal-with-inputs__modal-button-container">
        {handleClose && (
          <Button
            onClick={handleClose}
            label="Отмена"
            isDisabled={false}
            color="light-blue"
          />
        )}

        <Button
          onClick={handleButtonSubmit}
          label="Сохранить"
          color="blue"
          isDisabled={false}
        />
      </div>
    </div>
  );
};

export default UserInputs;

interface Props {
  handleSubmit: (name: string, modalType: string) => void;
  handleClose?: () => void;
  modalType:
    | "create"
    | "details"
    | "editUserPool"
    | "newPolicy"
    | "apiPolicyWithFile"
    | "apiModalWithSwagger";
  width?: string | number;
}
