import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Profile from "../../pages/Profile/Profile";
import Dashboard from "../../pages/Dashboard/Dashboard";
import UserPools from "../../pages/UserPools/UserPools";
import Users from "../../pages/Users/Users";
import Policy from "../../pages/Policy/Policy";
import Binding from "../../pages/Binding/Binding";
import DocsPage from "../../pages/Docs/Docs";

import "./PagesRouter.scss";

const PagesRouter: FC = () => (
  <div className="pages-router">
    <Routes>
      <Route index element={<Navigate to={"/dashboard"} />} />

      <Route path="profile" element={<Profile />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="pools" element={<UserPools />} />
      <Route path="users" element={<Users />} />
      <Route path="policy" element={<Policy />} />
      <Route path="binding" element={<Binding />} />
      <Route path="docs" element={<DocsPage />} />

      <Route path="*" element={<Dashboard />} />
    </Routes>
  </div>
);

export default PagesRouter;
