import { FC, useState } from "react";

import InputText from "../InputText/InputText";
import { IconButton } from "@mui/material";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import "./InputPassword.scss";

const InputPassword: FC<Props> = ({
  label,
  placeholder,
  errorMsg,
  isError,
  value,
  onChange,
  useForm = {},
}) => {
  const [isShowPass, setIsShowPass] = useState<boolean>(false);

  const handleShowPass = () => setIsShowPass(!isShowPass);

  return (
    <div className="input-password-wrapper">
      <InputText
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        errorMsg={errorMsg}
        isError={isError}
        type={isShowPass ? "text" : "password"}
        useForm={useForm}
      />
      <IconButton
        className="icon-button"
        style={{ color: "#5282FF" }}
        onClick={handleShowPass}
      >
        {isShowPass ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </div>
  );
};

export default InputPassword;

interface Props {
  value: string;
  onChange: (value: string) => void;
  label: string;
  placeholder?: string;
  errorMsg?: string;
  isError?: boolean;
  useForm?: any;
}
