import React from "react";
import cn from "classnames";
import "./SwiperBullet.scss";

interface IBulletProps {
  slide: number;
  index: number;
}

const SwiperBullet = ({ slide, index }: IBulletProps) => {
  const isActive = slide === index || slide === index + 4;
  return (
    <div
      className={cn("bullet", {
        "bullet-active": isActive,
      })}
    ></div>
  );
};

export default SwiperBullet;
