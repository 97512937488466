import { FC } from "react";
import { useNavigate } from "react-router-dom";

import "./Logo.scss";

const Logo: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate("/landing");

  return (
    <div className="logo" onClick={handleNavigate}>
      <i />
    </div>
  );
};

export default Logo;
