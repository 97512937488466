import axios from "axios";
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../store/functions";

const url = process.env.REACT_APP_HTTP_URL;

const instance = axios.create({
  baseURL: url,
  validateStatus: (status) => {
    return status >= 200 || status < 300 || status === 401;
  },
});

instance.interceptors.response.use(async (response) => {
  if (
    response.status === 401 &&
    !response.request.responseURL.includes("refresh")&&
    !response?.request?.responseURL?.includes('login')
  ) {
    const responseData = await fetch(`${url}owners/refresh`, {
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`,
      },
    });

    if (responseData.ok) {
      const data = await responseData.json();

      setAccessToken({ accessToken: data.access_token });
      setRefreshToken({ refreshToken: data.refresh_token });

      return await instance.request({
        ...response.config,
        headers: {
          Authorization: `Bearer ${data.access_token}`,
          "Content-Type": "application/json",
        },
        data: response.config.data,
      });
    }

    return response;
  }

  return response;
});


export interface APIResponse<R = any> {
  status: number;
  data: R;
}

/* todo: сделать по факту*/
export type APIErrorResult = APIResponse<string>;

export default instance;