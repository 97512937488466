import { FC } from "react";

import SignUnForm from "./SignUnForm/SignUnForm";
import SignUpGreetings from "./SignUpGreetings/SignUpGreetings";

import "./SignUpPage.scss";

const SignUpPage: FC = () => {
  return (
    <div className="sign-up-page page">
      <SignUnForm />
      <SignUpGreetings />
    </div>
  );
};

export default SignUpPage;
