import React, { FC } from "react";
import cn from "classnames";

import "./Icon.scss";

const Icon: FC<IProps> = ({
  icon,
  size = "2",
  width,
  height,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn([className, "icon-wrapper"])}
      onClick={() => onClick?.()}
    >
      <img
        src={icon}
        alt="icon"
        style={{
          width: `${width ? width : size}rem`,
          height: `${height ? height : size}rem`,
        }}
      />
    </div>
  );
};

export default Icon;

interface IProps {
  className?: string;
  icon: any;
  size?: string;
  width?: string | number;
  height?: string | number;
  onClick?: any;
}
