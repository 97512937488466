
export enum TypePageTable {
  users = "users",
  userPool = "user-pools",
  policy = "policy",
}

export interface IConstantFilter {
  id: number | string;
  label: string;
  default: boolean;
  type?: string;
  children?: IConstantFilters[];
  filter?: "ACTIVE" | "INACTIVE" | "BLOCKED" | "";
  data?: any;
}
export interface IConstantFilters {
  type: string;
  label: string;
  list: IConstantFilter[]
}

export interface IPoliciesValues extends IConstantFilters {
  filterValue: "HTTP" | "OpenAPI" | "Group" | "";
}

export interface IInput {
  name: string;
  label?: string;
  placeholder: string;
  type?: string;
  JSONPlaceholder?: boolean;
  JSONPlaceholderDownload?: string;
  JSONPlaceholderSuccess?: string;
}

export interface IInputModalContent {
  title: string;
  subtitle?: string;
  placeholder: string;
  checkBoxes?: string[];
  buttonLabel: string;
  inputs?: IInput[];
}

export interface IModalContent {
  title: string;
  subtitle: string;
  checkBoxes: string[];
  buttonLabel?: string;
}

export type RowStatus = {
  status: string;
  PoolId?: string | null;
  UserId?: string | null;
  policyId?: string | null;
};

export interface IInputModal {
  create: IInputModalContent;
  details: IInputModalContent;
  editUserPool: IInputModalContent;
  newPolicy: IInputModalContent;
  apiPolicyWithFile: IInputModalContent;
  apiModalWithSwagger: IInputModalContent;
  openApiSettings: IInputModalContent;
  editHttpPolicy: IInputModalContent;
  editOpenapiPolicy: IInputModalContent;
  editGroupPolicy: IInputModalContent;
}

export interface IModal {
  apiModal: IModalContent;
  httpModal: IModalContent;
}

export type ApiPolicyWithFileKeys = "fileValue";
export type UpdateApiPolicyWithFileKeys = "fileName" | "fileValue";

export type ApiPolicyWithSwaggerKeys =
  | "openapi_spec_url"
  | "login"
  | "password";

export const filtersUsersPool: any = {
  type: 'statuses', 
  label: 'Фильтр',
  searchParam: 'title',
  list: [
    {
      id: 1,
      label: "Активные",
      filter: "ACTIVE",
      default: false,
    },
    {
      id: 2,
      label: "Неактивные",
      filter: "INACTIVE",
      default: false,
    },
    {
      id: 3,
      label: "Заблокированные",
      filter: "BLOCKED",
      default: false,
    },
  ]
};

export const filterUsers: any = {
  type: 'statuses', 
  label: 'Фильтр',
  searchParam: 'username',
  list: [
    {
      id: 1,
      label: "Активные",
      filter: "ACTIVE",
      default: false,
    },
    {
      id: 2,
      label: "Неактивные",
      filter: "INACTIVE",
      default: false,
    },
    {
      id: 3,
      label: "Заблокированные",
      filter: "BLOCKED",
      default: false,
    },
  ]
};

// export const filtersUsers: IConstantFilter[] = [
//   {
//     id: 0,
//     label: "Все",
//     filter: "",
//     default: true,
//   },
//   {
//     id: 1,
//     label: "User pool",
//     filter: "",
//     default: false,
//     type: "users",
//   },
//   {
//     id: 2,
//     label: "Статус",
//     filter: "",
//     default: false,
//     type: "users",
//   },
// ];

export const filtersPolicy: any[] = [
  {
    id: 0,
    label: "HTTP",
    filterValue: "HTTP",
    default: false,
  },
  {
    id: 1,
    label: "OpenAPI",
    filterValue: "OpenAPI",
    default: false,
  },
  {
    id: 2,
    label: "Группа",
    filterValue: "Group",
    default: false,
  },
];

export const dataTable: any[] = [
  {
    id: 1.1,
    title: "Название",
    quantity: 45,
    status: "ACTIVE",
  },
  {
    id: 1.2,
    title: "Название",
    quantity: 45,
    status: "BLOCKED",
  },
  {
    id: 1.3,
    title: "Название",
    quantity: 45,
    status: "INACTIVE",
  },
  {
    id: 1.4,
    title: "Название",
    quantity: 45,
    status: "BLOCKED",
  },
  {
    id: 1.5,
    title: "Название",
    quantity: 45,
    status: "BLOCKED",
  },
];

export const pageTableContent = {
  "user-pools": {
    title: "user pool",
    "header-title": ["Название", "Количество пользователей", "Статус"],
  },
  users: {
    title: "users",
    "header-title": ["Имя пользователя", "Название user pools", "Статус"],
  },
  policy: { title: "policy", "header-title": ["Название", "Тип", 'Пулл', "Статус"] },
};

export const inputModalContent: IInputModal = {
  create: {
    title: "Создайте новый user pool",
    placeholder: "Введите название user pool",
    buttonLabel: "Сохранить",
  },
  details: {
    title: "Детали",
    placeholder: "Введите причину смены статуса",
    buttonLabel: "Сохранить",
  },
  editUserPool: {
    title: "Введите новое название user pool",
    placeholder: "Введите новое название user pool",
    buttonLabel: "Сохранить",
  },
  newPolicy: {
    title: "Создайте новый policy",
    subtitle: "Введите название user pool",
    placeholder: "Введите название policy",
    checkBoxes: ["HTTP", "OpenAPI", "Группа"],
    buttonLabel: "Далее",
  },
  apiPolicyWithFile: {
    title: "Создание с помощью файла policy",
    inputs: [
      {
        name: "fileValue",
        JSONPlaceholder: true,
        JSONPlaceholderDownload: "Загрузите JSON файл",
        JSONPlaceholderSuccess: "файл успешно загружен",
        type: "file",
        placeholder: "",
      },
    ],
    placeholder: "",
    buttonLabel: "Составить набор роутов",
  },
  apiModalWithSwagger: {
    title: "Создание через доступ к сваггеру policy",
    inputs: [
      // {
      //   name: "title",
      //   placeholder: "Введите название policy",
      //   type: "text",
      // },
      {
        name: "openapi_spec_url",
        label: "Ссылка",
        placeholder: "Введите ссылку",
        type: "text",
      },
      {
        name: "login",
        label: "Логин",
        placeholder: "Введите логин",
        type: "text",
      },
      {
        name: "password",
        label: "Пароль",
        placeholder: "Введите пароль",
        type: "password",
      },
    ],
    placeholder: "",
    buttonLabel: "Составить набор роутов",
  },
  openApiSettings: {
    title: "Название policy",
    placeholder: "Введите название для поиска",
    buttonLabel: "Сохранить",
  },
  editHttpPolicy: {
    title: "Введите новое название http policy",
    placeholder: "Введите новое название http policy",
    buttonLabel: "Сохранить",
  },
  editOpenapiPolicy: {
    title: "Введите новое название openapi policy",
    placeholder: "Введите новое название openapi policy",
    buttonLabel: "Сохранить",
  },
  editGroupPolicy: {
    title: "Введите новое название group policy",
    placeholder: "Введите новое название group policy",
    buttonLabel: "Сохранить",
  },
};

export const modalContent: IModal = {
  apiModal: {
    title: "Создайте новый policy",
    subtitle: "Выберите формат разрешения для каждого роута своего сервиса",
    checkBoxes: ["С помощью файла", "Доступ к сваггеру"],
    buttonLabel: "Далее",
  },
  httpModal: {
    title: "Создание HTTP policy",
    subtitle: "Дайте разрешения для возможных HTTP методов:",
    checkBoxes: [
      "POST",
      "GET",
      "PUT",
      "PATCH",
      "DELETE",
      "CONNECT",
      "OPTIONS",
      "TRACE",
      "HEAD",
    ],
    buttonLabel: "Сохранить",
  },
};

export const mapPoolsToFilters = (
  pools: any,
): IConstantFilters[] | null => {
  if (!pools) return null;

  return [
    ...pools?.map((pool: any) => ({
      id: pool.id,
      label: pool.title,
      default: false,
    })),
  ];
};

export const statusParser = (status: string) => {
  switch (status) {
    case "ACTIVE":
      return {
        color: "active",
        label: "Активен",
      };
    case "INACTIVE":
      return {
        color: "inactive",
        label: "Неактивен",
      };
    case "BLOCKED":
      return {
        color: "blocked",
        label: "Заблокирован",
      };
    default:
      return {
        color: "inactive",
        label: "Нет статуса",
      };
  }
};

export const openApiMethod = (method: string) => {
  switch (method) {
    case "get":
      return {
        color: "blue",
        label: "GET",
      };
    case "post":
      return {
        color: "green",
        label: "POST",
      };
    case "patch":
      return {
        color: "green-light",
        label: "GET",
      };
    case "delete":
      return {
        color: "red",
        label: "DELETE",
      };
  }
};

export const defaultOpenHttpAndApiMethods = {
  get: true,
  head: true,
  post: true,
  put: true,
  delete: true,
  connect: true,
  options: true,
  trace: true,
  patch: true,
};

export const defaultUserFilters: any = {
  status: [{ id: "", label: "", default: false, filter: "" }],
  userPools: [{ id: "", label: "", default: false, filter: "" }],
  inputValue: "",
};

export const defaultFilterHeader: any = [
  { id: "", label: "", default: false },
];
