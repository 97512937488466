import React, { FC, useEffect, useMemo, useState } from "react";

import Button from "../../Inputs/Button/Button";
import ModalButton from "./ModalContent/ModalButton";
import ModalContainer from "../ModalContainer";
import {
  GroupSelectedItemsType,
  IPoliciesGroupRequest,
} from "../../../store/reducers/policy/policy-types";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  getIsLoading,
  getPolicies,
} from "../../../store/reducers/policy/policy-selectors";
import {
  addPoliciesToGroup,
  removePoliciesFromGroup,
  requestPolicies,
} from "../../../store/reducers/policy/policy-reducer";
import "./ModalPolicy.scss";

const ModalConfigureGroupPolicy: FC<IProps> = ({
  width,
  handleClose,
  isModalOpen,
  actualPolicies,
}) => {
  const dispatch = useAppDispatch();
  const [checkedItems, setCheckedItems] = useState<GroupSelectedItemsType[]>(
    []
  );
  const {
    policies: { policies },
  } = useAppSelector(getPolicies);
  const isPageLoading = useAppSelector(getIsLoading);
  const existingGroupPolicies = policies?.filter(
    (policy: any) =>
      policy.user_pool_id === actualPolicies.user_pool_id &&
      policy.id !== actualPolicies.id
  );

  const currentCheckedPolicies = existingGroupPolicies?.filter(
    (existingPolicy: any) => {
      return actualPolicies.policy_ids.find(
        (actualPolicy) => actualPolicy === existingPolicy.id
      );
    }
  );

  useEffect(() => {
    dispatch(requestPolicies({ per_page: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    setCheckedItems((): any => {
      return currentCheckedPolicies?.map((policy: any) => {
        const { id, title, user_pool_id, type } = policy;
        return { id, title, user_pool_id, type };
      });
    });
  }, [actualPolicies, policies]);

  const handleCheckboxChange = (body: GroupSelectedItemsType) => {
    const { id, title, user_pool_id, type } = body;

    // обновить общий массив всех полиси
    if (checkedItems.some((item: GroupSelectedItemsType) => item.id === id)) {
      setCheckedItems(
        checkedItems.filter((item: GroupSelectedItemsType) => item.id !== id)
      );
    } else {
      setCheckedItems([...checkedItems, { id, title, user_pool_id, type }]);
    }
  };

  const findAddedAndDeletedItems = (
    oldArray: any,
    newArray: GroupSelectedItemsType[]
  ) => {
    const addedItems = newArray.filter(
      (newItem: any) =>
        !oldArray.some((oldItem: any) => oldItem.id === newItem.id)
    );
    const deletedItems = oldArray.filter(
      (oldItem: any) =>
        !newArray.some((newItem: any) => newItem.id === oldItem.id)
    );
    return { addedItems, deletedItems };
  };

  const handleSubmit = () => {
    const { addedItems, deletedItems } = findAddedAndDeletedItems(
      currentCheckedPolicies,
      checkedItems
    );

    addedItems.length > 0 &&
      dispatch(
        addPoliciesToGroup({
          id: actualPolicies.id,
          body: {
            policy_ids: addedItems.map(
              (policy: GroupSelectedItemsType) => policy.id
            ),
          },
        })
      );

    deletedItems.length > 0 &&
      dispatch(
        removePoliciesFromGroup({
          id: actualPolicies.id,
          body: {
            policy_ids: deletedItems.map(
              (policy: GroupSelectedItemsType) => policy.id
            ),
          },
        })
      );

    handleClose();
  };

  return (
    <ModalContainer isOpen={isModalOpen} handleClose={handleClose}>
      <div
        className="modal-policy modal-policy__content modal-policy__content_type_group"
        style={{ width }}
      >
        {!isPageLoading ? (
          <>
            <h4 className="modal-policy__modal-title">Выберете policy</h4>
            <div className="modal-policy__group-title-container">
              <h6 className="modal-policy__table-title">Название</h6>
              <h6 className="modal-policy__table-title">Тип</h6>
            </div>
            <div className="modal-policy__field-container">
              {existingGroupPolicies && existingGroupPolicies.length > 0 ? (
                existingGroupPolicies.map((policy: any) => {
                  const { id, title, user_pool_id, type } = policy;

                  return (
                    <div className="modal-policy__group-item-container">
                      <label
                        htmlFor={`checkbox-${id}-${title}`}
                        className="modal-policy__label modal-policy__label_type_group"
                        key={id}
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${id}-${title}`}
                          value={id}
                          onChange={() =>
                            handleCheckboxChange({
                              id,
                              user_pool_id,
                              title,
                              type,
                            } as GroupSelectedItemsType)
                          }
                          checked={checkedItems.some(
                            (selectedItem: any) => selectedItem.id === id
                          )}
                        />
                        <span className="modal-policy__radiomark">{}</span>
                        {title}
                      </label>
                      <p className="modal-policy__policy-type">{type}</p>
                    </div>
                  );
                })
              ) : (
                <h6
                  className="modal-policy__waiting-title"
                  style={{ width: "100%" }}
                >
                  Нет доступных policy
                </h6>
              )}
            </div>
            <div className="modal-policy__modal-button-container">
              <Button
                onClick={handleClose}
                isDisabled={false}
                label="Отмена"
                color="light-blue"
                width="fit-content"
              />

              <ModalButton
                handleClick={handleSubmit}
                label="Сохранить"
                marginTop="0.625rem"
                alignSelf="end"
              />
            </div>
          </>
        ) : (
          <h1 className="modal-policy__waiting-title">Загрузка данных...</h1>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalConfigureGroupPolicy;

interface IProps {
  width: string | number;
  isModalOpen: boolean;
  handleClose: () => void;
  actualPolicies: IPoliciesGroupRequest;
}
