import { FC, useState } from "react";

import { useAppDispatch } from "../../../store/store";

import InputPassword from "../../../components/Inputs/InputPassword/InputPassword";
import InputText from "../../../components/Inputs/InputText/InputText";
import Button from "../../../components/Inputs/Button/Button";

import { signInOwner } from "../../../store/reducers/auth/auth-reducer";

import "./SignInForm.scss";

const SignInForm: FC = () => {
  const [name, setName] = useState<string>("");
  const [pass, setPass] = useState<string>("");

  const dispatch = useAppDispatch();

  const isDisabled = !name || !pass;

  const handleSignIn = () => {
    dispatch(
      signInOwner({
        username: name,
        password: pass,
      }),
    );
  };

  /*todo валидация*/
  return (
    <div className="sign-in-form">
      <h2>Вход</h2>
      {/* <form noValidate autoComplete="off"> */}
      <InputText
        value={name}
        onChange={setName}
        label="Имя пользователя"
        placeholder="Введите имя пользователя"
        errorMsg="Неверное имя пользователя"
      />
      <InputPassword
        value={pass}
        onChange={setPass}
        label="Пароль"
        placeholder="Введите пароль"
        errorMsg="Введите пароль"
      />
      <Button
        size="big"
        label="Войти"
        onClick={handleSignIn}
        isDisabled={isDisabled}
      />
      {/* </form> */}
    </div>
  );
};

export default SignInForm;
