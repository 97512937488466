import { FC } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import PageTableField from "../PageTableField/PageTableField";
import TablePagination from "../TablePagination/TablePagination";

import {
  IUserPool,
  IUser,
  IPaginationFilters as IPaginationPoolsFilters,
} from "../../store/reducers/pools/pools-types";
import {
  IHttpPolicy,
  IOpenapiPolicyRequest,
  IPoliciesGroupRequest,
} from "../../store/reducers/policy/policy-types";
import { IPaginationFilters as IPaginationPolicyFilters } from "../../store/reducers/policy/policy-types";

import {
  RowStatus,
  TypePageTable,
  pageTableContent,
} from "../../constants/constants";

import "./PageTable.scss";

const PageTable: FC<
  IProps<
    | IUser[]
    | IUserPool[]
    | (IOpenapiPolicyRequest | IHttpPolicy | IPoliciesGroupRequest)[]
    | null
  >
> = ({
  dataTable,
  pagination,
  type,
  isLoading = false,
  handleInfo,
  handleEdit,
  handleApiKey,
  handleUpdateOpenApiFromUrlOrSpec,
  handleStatus,
  handleConfig,
  handleGroupConfig,
  handlePaginationChangePage,
  isFirstFilterActive = false,
  isSecondFilterActive = false,
  policyPagination,
}) => {
  const location = useLocation();

  return (
    <div className="page-table">
      {pageTableContent ? (
        <>
          <h2 className="page-table__title">
            {`Ваши ${pageTableContent[type]["title"]}`}
          </h2>
          <div className="page-table__main-container">
            <ul
              className="page-table__header"
              style={{
                gridTemplateColumns:
                  type === "policy"
                    ? "510px 250px 1fr 140px"
                    : type === "users"
                    ? "2fr 1.5fr 0.7fr"
                    : "2fr 2.3fr 0.6fr",
              }}
            >
              <li>{pageTableContent[type]["header-title"][0]}</li>
              <li>{pageTableContent[type]["header-title"][1]}</li>
              <li>{pageTableContent[type]["header-title"][2]}</li>
              {pageTableContent[type]["header-title"][3] ? (
                <li>{pageTableContent[type]["header-title"][3]}</li>
              ) : null}
            </ul>

            <div
              className={`page-table__row ${cn("containerHeight", {
                isHigher: location.pathname !== "/users",
                isFirstFilterActive: isFirstFilterActive,
                isSecondFilterActive: isSecondFilterActive,
              })}`}
            >
              <div className="page-table__child-container">
                {isLoading ? (
                  <h1 className="page-table__loading">Загрузка данных...</h1>
                ) : (
                  dataTable &&
                  dataTable.map((tableRow) => {
                    return (
                      <PageTableField
                        key={tableRow.id}
                        type={type}
                        tableRow={tableRow}
                        isClickable={true}
                        handleStatus={handleStatus}
                        handleInfo={handleInfo}
                        handleEdit={handleEdit}
                        handleApiKey={handleApiKey}
                        handleConfig={handleConfig}
                        handleGroupConfig={handleGroupConfig}
                        handleUpdateOpenApiFromUrlOrSpec={
                          handleUpdateOpenApiFromUrlOrSpec
                        }
                      />
                    );
                  })
                )}
              </div>

              {pagination && <TablePagination pagination={policyPagination} />}
            </div>
          </div>
        </>
      ) : (
        <h2 className="page-table__title">There is no content</h2>
      )}
    </div>
  );
};

export default PageTable;

interface IProps<T> {
  type: TypePageTable;
  isLoading?: boolean;
  dataTable: T;
  pagination?: IPaginationPoolsFilters | IPaginationPolicyFilters;
  handleInfo?: (id: string) => void;
  handleEdit: (id: string) => void;
  handleApiKey?: (tableRow: IUserPool) => void;
  handleUpdateOpenApiFromUrlOrSpec?: (tableRow: IOpenapiPolicyRequest) => void;
  handleConfig?: (id: string) => void;
  handleGroupConfig?: (id: string) => void;
  handleStatus?: (row: RowStatus) => void;
  handlePaginationChangePage?: (perPage: number, currentPage: number) => void;
  isFirstFilterActive?: boolean;
  isSecondFilterActive?: boolean;
  policyPagination?: any;
}
