import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Inputs/Button/Button";
import Logo from "../../../components/Logo/Logo";

import "./SignUpGreetings.scss";

const SignUpGreetings: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate("/signin");

  return (
    <div className="sign-up-greetings">
      <Logo />
      <div className="greetings">Добро пожаловать!</div>
      <div className="info">
        Мы обеспечим единый доступ ко всем вашим продуктам, объединяя
        пользователей в одном месте.
      </div>
      <div className="noaccaunt">Уже есть аккаунт?</div>
      <Button size="big" label="Войти" color="white" onClick={handleNavigate} />
    </div>
  );
};

export default SignUpGreetings;
