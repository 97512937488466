import { FC } from "react";

import SignInGreetings from "./SignInGreetings/SignInGreetings";
import SignInForm from "./SignInForm/SignInForm";

import "./SignInPage.scss";

const SignInPage: FC = () => {
  return (
    <div className="sign-in-page">
      <SignInGreetings />
      <SignInForm />
    </div>
  );
};

export default SignInPage;
