import { FC, useEffect, useState } from "react";
import "./ModalUserPoolsContent.scss";
import { inputModalContent } from "../../../../constants/constants";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";
import Button from "../../../Inputs/Button/Button";
import { useAppSelector } from "../../../../store/store";
import {
  getCurrenUserPoolId,
  getPools,
} from "../../../../store/reducers/pools/pools-selectors";
import { useLocation } from "react-router-dom";
import { getIsLoading } from "../../../../store/reducers/policy/policy-selectors";

const ModalUserPoolsInputs: FC<Props> = ({
  width = "30.125rem",
  modalType,
  handleSubmit,
  handleClose,
  title,
}) => {
  const location = useLocation();
  const currenUserPoolId = useAppSelector(getCurrenUserPoolId);
  const { pools } = useAppSelector(getPools);
  const isLoading = useAppSelector(getIsLoading);

  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setIsInputValid] = useState(true);

  useEffect(() => {
    if (title) {
      setInputValue(title);
    }

    if (
      currenUserPoolId &&
      pools &&
      location.pathname.includes("pools") &&
      modalType === "editUserPool"
    ) {
      setInputValue(() => {
        const actualPool = pools.find(
          (pool) => pool && pool.id === currenUserPoolId
        );
        return actualPool ? actualPool.title : "";
      });
    }
  }, [title, currenUserPoolId, location, pools, modalType]);

  const handleButtonSubmit = () => {
    const isInputValid = !!inputValue;
    setIsInputValid(isInputValid);

    if (isInputValid) handleSubmit(inputValue, modalType);
  };

  return (
    <div className="modal-with-inputs" style={{ width }}>
      <h4 className="modal-with-inputs__modal-title">
        {inputModalContent[modalType]["title"]}
      </h4>

      {isLoading ? (
        <h1 className="modal-with-inputs__loading">Загрузка данных...</h1>
      ) : (
        <>
          <ModalInput
            placeholder={inputModalContent[modalType]["placeholder"]}
            value={inputValue}
            onChange={setInputValue}
            isInputValid={isInputValid}
          />
          <div className="modal-with-inputs__modal-button-container">
            {handleClose && (
              <Button
                onClick={handleClose}
                label="Отмена"
                isDisabled={false}
                color="light-blue"
              />
            )}

            <Button
              onClick={handleButtonSubmit}
              label="Сохранить"
              color="blue"
              isDisabled={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ModalUserPoolsInputs;

interface Props {
  handleSubmit: (name: string, modalType: string) => void;
  handleClose?: () => void;
  modalType:
    | "create"
    | "details"
    | "editUserPool"
    | "newPolicy"
    | "apiPolicyWithFile"
    | "apiModalWithSwagger"
    | "editHttpPolicy"
    | "editOpenapiPolicy"
    | "editGroupPolicy";
  width?: string | number;
  title?: string;
}
