import React, { ChangeEvent, FC } from "react";
import "./ModalInput.scss";
import Icon from "../../Icon/Icon";
import addIcon from "../../../assets/media/icons/add-icon.svg";
import cn from "classnames";

const ModalInput: FC<IProps> = ({
  index = null,
  value,
  placeholder,
  onChange,
  handleFileChange,
  type = "text",
  inputName = null,
  isInputValid = true,
  label,
}) => {
  const errorMessage = (
    <div className="modalInput__error-container">
      <span
        className={`modalInput__error-message ${cn("input-valid-message", [
          isInputValid.toString(),
        ])}`}
      >
        Это поле обязательно для заполнения
      </span>
    </div>
  );

  return type === "file" ? (
    <>
      <input
        type="file"
        id="fileInput"
        accept=".json"
        onChange={handleFileChange}
      />
      <label
        htmlFor="fileInput"
        className={`modalInput modalInput_type_file ${cn("input-valid", [
          isInputValid.toString(),
        ])}`}
      >
        {placeholder}
        <Icon icon={addIcon} width={1} height={1.375} />
      </label>
      {errorMessage}
    </>
  ) : (
    <div className="modalInput__input-container">
      <label htmlFor={`placeholder-${index}`} className="modalInput__label">
        {label}
      </label>
      <input
        id={`placeholder-${index}`}
        className={`modalInput ${cn(
          "input-valid",
          { isLabelExist: Boolean(label) },
          [isInputValid.toString()],
        )}`}
        type={type}
        value={value as string}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value, inputName)}
      />
      {errorMessage}
    </div>
  );
};

export default ModalInput;

interface IProps {
  index?: number;
  value?: string;
  label?: string;
  placeholder: string;
  onChange: any;
  handleFileChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  inputName?: string | null;
  isInputValid?: boolean;
}
