import React, { FC, ReactNode } from "react";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import cn from "classnames";

import { IConstantFilter } from "../../constants/constants";

import "./DropDown.scss";

type Props = {
  element: ReactNode;
  items: IConstantFilter[];
  onSelect?: (item: any) => void;
};

export const DropDown: FC<Props> = ({ element, items, onSelect }) => {
  const onClickMenuItem = (item: IConstantFilter) => {
    onSelect?.(item);
  };

  return (
    <Dropdown>
      <MenuButton className={cn("menu-button", { disabled: items.length < 1 })}>
        {element}
      </MenuButton>
      {items.length > 0 && (
        <Menu className="menu-dropdown">
          {items.map((item: IConstantFilter) => {
            return (
              <React.Fragment key={item.id}>
                <MenuItem
                  key={item.id}
                  className="dropdown-item"
                  onClick={() => onClickMenuItem(item)}
                >
                  {!!item.label.length ? item.label : item.id}
                </MenuItem>
              </React.Fragment>
            );
          })}
        </Menu>
      )}
    </Dropdown>
  );
};
