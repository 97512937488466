export const readFileAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        const result = event.target.result;
        if (typeof result === "string") {
          resolve(result);
        } else {
          reject(new Error("Не удалось прочитать файл"));
        }
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};
