import { FC, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/store";

import Icon from "../../../components/Icon/Icon";
import StatusLabel from "../../../components/PageTableField/StatusLabel";
import Tooltip from "../../../components/Tooltip/Tooltip";
import ModalUserPools from "../ModalUserPools/ModalUserPools";

import {
  clearCreatedPool,
  refreshUserPoolApiKey,
  requestPoolById,
  setCurrenUserPoolId,
  setPoolById,
  setRefreshedApiKey,
  updatePool,
  updatePoolStatus,
} from "../../../store/reducers/pools/pools-reducer";
import { getCurrenUserPoolId } from "../../../store/reducers/pools/pools-selectors";

import {
  IRefreshedApiKeyRequest,
  IUserPool,
} from "../../../store/reducers/pools/pools-types";

import infoIcon from "../../../assets/media/icons/info.svg";
import apiKey from "../../../assets/media/icons/key-icon.svg";
import editIcon from "../../../assets/media/icons/edit.svg";

import "./UserPoolsStatusCell.scss";
import { RowStatus } from "../../../constants/constants";

export type ModalUserPoolsState = {
  isOpen: boolean;
  info: boolean;
  title: boolean;
  detail: boolean;
  apiKey: boolean;
};

const initialState = {
  isOpen: false,
  info: false,
  title: false,
  detail: false,
  apiKey: false,
};

const UserPoolsStatusCell: FC<IProps> = ({ rowData }) => {
  const isClickable = true;

  const dispatch = useAppDispatch();
  const currenUserPoolId = useAppSelector(getCurrenUserPoolId);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalUserPoolsState>(initialState);
  const [currentPoolStatus, setCurrentPoolStatus] = useState({
    id: "",
    status: "",
  });

  const onModalClose = () => {
    dispatch(clearCreatedPool());
    dispatch(setRefreshedApiKey({ refreshedApiKey: null }));
    setModalType(initialState);
  };

  const onModalInputSubmit = (input: string, modalType: string) => {
    if (modalType === "editUserPool" && currenUserPoolId) {
      dispatch(
        updatePool({
          id: currenUserPoolId,
          body: {
            title: input.trim(),
            access_lifetime_min: 10,
            refresh_lifetime_min: 60,
          },
        })
      );
      setModalType(initialState);
    } else if (modalType === "details" && currentPoolStatus.id) {
      dispatch(
        updatePoolStatus({
          id: currentPoolStatus.id,
          body: {
            new_status: currentPoolStatus.status,
            details: input,
          },
        })
      );
      dispatch(setCurrenUserPoolId({ currenUserPoolId: "" }));
      setCurrentPoolStatus({
        id: "",
        status: "",
      });
      setModalType(initialState);
    }
  };

  const onModalApiKeySubmit = (apiKeyValues: IRefreshedApiKeyRequest) => {
    "user_pool_uuid" in apiKeyValues &&
      "api_key" in apiKeyValues &&
      dispatch(refreshUserPoolApiKey(apiKeyValues));
  };

  const openEditPoolModal = (id: string) => () => {
    dispatch(setCurrenUserPoolId({ currenUserPoolId: id }));
    setModalType(() => ({
      ...initialState,
      isOpen: true,
      title: true,
    }));
  };

  const showPoolInfo = (id: string) => () => {
    dispatch(requestPoolById({ id }));
    setModalType(() => ({
      ...initialState,
      isOpen: true,
      info: true,
    }));
  };

  const editPoolStatus = (status: RowStatus) => {
    if (status.PoolId) {
      setCurrentPoolStatus({
        id: status.PoolId,
        status: status.status,
      });
      setModalType(() => ({
        ...initialState,
        isOpen: true,
        detail: true,
      }));
    }
  };

  const handleNewStatus = (statusInput: string) => {
    editPoolStatus?.({
      status: statusInput,
      PoolId: rowData.id,
    });
  };

  const configurateApiKey = (poolById: IUserPool) => () => {
    dispatch(setPoolById({ poolById }));
    setModalType(() => ({
      ...initialState,
      isOpen: true,
      apiKey: true,
    }));
  };

  return (
    <div className="pools-status-cell">
      <button
        className="page-table-field__button"
        onClick={showPoolInfo(rowData.id)}
      >
        <Icon icon={infoIcon} width={1.25} height={1.25} />
      </button>

      <button
        className="page-table-field__button"
        onClick={openEditPoolModal(rowData.id)}
      >
        <Icon icon={editIcon} width={1.25} height={1.25} />
      </button>

      <button
        className="page-table-field__button"
        onClick={configurateApiKey(rowData as IUserPool)}
      >
        <Icon icon={apiKey} width={1.25} height={1.25} />
      </button>

      <div className="status-label-container">
        <StatusLabel
          status={rowData.status as "" | "ACTIVE" | "INACTIVE" | "BLOCKED"}
          isClickable={isClickable}
          onClick={() => setIsStatusModalOpen(true)}
        />

        <Tooltip
          open={isStatusModalOpen}
          id={rowData.id}
          status={rowData.status as "" | "ACTIVE" | "INACTIVE" | "BLOCKED"}
          handleClose={() => setIsStatusModalOpen(false)}
          handleSave={handleNewStatus}
        />
      </div>
      <ModalUserPools
        modal={modalType}
        onModalClose={onModalClose}
        onModalInputSubmit={onModalInputSubmit}
        onModalApiKeySubmit={onModalApiKeySubmit}
      />
    </div>
  );
};

interface IProps {
  rowData: any;
}

export default UserPoolsStatusCell;
