import React, { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import cn from "classnames";

import BindCheckbox from "../BindCheckbox/BindCheckbox";
import Popup from "../../../components/Popup/Popup";
import TriangleCell from "./TriangleCell/TriangleCell";

import {
  clearPolicyBySwagger,
  requestPolicies,
} from "../../../store/reducers/policy/policy-reducer";
import { users } from "../../../store/reducers/users/users-selectors";
import { getPolicies } from "../../../store/reducers/policy/policy-selectors";
import { requestUsersByPool } from "../../../store/reducers/users/users-reducer";

import "./BindingTable.scss";

const BindingTable: FC<IProps> = ({ selectedPool, selectedUser }) => {
  const [policiesByPool, setPoliciesByPool] = useState<any>([]);

  const dispatch = useAppDispatch();

  const usersByPool = useAppSelector(users);
  const {
    policies: { policies },
  } = useAppSelector(getPolicies);

  const isTableData = usersByPool?.users && usersByPool?.users.length > 0;
  const usersList = selectedUser ? [selectedUser] : usersByPool?.users;

  /*
  Если дропдауне выбираем Пулл, запрашиваем юзеров
    Если будет эндпоинт на получение полиси по пуллам, то его тоже сюда
  */
  useEffect(() => {
    if (selectedPool) {
      dispatch(
        requestUsersByPool({ user_pools_ids: [selectedPool] as string[] })
      );
    }
  }, [dispatch, selectedPool]);

  /*
    т.к. нет эндпоинта для полиси по пуллу, просим все
  */
  useEffect(() => {
    dispatch(requestPolicies({}));
  }, [dispatch]);

  /*
    фильтруем полиси по пуллу, 
    как только попросили все полиси
  */
  useEffect(() => {
    setPoliciesByPool(
      policies?.filter(
        (policy: any) => policy.user_pool_id === selectedPool?.id
      )
    );
  }, [policies, selectedPool]);

  useEffect(
    () => () => {
      dispatch(clearPolicyBySwagger());
    },
    [dispatch]
  );

  return (
    <div>
      <div
        className={cn("binding-table-pool-info", { "table-data": isTableData })}
      >
        <div className="pool-info-row">
          <h3>Пользователей в пуле:</h3>
          <h2>{usersByPool ? usersByPool?.users?.length : 0}</h2>
        </div>
        <div className="pool-info-row">
          <h3>Количество Policy:</h3>
          <h2>{policiesByPool?.length}</h2>
        </div>
      </div>
      <div className="binding-table-scroll-container">
        <div className="binding-table-wrapper">
          {isTableData && (
            <div
              className="binding-table"
              style={{
                gridTemplateColumns: `17.5rem repeat(${policiesByPool?.length}, 8.25rem)`,
                gridTemplateRows: `4.5rem repeat(${usersList.length}, 5.625rem)`,
              }}
            >
              <TriangleCell />
              {/* Колонки Policy */}
              {policiesByPool?.map((policy: any, index: any) => (
                <div
                  key={policy.id}
                  className="binding-table__cell title-cell column-cell"
                  style={{
                    gridColumn: `${index + 2}`,
                    borderTopRightRadius:
                      index + 1 === policiesByPool.length ? "4px" : "",
                    borderRightWidth: "3px",
                  }}
                >
                  <span>{policy.title}</span>
                  {policy.title.length > 5 && <Popup text={policy.title} />}
                </div>
              ))}

              {usersList.map((user: any, index: any) => (
                <>
                  {/* Строки Пользователи */}
                  <div
                    key={user.id}
                    className="binding-table__cell title-cell row-cell"
                    style={{
                      gridRow: `${index + 2}`,
                      borderBottomLeftRadius:
                        index + 1 === usersByPool?.users.length ? "4px" : "",
                      borderBottomWidth: "3px",
                    }}
                  >
                    {user.username}
                  </div>

                  {/* Ячейки */}
                  {policiesByPool?.map((policy: any) => (
                    <div className="binding-table__cell" key={policy?.id}>
                      <BindCheckbox
                        isActive={user?.policy_ids?.includes(policy.id)}
                        user={user}
                        policy={policy}
                        selectedPool={selectedPool}
                      />
                    </div>
                  ))}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BindingTable;

interface IProps {
  selectedPool: any;
  selectedUser: any;
}
