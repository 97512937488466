import instance from "./api";

import {
  IPolicy,
  IOpenapiPolicyRequest,
  IPoliciesGroupRequest,
  IPoliciesGroupPatch,
  IPoliciesBindPost,
  IHttpPolicy,
  IHttpPolicyCreate,
  IOpenapiPolicyCreate,
  IPolicyRequestBody,
  IOpenapiPolicyPatch,
  IParsedOpenapiPolicyFromUrl,
  IPoliciesGroupStatusPatch,
  IPoliciesGroupAddAndRemove,
} from "../store/reducers/policy/policy-types";
import { getAccessToken } from "../store/functions";

const policyApi = {
  async getAllPolicies({
    current_page,
    per_page,
    title,
    policy_types,
  }: IPolicyRequestBody) {
    const queryParams: { [key: string]: string | number } = {};
    const policyTypes = policy_types
      ?.map((policy_type: any) => `${policy_type.filterValue.toUpperCase()}`)
      .join(",");

    current_page && (queryParams.current_page = current_page);
    per_page && (queryParams.per_page = per_page);
    title && (queryParams.title = title);
    policyTypes && (queryParams.policy_types = policyTypes);

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return await instance.get<IPolicy>(
      `/policies/?${queryString}`,

      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },
  async getPolicyById({ id }: { id: string }) {
    return await instance.get<
      IOpenapiPolicyRequest | IHttpPolicy | IPoliciesGroupRequest | null
    >(`/policies/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async postHttpPolicy(body: IHttpPolicyCreate) {
    return await instance.post<[]>("/policies/http", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async patchHttpPolicy({ id, body }: { id: string; body: IHttpPolicyCreate }) {
    return await instance.patch<[]>(`/policies/http/${id}`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo Решили, что пока будет просто строка со спеками. 
  Тут на деле уже нужно понять в каком виде отправлять строку. 
  Пока инфы нет.*/
  async postOpenapiSpec(body: {}) {
    return await instance.post<{}>("/policies/openapi/parse/spec", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /* todo уточнить в каком виде передаётся урл 
  (в сваггере квери)*/
  async postOpenapiUrlSpec({ url }: { url: string }) {
    return await instance.post<IParsedOpenapiPolicyFromUrl>(
      "/policies/openapi/parse/url",
      { openapi_spec_url: url },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  /*todo type!!*/
  async postOpenapiPolicy(body: IOpenapiPolicyCreate) {
    return await instance.post<[]>("/policies/openapi", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  // async patchOpenapiPolicy({
  //   id,
  //   body,
  // }: {
  //   id: string;
  //   body: IOpenapiPolicyCreate;
  // }) {
  //   return await instance.patch<[]>(`/policies/openapi/${id}/simple`, body, {
  //     headers: {
  //       Authorization: `Bearer ${getAccessToken()}`,
  //     },
  //   });
  // },

  async patchOpenapiPolicyFields({
    id,
    body,
  }: {
    id: string;
    body?: IOpenapiPolicyPatch;
  }) {
    return await instance.patch<IOpenapiPolicyPatch>(
      `/policies/openapi/${id}/fields`,
      body,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  /* Update Openapi Policy By Spec (.json file) */
  async patchOpenapiPolicyBySpec({
    id,
    body,
  }: {
    id: string;
    body: IOpenapiPolicyPatch;
  }) {
    return await instance.patch<IOpenapiPolicyPatch>(
      `/policies/openapi/${id}/routes`,
      body,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  /* Update Openapi Policy By URL (with swagger) */
  async patchOpenapiPolicyByUrl({ id }: { id: string }) {
    return await instance.patch<IOpenapiPolicyPatch>(
      `/policies/openapi/${id}/url`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  /*todo type!!*/
  async postPoliciesGroup(body: { title: string; user_pool_id: string }) {
    return await instance.post<IPoliciesGroupRequest>("/policies/group", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async patchPoliciesGroup({
    id,
    body,
  }: {
    id: string;
    body: IPoliciesGroupPatch;
  }) {
    return await instance.patch<[]>(`/policies/group/${id}`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async patchAddPoliciesGroup({
    id,
    body,
  }: {
    id: string;
    body: IPoliciesGroupAddAndRemove;
  }) {
    return await instance.patch<[]>(`/policies/group/${id}/add`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async patchRemovePoliciesGroup({
    id,
    body,
  }: {
    id: string;
    body: IPoliciesGroupAddAndRemove;
  }) {
    return await instance.patch<[]>(`/policies/group/${id}/remove`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async patchStatusPolicies(body: IPoliciesGroupStatusPatch) {
    return await instance.patch<[]>("/policies/common/status", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async postBindPolicyToUser({
    id,
    body,
  }: {
    id: any
    body: IPoliciesBindPost;
  }) {
    return await instance.post<[]>(`/policies/bind/${id}`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo type!!*/
  async deletePolicyFromBindUserPool({
    id,
    body,
  }: {
    id: any;
    body: IPoliciesBindPost;
  }) {
    return await instance.delete<[]>(`/policies/unbind/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: body,
    });
  },
};
export default policyApi;
