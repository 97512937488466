import React, { ChangeEvent, FC, useState } from "react";

import ModalInput from "../../../Inputs/ModalInput/ModalInput";
import ModalButton from "./ModalButton";
import CheckboxField from "./CheckboxField";
import Autocomplete from "../../../Autocomplete/Autocomplete";
import Button from "../../../Inputs/Button/Button";

import { useAppSelector } from "../../../../store/store";
import { IUserPool } from "../../../../store/reducers/pools/pools-types";
import { getPools } from "../../../../store/reducers/pools/pools-selectors";
import { inputModalContent } from "../../../../constants/constants";

import "../ModalPolicy.scss";

const NewPolicyContent: FC<IProps> = ({
  width,
  handleNewPolicyClick,
  handleClose,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setIsInputValid] = useState<{
    newPolicy: boolean;
    userPoolName: boolean;
  }>({
    newPolicy: true,
    userPoolName: true,
  });
  const [radioInputValue, setRadioInputValue] = useState("");
  const [userPoolValueByDropdown, setUserPoolValueByDropdown] =
    useState<IUserPool | null>(null);
  const { pools: userPools } = useAppSelector(getPools);

  const handleRadioButtonClick = (evt: ChangeEvent<HTMLInputElement>) =>
    setRadioInputValue(evt.target.value);

  const handleNewPolicyButtonClick = () => {
    if (!handleNewPolicyClick) {
      return;
    }

    const isInputValid = {
      newPolicy: !!inputValue,
      userPoolName: !!userPoolValueByDropdown,
    };

    if (inputValue && userPoolValueByDropdown && radioInputValue) {
      handleNewPolicyClick(
        inputValue,
        userPoolValueByDropdown,
        radioInputValue
      );
    }
    setIsInputValid(isInputValid);
  };

  return (
    <div className="modal-policy modal-policy__with-input" style={{ width }}>
      {userPools ? (
        <>
          <div className="modal-policy__header-container">
            <h4 className="modal-policy__modal-title">
              {inputModalContent["newPolicy"]["title"]}
            </h4>
            <ModalInput
              placeholder={inputModalContent["newPolicy"]["placeholder"]}
              value={inputValue}
              onChange={setInputValue}
              isInputValid={isInputValid.newPolicy}
            />
          </div>

          <div className="modal-policy__header-container">
            <h4 className="modal-policy__modal-title">
              {inputModalContent["newPolicy"]["subtitle"]}
            </h4>
            <Autocomplete
              values={userPools}
              value={userPoolValueByDropdown}
              setValue={setUserPoolValueByDropdown}
              isInputValid={isInputValid.userPoolName}
            />
          </div>

          <CheckboxField
            modalType="newPolicy"
            radioInputValue={radioInputValue}
            handleRadioButtonClick={handleRadioButtonClick}
          />
          <div className="modal-policy__modal-button-container">
            {
              <Button
                onClick={handleClose}
                label="Отмена"
                isDisabled={false}
                color="light-blue"
              />
            }
            <ModalButton
              handleClick={handleNewPolicyButtonClick}
              label={inputModalContent["newPolicy"]["buttonLabel"]}
            />
          </div>
        </>
      ) : (
        <h1 className="modal-policy__waiting-title" style={{ width: "100%" }}>
          Загрузка данных...
        </h1>
      )}
    </div>
  );
};

export default NewPolicyContent;

interface IProps {
  width: string | number;
  handleNewPolicyClick?: (
    inputValue: string,
    userPoolValueByDropdown: IUserPool,
    radioInputValue: string
  ) => void;
  handleClose: () => void;
}
