import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/store";

import { Checkbox } from "@mui/material";

import Button from "../../Inputs/Button/Button";
import InputText from "../../Inputs/InputText/InputText";

import { getMe } from "../../../store/reducers/auth/auth-selectors";

import { leaveTicket } from "./functions";

import "./Tickets.scss";

const Tickets: FC<any> = ({ handleClose }) => {
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [telegram, setTelegram] = useState("");
  const [isAgree, setIsAgree] = useState(true);

  const userInfo = useAppSelector(getMe);

  const isDisabled = text.length < 1 || title.length < 1 || !isAgree;

  const handleFeedBack = () => {
    leaveTicket({
      id: userInfo.id,
      title,
      message: text,
      username: userInfo.username,
      contacts: {
        name,
        phone: tel,
        email,
        telegram,
      },
    });
    handleClose();
  };

  useEffect(() => {
    if (userInfo.first_name) {
      setName(userInfo.first_name);
    }
    if (userInfo.phone) {
      setTel(userInfo.phone);
    }
    if (userInfo.email) {
      setEmail(userInfo.email);
    }
  }, [userInfo]);

  useEffect(
    () => () => {
      setText("");
      setName("");
      setTel("");
      setEmail("");
      setTitle("");
      setTelegram("");
      setIsAgree(true);
    },
    []
  );

  return (
    <div className="feedback">
      <h2 className="feedback-title">Форма обратной связи</h2>
      <InputText label="Имя" value={name} onChange={setName} />
      <InputText label="Телефон" value={tel} onChange={setTel} />
      <InputText label="Почта" value={email} onChange={setEmail} />
      <InputText label="Telegram" value={telegram} onChange={setTelegram} />
      <InputText
        label="Заголовок обращения *"
        value={title}
        onChange={setTitle}
      />
      <textarea
        className="feedback-input"
        value={text}
        onChange={({ target: { value } }) => setText(value)}
        placeholder="Напишите ваше сообщение *"
        rows={5}
      />
      <div>
        <button
          className="feedback-checkbox"
          onClick={() => setIsAgree(!isAgree)}
        >
          <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
          <span>Даю свое согласие на публикацию моего отзыва</span>
        </button>
      </div>

      <div className="btn-wrapper">
        <Button
          isDisabled={isDisabled}
          label="Отправить"
          onClick={handleFeedBack}
        />
      </div>
    </div>
  );
};

export default Tickets;
