import React, { FC, useState } from "react";
import ModalButton from "./ModalButton";
import Button from "../../../Inputs/Button/Button";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";
import { apiPolicyWithSwaggerType } from "../../../../store/reducers/policy/policy-types";
import {
  ApiPolicyWithSwaggerKeys,
  IInput,
  inputModalContent,
} from "../../../../constants/constants";
import "../ModalPolicy.scss";

const swaggerInputsInitialState = {
  openapi_spec_url: "",
  login: "",
  password: "",
};

const OpenApiWithSwaggerContent: FC<IProps> = ({
  width,
  handleNewPolicyWithSwaggerSubmit,
  handleClose,
}) => {
  const [policyInputsWithSwagger, setPolicyInputsWithSwagger] =
    useState<apiPolicyWithSwaggerType>(swaggerInputsInitialState);
  const [isInputValid, setIsInputValid] = useState<{
    openapi_spec_url: boolean;
    login: boolean;
    password: boolean;
  }>({
    openapi_spec_url: true,
    login: true,
    password: true,
  });

  const handleValueApiWithSwaggerChange = (
    value: string,
    inputName: string,
  ) => {
    setPolicyInputsWithSwagger((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleButtonSubmit = () => {
    const isInputValid = {
      openapi_spec_url: !!policyInputsWithSwagger.openapi_spec_url,
      login: !!policyInputsWithSwagger.login,
      password: !!policyInputsWithSwagger.password,
    };
    setIsInputValid(isInputValid);

    if (isInputValid) handleNewPolicyWithSwaggerSubmit(policyInputsWithSwagger);
  };

  return (
    <div
      className="modal-policy modal-policy__with-input modal-policy__with-input_type_swagger-modal"
      style={{ width }}
    >
      <h4 className="modal-policy__modal-title">
        {inputModalContent["apiModalWithSwagger"]["title"]}
      </h4>

      {inputModalContent["apiModalWithSwagger"].inputs?.map(
        (input: IInput, index) => {
          return (
            <ModalInput
              index={index}
              label={input.label}
              key={input.placeholder}
              placeholder={input.placeholder}
              value={
                policyInputsWithSwagger[input.name as ApiPolicyWithSwaggerKeys]
              }
              onChange={handleValueApiWithSwaggerChange}
              inputName={input.name}
              type={input.type}
              isInputValid={
                isInputValid[input.name as ApiPolicyWithSwaggerKeys]
              }
            />
          );
        },
      )}

      <div className="modal-policy__modal-button-container modal-policy__modal-button-container_type_swagger-modal">
        <Button
          onClick={handleClose}
          label="Отмена"
          isDisabled={false}
          color="light-blue"
        />
        <ModalButton
          handleClick={handleButtonSubmit}
          label={inputModalContent["apiModalWithSwagger"]["buttonLabel"]}
          width="fit-content"
        />
      </div>
    </div>
  );
};

export default OpenApiWithSwaggerContent;

interface IProps {
  width: string | number;
  handleNewPolicyWithSwaggerSubmit: (arg: apiPolicyWithSwaggerType) => void;
  handleClose: () => void;
}
