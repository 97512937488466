import React, { FC, useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import "moment/locale/ru";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { IUserPool } from "../../../store/reducers/pools/pools-types";

import AutocompleteIntroduction from "../../../components/Autocomplete/Autocomplete";

import Icon from "../../../components/Icon/Icon";

import { requestPools } from "../../../store/reducers/pools/pools-reducer";
import { pools } from "../../../store/reducers/pools/pools-selectors";

import { _data, options, colors } from "./load-data";
import { LangContext } from "../../../App";
import close from "../../../assets/media/icons/close.svg";

import "./LoadTable.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LoadTable: FC = () => {
  const dispatch = useAppDispatch();

  const {
    lang: {
      dashboard: { poolsDropdown, load },
    },
  } = useContext(LangContext);

  const { pools: userPools, isLoading: isPoolsLoading } = useAppSelector(pools);
  const [datasets, setDatasets] = useState<{}[]>([]);
  const [selectedPool, setSelectedPool] = useState<IUserPool | null>(null);
  const [activePoolsList, setActivePoolsList] = useState<string[]>([
    "poolId1",
    "poolId2",
    "poolId3",
  ]);

  /* todo тут проблемка с цветами, т.к. цвета подвязаны на индекс,
то при изменении порядка эл-тов в массиве скачат цвета
*/
  useEffect(() => {
    setDatasets(
      activePoolsList.map((item, index) => ({
        label: item,
        data: _data[item as keyof typeof _data].map((item) => item.reqCount),
        radius: 0,
        borderColor: `rgb(${colors[index]})`,
        backgroundColor: `rgb(${colors[index]})`,
      }))
    );
  }, [activePoolsList]);

  useEffect(() => {
    /*
    Сразу просим Пуллы для дропдауна
  */
    dispatch(requestPools({ per_page: null }));
  }, [dispatch]);

  const data = {
    labels: _data.poolId1.map((item) => moment(item.timeStamp).format("LT")),
    backgroundColor: "#f2f4fb",
    datasets: datasets as any[],
  };

  /* todo тут можно попрбовать не фильтровать а заменять спалйсом элемент на пустой объект,
  чтобы не менялись индексы в массиве и корректно сохранялись цвета при изменении массива
*/
  const handleSetPool = (id: any) => () => {
    if (activePoolsList.includes(id)) {
      setActivePoolsList((state) => state.filter((item) => item !== id));
    } else setActivePoolsList((state) => state.concat(id));
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f4fb",
        borderRadius: "20px",
        padding: "30px 20px",
      }}
    >
      <p
        style={{
          color: "#5282FF",
          fontWeight: "500",
          fontSize: "20px",
          marginBottom: "16px",
        }}
      >
        {load}
      </p>
      <div style={{ display: "flex" }}>
        <div
          className="dropdown-wrapper"
          style={{ marginRight: "12px", width: "25%" }}
        >
          <AutocompleteIntroduction
            placeholder={poolsDropdown}
            values={userPools as IUserPool[]}
            value={selectedPool}
            setValue={setSelectedPool}
            isInputValid
          />
        </div>
        <div style={{ display: "flex" }}>
          {activePoolsList.map((item, index) => (
            <div
              className="selected"
              key={item}
              style={{
                backgroundColor: `rgba(${colors[index]}, 0.4)`,
                borderColor: `rgb(${colors[index]})`,
              }}
            >
              {/* 
              todo: сделать иконке возможность изменения цвета
             */}
              <Icon
                className="icon-close"
                icon={close}
                size={"0.9265"}
                onClick={handleSetPool(item)}
              />
              <p style={{ color: `rgb(${colors[index]})` }}>{item}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <button onClick={handleSetPool("poolId1")}>id1</button>
        <button onClick={handleSetPool("poolId2")}>id2</button>
        <button onClick={handleSetPool("poolId3")}>id3</button>
      </div>
      <div
        style={{
          width: "100%",
          height: "280px",
        }}
      >
        <Line options={options} data={data} />
      </div>
    </div>
  );
};

export default LoadTable;
