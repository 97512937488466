import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { BaseThunk, SliceActions } from "../../store";

import poolsApi from "../../../api/user-pool-api";

const initialState: any = {
  isLoading: false,
  usersByPool:{
    users: null, 
    pagination: {
      total_pages: 0, 
      total_items: 0,
      per_page: 1000,
      current_page: 1,
    }
  },
  filters: { 
    user_pools_ids : [],
    statuses: [],
    username:"",
    current_page: 1,
    per_page:1000,
  },
};

const poolsSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading;
    },

    setUsersByPool(
      state,
      action: PayloadAction<{ usersByPool:  any }>,
    ) {
      state.usersByPool = action.payload.usersByPool;
    },
    setFilters(state, action: PayloadAction<{ filters: any }>) {
      state.filters = {...state.filters, ...action.payload.filters};
    }
  },
});

const { reducer, actions } = poolsSlice;

export const {
  setIsLoading,
  setUsersByPool,
  setFilters,
} = actions;

/* Thunk */

/* Сброс стэйта */
export const clearUsersState = (): Thunk => (dispatch) => {
  dispatch(setUsersByPool({ usersByPool: null }));
  dispatch(setFilters( {filters: { 
    user_pools_ids: [],
    statuses: [],
    username:"",
    current_page: 1,
    per_page:1000,
  }}))
};

/* Юзеры пула по айди */
export const requestUsersByPool =
  (body: any): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.getUsersByPool(body);

    if (status === 200) {
      dispatch(setUsersByPool({ usersByPool: data }));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* Изменить статус юзера в пуле */
export const updateUserStatus =
  ({
    poolId,
    userId,
    body,
  }: {
    poolId: string;
    userId: string;
    body: any;
  }): Thunk =>
  async (dispatch, getState) => {
    const {
        users: {
          filters
        },
      } = getState()
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.patchUserStatus({
      poolId,
      userId,
      body,
    });

    if (status === 200) {
      dispatch(requestUsersByPool(filters));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

export default reducer;

export type UsersActions = SliceActions<typeof actions>;
type Thunk = BaseThunk<UsersActions, void>;
