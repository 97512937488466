import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { Action, ThunkAction } from "@reduxjs/toolkit";

import auth from "./reducers/auth/auth-reducer";
import pools from "./reducers/pools/pools-reducer";
import policy from "./reducers/policy/policy-reducer";
import users from "./reducers/users/users-reducer";

const reducers = {
  auth,
  pools,
  policy,
  users
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
});

export default store;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export type SliceActions<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T];
export type BaseThunk<A extends Action, R = Promise<void>> = ThunkAction<
  R,
  RootState,
  unknown,
  A
>;

// @ts-ignore
window.store = store;
