import instance from "./api";

import {
  IUserPool,
  IPoolRequestBody,
  IRequestPoolStatus,
  IPoolResponse,
  IUsersOfFewUserPools,
  IUserPoolsRequestBody,
  IUsersRequestBody,
  IRefreshedApiKeyRequest,
} from "../store/reducers/pools/pools-types";

import { getAccessToken } from "../store/functions";

const poolsApi = {
  async getUserPools({
    statuses = [],
    title = "",
    current_page = null,
    per_page = 10,
  }: IUserPoolsRequestBody) {
    const queryParams: { [key: string]: string | number } = {};
    const statusesString = statuses
      ?.filter((item:any) => item !== "")
      .map((status:any) => `${status.filter.toUpperCase()}`)
      .join(",");

    statusesString && (queryParams.statuses = statusesString);
    title && (queryParams.title = title);
    current_page && (queryParams.current_page = current_page);
    per_page && (queryParams.per_page = per_page);

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return await instance.get<IPoolResponse>(
      `/user_pools/?${queryString}`,

      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          accept: "application/json",
        },
      },
    );
  },

  async getUserPoolById({ id }: { id: string }) {
    return await instance.get<IUserPool>(`/user_pools/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  /*todo дописать тип юзеров*/
  async getUsersByPool({
    statuses = [],
    username = "",
    user_pools_ids,
    current_page = 1,
    per_page = 1000,
  }: IUsersRequestBody) {
    const queryParams: { [key: string]: string | number } = {};    
    
    const statusesString = statuses
    ?.filter((item:any) => item !== "")
    .map((status:any) => `${status.filter.toUpperCase()}`)
    .join(",");

    const userPoolsIdsString = user_pools_ids.map(item => item.id).join(",");
   
    statusesString && (queryParams.statuses = statusesString);
    username && (queryParams.username = username);
    userPoolsIdsString.length > 0 ?  (queryParams.user_pools_ids = userPoolsIdsString) : (queryParams.user_pools_ids = '');
    current_page && (queryParams.current_page = current_page);
    per_page && (queryParams.per_page = per_page);

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return await instance.get<IUsersOfFewUserPools>(
      `user_pools/users?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  /*todo дописать тип статусов*/
  async getPoolStatusesHistory({ id }: { id: string }) {
    return await instance.get<[]>(`/user_pools/${id}/statuses`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  async postPool(body: IPoolRequestBody) {
    return await instance.post("/user_pools/", body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  async patchPool({ id, body }: { id: string; body: IPoolRequestBody }) {
    return await instance.patch<[]>(`/user_pools/${id}`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  async patchPoolStatus({
    id,
    body,
  }: {
    id: string;
    body: IRequestPoolStatus;
  }) {
    return await instance.patch<[]>(`/user_pools/${id}/status`, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  },

  async patchUserStatus({
    poolId,
    userId,
    body,
  }: {
    poolId: string;
    userId: string;
    body: IRequestPoolStatus;
  }) {
    return await instance.patch<[]>(
      `/user_pools/${poolId}/users/${userId}/status`,
      body,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  async refreshUserPoolApiKey({
    user_pool_uuid,
    api_key,
  }: IRefreshedApiKeyRequest) {
    return await instance.post(
      `/user_pools/${user_pool_uuid}/refresh-key?api_key=${api_key}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  },

  async getPoolsWhithUsers() {
    return await instance.get('/user_pools/with-users',{ 
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      }})
  }
};
export default poolsApi;
