import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { BaseThunk, SliceActions } from "../../store";
import { toast } from 'react-toastify';

import poolsApi from "../../../api/user-pool-api";
import {UsersActions, setFilters as setUsersFilters} from '../users/users-reducer'

import {
  IPoolRequestBody,
  IUserPool,
  IPoolsState,
  IRequestPoolStatus,
  IUserPoolsRequestBody,
  IUsersRequestBody,
  IUsersOfFewUserPools,
  IRefreshedApiKey,
  IRefreshedApiKeyRequest,
} from "./pools-types";

import { mapPoolsToFilters } from "../../../constants/constants";

const initialState: IPoolsState = {
  isLoading: false,
  isLoadingPoolById: false,
  pools: {
    pools: null, 
    pagination: {
      total_pages: 0, 
      total_items: 0
    }
  },
  poolById: null,
  usersByPool: null,
  statusesHistory: [],
  createdPool: null,
  filters: { 
    statuses: [],
    title:"",
    current_page: 0,
    per_page:10,
  },
  poolsIdsForUsers: [],
  currenUserPoolId: "",
  refreshedApiKey: null,
};

const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading;
    },
    setIsLoadingPoolById(
      state,
      action: PayloadAction<{ isLoadingPoolById: boolean }>,
    ) {
      state.isLoadingPoolById = action.payload.isLoadingPoolById;
    },
    setPools(state, action: PayloadAction<{ pools: IUserPool[], pagination: any }>) {
      state.pools.pools = action.payload.pools;
      state.pools.pagination = action.payload.pagination;
    },
    setPoolById(state, action: PayloadAction<{ poolById: IUserPool | null }>) {
      state.poolById = action.payload.poolById;
    },
    setPoolsIdsForUsers(state, action: PayloadAction<{pools: any}>) {
      state.poolById = action.payload.pools;
    },
    /*todo дописать тип*/
    setUsersByPool(
      state,
      action: PayloadAction<{ usersByPool: IUsersOfFewUserPools | null }>,
    ) {
      state.usersByPool = action.payload.usersByPool;
    },
    /*todo дописать тип*/
    setStatusesHistory(state, action: PayloadAction<{ statusesHistory: [] }>) {
      state.statusesHistory = action.payload.statusesHistory;
    },
    setCreatedPool(
      state,
      action: PayloadAction<{ createdPool: IUserPool | null }>,
    ) {
      state.createdPool = action.payload.createdPool;
    },
    clearCreatedPool(state) {
      state.createdPool = null;
    },
    setFilters(state, action: PayloadAction<{ filters: IUserPoolsRequestBody }>) {
      state.filters = {...state.filters, ...action.payload.filters};
    },
    setCurrenUserPoolId(
      state,
      action: PayloadAction<{ currenUserPoolId: string }>,
    ) {
      state.currenUserPoolId = action.payload.currenUserPoolId;
    },
    setRefreshedApiKey(
      state,
      action: PayloadAction<{ refreshedApiKey: IRefreshedApiKey | null }>,
    ) {
      state.refreshedApiKey = action.payload.refreshedApiKey;
    },
  },
});

const { reducer, actions } = poolsSlice;

export const {
  setIsLoading,
  setPools,
  setPoolById,
  setUsersByPool,
  setStatusesHistory,
  setIsLoadingPoolById,
  setCreatedPool,
  clearCreatedPool,
  setFilters,
  setCurrenUserPoolId,
  setRefreshedApiKey,
  setPoolsIdsForUsers
} = actions;

/* Thunk */

/* Сброс стэйта */
export const clearPoolsState = (): Thunk => (dispatch) => {
  dispatch(setPools({ pools: [], pagination: { total_pages: 0, total_items: 0 } }));
  dispatch(setPoolById({ poolById: null }));
  dispatch(setUsersByPool({ usersByPool: null }));
  dispatch(setStatusesHistory({ statusesHistory: [] }));
  dispatch(clearCreatedPool());
  dispatch(setRefreshedApiKey({ refreshedApiKey: null }));
  dispatch(setFilters({filters:{ 
    statuses: [],
    title:"",
    current_page: 1,
    per_page:10,
  }}))
};

/* Все пулы */
export const requestPools =
  ({ statuses, title, current_page, per_page }: IUserPoolsRequestBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data: IPoolFromResponse } = await poolsApi.getUserPools({
      statuses,
      title,
      current_page,
      per_page,
    });

    if (status === 200) {
      const {page: {total_pages, total_items}} = IPoolFromResponse

      dispatch(setPools({ pools: IPoolFromResponse.user_pools, pagination: { total_pages, total_items } }));
      dispatch(setUsersFilters({filters: { user_pools_ids:  mapPoolsToFilters(IPoolFromResponse.user_pools)}}))
    }
    
    dispatch(setIsLoading({ isLoading: false }));
  };

/* Пул по айди */
export const requestPoolById =
  ({ id }: { id: string }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoadingPoolById({ isLoadingPoolById: true }));

    const { status, data } = await poolsApi.getUserPoolById({ id });

    if (status === 200) {
      dispatch(setPoolById({ poolById: data }));
    }

    dispatch(setIsLoadingPoolById({ isLoadingPoolById: false }));
  };

/* Юзеры пула по айди */
export const requestUsersByPool =
  (body: IUsersRequestBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.getUsersByPool(body);

    if (status === 200) {
      dispatch(setUsersByPool({ usersByPool: data }));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* Лог статусов пула по айди */
export const requestPoolStatusesHistory =
  ({ id }: { id: string }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.getPoolStatusesHistory({ id });

    if (status === 200) {
      dispatch(setStatusesHistory({ statusesHistory: data }));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* Новый пул */
export const createPool =
  (body: IPoolRequestBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.postPool(body);

    if (status >= 200 && status < 300) {
      /*todo дописать логику(что должно происходить когда пул создан?), нотификации*/

      dispatch(setCreatedPool({ createdPool: data }));
      dispatch(requestPools({}));
      // dispatch(requesPoolById({id: data.id}))
      toast('Пулл успешно создан')
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* Изменить пул */
export const updatePool =
  ({ id, body }: { id: string; body: IPoolRequestBody }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.patchPool({ id, body });

    if (status === 200) {
      dispatch(requestPools({}));
      toast('Пулл обновлён')
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/* Изменить статус пула */
export const updatePoolStatus =
  ({ id, body }: { id: string; body: IRequestPoolStatus }): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.patchPoolStatus({ id, body });

    if (status === 200) {
      dispatch(requestPools({}));
      toast('Статус пулла обновлён')
    }

    dispatch(setIsLoading({ isLoading: false }));
  };


export const getPoolsWhithUsers = (): Thunk => async (dispatch) => {
  dispatch(setIsLoading({ isLoading: true }));

  const { status, data: IPoolFromResponse } = await poolsApi.getPoolsWhithUsers();


  if (status === 200) {
    dispatch(setPools({ pools: IPoolFromResponse, pagination: {}}));
  }
  
  dispatch(setIsLoading({ isLoading: false }));
}

export const refreshUserPoolApiKey =
  (body: IRefreshedApiKeyRequest): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await poolsApi.refreshUserPoolApiKey(body);

    if (status === 200) {
      dispatch(
        setRefreshedApiKey({
          refreshedApiKey: {
            api_key: data.api_key,
            status,
          },
        }),
      );
      dispatch(requestPools({}));
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

export default reducer;

type Actions = SliceActions<typeof actions>;
type Thunk = BaseThunk<Actions | UsersActions, void>;
