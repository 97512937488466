export interface IUserInfo {
  phone?: string;
  username?: string;
  email?: string;
  first_name?: string;
  second_name?: string;
  last_name?: string;
  id?: string;
  status?: string;
  creation_timestamp?: number;
  users_count: number;
  pools_count: number;
  policies_count: number;
}
export interface ISignUpBody {
  phone: string;
  username: string;
  email: string;
  first_name: string;
  second_name: string;
  last_name: string;
  password: string;
}

export interface ISignInBody {
  username: string;
  password: string;
}

export interface IRegistration {
  name?: string;
  mail?: string;
  phone?: string;
}

export interface ISignInResponce {
  access_token: string;
  refresh_token: string;
}

export interface IAuthResponceError {
  message: {
    en: string;
    ru: string;
  };
}

export interface IAuthState {
  isAuth: false;
  isLoading: false;
  profile: IUserInfo;
}

export const defaultUserValue: Readonly<IUserInfo> = {
  phone: "",
  username: "",
  email: "",
  first_name: "",
  second_name: "",
  last_name: "",
  id: "",
  status: "",
  creation_timestamp: 0,
  users_count: 0,
  pools_count: 0,
  policies_count: 0,
};
