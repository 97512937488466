import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Inputs/Button/Button";

import logo from "../../../assets/images/logo.svg";

import "./LandingHeader.scss";

const LandingHeader: FC = () => {
  const navigate = useNavigate();
  return (
    <header className="landing-header">
      <img className="landing-header-image" src={logo} alt="logo" />
      <div className="landing-header-buttons">
        <Button
          onClick={() => navigate("/signup")}
          label="Зарегистрироваться"
          size="small"
          color="white"
          width="fit-content"
        />
        <Button
          onClick={() => navigate("/signin")}
          label="Войти"
          size="small"
          color="blue"
          width="fit-content"
        />
      </div>
    </header>
  );
};

export default LandingHeader;
