import { useSwiper } from "swiper/react";
import "./SwiperNavBtn.scss";

const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="container">
      <button className="container-btn" onClick={() => swiper.slidePrev()}>
        <div className="btn-arrow-left"></div>
      </button>
      <button className="container-btn" onClick={() => swiper.slideNext()}>
        <div className="btn-arrow-right"></div>
      </button>
    </div>
  );
};

export default SwiperNavButtons;
