import React, { ChangeEvent, FC, useState } from "react";
import cn from "classnames";

import ModalContainer from "../../ModalContainer";
import Button from "../../../Inputs/Button/Button";
import ModalButton from "./ModalButton";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";

import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  updateOpenapiPolicyBySpec,
  updateOpenapiPolicyByUrl,
} from "../../../../store/reducers/policy/policy-reducer";
import {
  getIsLoading,
  getIsOpenApiUpdateSuccess,
  getPolicyById,
} from "../../../../store/reducers/policy/policy-selectors";
import { UpdateApiPolicyWithFileKeys } from "../../../../constants/constants";
import { readFileAsync } from "../../../../utils/readFiles";

export type apiPolicyWithFileType = {
  [key in UpdateApiPolicyWithFileKeys]: string;
};

const UpdateOpenapiPolicyModal: FC<IProps> = ({
  width,
  isModalOpen,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const policyById: any = useAppSelector(getPolicyById);
  const isOpenApiUpdateSuccess = useAppSelector(getIsOpenApiUpdateSuccess);
  const isLoading = useAppSelector(getIsLoading);

  const [apiWithFile, setApiWithFile] = useState<apiPolicyWithFileType>({
    fileName: "",
    fileValue: "",
  });

  const isSpecUrlExist = policyById.swagger_setup?.openapi_spec_url;
  const inputJsonPlaceholder = apiWithFile.fileValue
    ? `${apiWithFile.fileName}  файл успешно загружен`
    : "Загрузите JSON файл";

  const isSubmitButtonActive = isSpecUrlExist
    ? isLoading
    : !apiWithFile.fileValue;

  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = evt.target.files?.[0];

    if (selectedFile && selectedFile.type === "application/json") {
      try {
        setApiWithFile((prev) => ({
          ...prev,
          fileName: selectedFile.name,
        }));
        const fileContents = await readFileAsync(selectedFile);

        setApiWithFile((prev) => ({
          ...prev,
          fileValue: fileContents,
        }));
      } catch (error) {
        console.error("Ошибка при чтении файла:", error);
      }
    } else {
      console.error("no files here");
    }
  };

  const handleSubmit = () => {
    if (isSpecUrlExist)
      dispatch(updateOpenapiPolicyByUrl({ id: policyById.id }));

    if (apiWithFile.fileValue)
      dispatch(
        updateOpenapiPolicyBySpec({
          id: policyById.id,
          body: {
            ...policyById,
            endpoints: JSON.parse(apiWithFile.fileValue),
          },
        }),
      );
  };

  const infoLabel = (
    <div className="modal-with-inputs__update-url-container">
      <p
        className={`modal-with-inputs__update-url ${cn("successful-updated", {
          isOpenApiUpdateSuccess,
        })}`}
      >
        Ваш OpenAPI policy успешно обновлен!
      </p>
      <p
        className={`modal-with-inputs__update-url ${cn("updating-message", {
          isLoading,
        })}`}
      >
        Обновление OpenAPI policy...
      </p>
    </div>
  );

  return (
    <ModalContainer isOpen={isModalOpen} handleClose={handleClose}>
      <div
        className="modal-policy modal-policy__content modal-policy__content_type_group"
        style={{ width }}
      >
        <h4 className="modal-policy__modal-title">Обновление OpenAPI policy</h4>

        {isSpecUrlExist ? (
          <>
            <h5 className="modal-policy__modal-second-title">Ваша ссылка:</h5>
            <p className="modal-policy__modal-subtitle">
              {policyById.swagger_setup?.openapi_spec_url}
            </p>
          </>
        ) : (
          <ModalInput
            placeholder={inputJsonPlaceholder}
            value={apiWithFile.fileValue}
            onChange={null}
            type="file"
            inputName="updateOpenApiWithFile"
            handleFileChange={handleFileChange}
          />
        )}

        {infoLabel}

        <div className="modal-policy__modal-button-container">
          <Button
            onClick={handleClose}
            isDisabled={isLoading}
            label={isOpenApiUpdateSuccess ? "Закрыть" : "Отмена"}
            color="light-blue"
            width="fit-content"
          />

          <div
            className={`${cn("successful-updated-button", {
              isOpenApiUpdateSuccess,
            })}`}
          >
            <ModalButton
              handleClick={handleSubmit}
              isDisabled={isSubmitButtonActive}
              label="Обновить"
              alignSelf="end"
            />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default UpdateOpenapiPolicyModal;

interface IProps {
  width: string | number;
  isModalOpen: boolean;
  handleClose: () => void;
}
