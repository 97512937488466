import { FC } from "react";

import "./Popup.scss";

interface IProps {
  text: string;
}

const Popup: FC<IProps> = ({ text }) => {
  return <div className="common-popup">{text}</div>;
};

export default Popup;
