import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/store";
import { Checkbox } from "@mui/material";

import FeedbackButton from "./FeedbackButton/FeedbackButton";
import Button from "../../Inputs/Button/Button";

import { getMe } from "../../../store/reducers/auth/auth-selectors";
import { leaveFeedback } from "./functions";

import feed1 from "../../../assets/media/icons/feed1.svg";
import feed2 from "../../../assets/media/icons/feed2.svg";
import feed3 from "../../../assets/media/icons/feed3.svg";
import feed4 from "../../../assets/media/icons/feed4.svg";
import feed5 from "../../../assets/media/icons/feed5.svg";

import "./Feeback.scss";

const Feeback: FC<any> = ({ handleClose }) => {
  const [text, setText] = useState("");
  const [isAgree, setIsAgree] = useState(true);
  const [grade, setGrade] = useState(5);

  const userInfo = useAppSelector(getMe);

  const handleFeedBack = () => {
    leaveFeedback({
      id: userInfo.id,
      text,
      grade,
      username: userInfo.username,
    });
    handleClose();
  };

  useEffect(
    () => () => {
      setGrade(5);
      setText("");
      setIsAgree(true);
    },
    []
  );

  return (
    <div className="feedback">
      <h2 className="feedback-title">Ваш отзыв важен для нас</h2>
      <textarea
        className="feedback-input"
        value={text}
        onChange={({ target: { value } }) => setText(value)}
        placeholder="Напишите ваш отзыв"
        rows={5}
      />
      <div>
        <button
          className="feedback-checkbox"
          onClick={() => setIsAgree(!isAgree)}
        >
          <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
          <span>Даю свое согласие на публикацию моего отзыва</span>
        </button>
      </div>
      <div className="feedback-btns">
        <FeedbackButton
          icon={feed1}
          isActive={grade === 5}
          onClick={() => setGrade(5)}
        />
        <FeedbackButton
          icon={feed2}
          isActive={grade === 4}
          onClick={() => setGrade(4)}
        />
        <FeedbackButton
          icon={feed3}
          isActive={grade === 3}
          onClick={() => setGrade(3)}
        />
        <FeedbackButton
          icon={feed4}
          isActive={grade === 2}
          onClick={() => setGrade(2)}
        />
        <FeedbackButton
          icon={feed5}
          isActive={grade === 1}
          onClick={() => setGrade(1)}
        />
      </div>
      <div className="btn-wrapper">
        <Button
          isDisabled={text.length < 1 || !isAgree}
          label="Отправить"
          onClick={handleFeedBack}
        />
      </div>
    </div>
  );
};

export default Feeback;
