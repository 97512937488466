import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SignInPage from "../../pages/SignInPage/SignInPage";
import SignUpPage from "../../pages/SignUpPage/SignUpPage";
import LandingPage from "../../pages/Landing/Landing";

const AuthRouter: FC = () => (
  <>
    <Routes>
      <Route index element={<Navigate to={"/landing"} />} />
      <Route path="landing" element={<LandingPage />} />
      <Route path="signin" element={<SignInPage />} />
      <Route path="signup" element={<SignUpPage />} />

      <Route path="*" element={<LandingPage />} />
    </Routes>
  </>
);

export default AuthRouter;
