import { NavLink } from "react-router-dom";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";

import { useAppDispatch } from "../../../store/store";
import { logOut } from "../../../store/reducers/auth/auth-reducer";

import settingsIcon from "../../../assets/images/header/settings-icon.svg";
import logoutIcon from "../../../assets/images/header/logout-icon.svg";
import helpIcon from "../../../assets/images/header/help-icon.svg";

import "./DropDownMenu.scss";

export const DropDownMenu = () => {
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };
  return (
    <Dropdown>
      <MenuButton className="trigger-button">
        <i className="trigger-button__icon"></i>
      </MenuButton>
      <Menu className="dropdown">
        {/* <MenuItem className="dropdown-item">
          <img src={settingsIcon} alt="settings-icon" />
          <div className="dropdown-item__text">
            <NavLink to="/dashboard" className="dropdown-item__text__link">
              Настройки
            </NavLink>
          </div>
        </MenuItem> */}
        <MenuItem className="dropdown-item" onClick={handleLogOut}>
          <img src={logoutIcon} alt="logout-icon" />
          <div className="dropdown-item__text">
            {/* <NavLink to="/dashboard" className="dropdown-item__text__link"> */}
            Выйти
            {/* </NavLink> */}
          </div>
        </MenuItem>

        {/* <MenuItem className="dropdown-item">
          <img src={helpIcon} alt="help-icon" />
          <div className="dropdown-item__text">
            <NavLink to="/dashboard" className="dropdown-item__text__link">
              Центр Поддержки
            </NavLink>
          </div>
        </MenuItem> */}
      </Menu>
    </Dropdown>
  );
};
