import { FC } from "react";

import CommonTableHeaderTitle from "./CommonTableHeaderTitle/CommonTableHeaderTitle";

import "./CommonTableHeader.scss";

const CommonTableHeader: FC<IProps> = ({ headerTitles }) => {
  const titlesCount = headerTitles.length;

  return (
    <div
      className="common-table-header"
      style={{
        gridTemplateColumns: `repeat(${titlesCount}, minmax(10rem, 1fr)`,
      }}
    >
      {headerTitles?.map((title: any) => {
        return <CommonTableHeaderTitle title={title} />;
      })}
    </div>
  );
};

interface IProps {
  headerTitles: any;
}
export default CommonTableHeader;
