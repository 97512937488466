import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { BaseThunk, SliceActions } from "../../store";
import { toast } from 'react-toastify';
import authApi from "../../../api/auth-api";

import {
  IRegistration,
  ISignInBody,
  ISignUpBody,
  IUserInfo,
  defaultUserValue,
} from "./auth-types";

import {
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
  getTokenLifeTime,
} from "../../functions";

const initialState: any = {
  isAuth: false,
  isLoading: false,
  profile: defaultUserValue,
  registration: {
    name: "",
    mail: "",
    phone: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading;
    },
    setIsAuth(state, action: PayloadAction<{ isAuth: boolean }>) {
      state.isAuth = action.payload.isAuth;
    },
    setProfile(state, action: PayloadAction<{ profile: IUserInfo }>) {
      state.profile = action.payload.profile;
    },

    setRegistration(
      state,
      action: PayloadAction<{ registration: IRegistration }>,
    ) {
      state.registration = {
        ...state.registration,
        ...action.payload.registration,
      };
    },
  },
});

const { reducer, actions } = authSlice;

export const { setIsAuth, setIsLoading, setProfile, setRegistration } = actions;

/* Thunk */
export const signUpOwner =
  (body: ISignUpBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await authApi.signUpOwner(body);

    if (status === 201) {
      // dispatch(setProfile({ profile: data }));
      toast(`Пользователь ${data.first_name} ${data.last_name} успешно зарегистрирован, можете войти в систему.`)
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

export const signInOwner =
  (body: ISignInBody): Thunk =>
  async (dispatch) => {
    dispatch(setIsLoading({ isLoading: true }));

    const { status, data } = await authApi.signInOwner(body);

    const { access_token, refresh_token } = data;

    if (status === 200) {
      dispatch(setIsAuth({ isAuth: true }));
      setAccessToken({ accessToken: access_token });
      setRefreshToken({ refreshToken: refresh_token });
    } else if (status === 401) {
      toast.error('Неверный логин или пароль', {  className: 'toast-error-container' })
    }

    dispatch(setIsLoading({ isLoading: false }));
  };

/*
	Инициализация при запуске
	Проверяет авторизацию
*/
export const initialize = (): Thunk => (dispatch) => {
  if (getTokenLifeTime() > 0) {
    dispatch(getMyProfile());
    dispatch(setIsAuth({ isAuth: true }));
  } else {
    dispatch(refreshToken());
  }
};

export const getMyProfile = (): Thunk => async (dispatch) => {
  dispatch(setIsLoading({ isLoading: true }));

  const result = await authApi.getMyProfile();
  const { status, data } = result;

  if (status === 200) {
    dispatch(setProfile({ profile: data }));
  } else if (status === 401) {
    dispatch(refreshToken());
    dispatch(getMyProfile());
  }

  dispatch(setIsLoading({ isLoading: false }));
};

export const logOut = (): Thunk => async (dispatch) => {
  dispatch(setIsLoading({ isLoading: true }));

  await authApi.logOutOwner();
  dispatch(setIsAuth({ isAuth: false }));
  removeRefreshToken();
  removeAccessToken();

  dispatch(setIsLoading({ isLoading: false }));
};

export const refreshToken = (): Thunk => async (dispatch) => {
  dispatch(setIsLoading({ isLoading: true }));

  const { status, data } = await authApi.refreshToken();
  const { access_token, refresh_token } = data;

  if (status === 200) {
    setAccessToken({ accessToken: access_token });
    setRefreshToken({ refreshToken: refresh_token });
    dispatch(getMyProfile());
  } else {
    logOut();
  }
  dispatch(setIsLoading({ isLoading: false }));
};

export const resetPofileState = (): Thunk => (dispatch) => {
  dispatch(setProfile({ profile: defaultUserValue }));
};

export default reducer;

type Actions = SliceActions<typeof actions>;
type Thunk = BaseThunk<Actions, void>;
