import React, { FC, useState } from "react";

import CheckboxField from "./CheckboxField";
import ModalButton from "./ModalButton";
import Button from "../../../Inputs/Button/Button";

import { modalContent } from "../../../../constants/constants";

import "../ModalPolicy.scss";

const OpenApiContent: FC<IProps> = ({
  width,
  handleApiPolicyClick,
  handleClose,
}) => {
  const [radioInputValue, setRadioInputValue] = useState("");

  const handleRadioButtonClick = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setRadioInputValue(evt.target.value);

  return (
    <div className="modal-policy modal-policy__content" style={{ width }}>
      <div className="modal-policy__title-container">
        <h5 className="modal-policy__modal-title">
          {modalContent["apiModal"].title}
        </h5>
        <p className="modal-policy__modal-subtitle">
          {modalContent["apiModal"].subtitle}
        </p>
      </div>

      <CheckboxField
        modalType="apiModal"
        radioInputValue={radioInputValue}
        handleRadioButtonClick={handleRadioButtonClick}
      />
      <div className="modal-policy__modal-button-container">
        <Button
          onClick={handleClose}
          label="Отмена"
          isDisabled={false}
          color="light-blue"
        />
        <ModalButton
          label={modalContent["apiModal"].buttonLabel}
          handleClick={() => handleApiPolicyClick(radioInputValue)}
          alignSelf="end"
        />
      </div>
    </div>
  );
};

export default OpenApiContent;

interface IProps {
  width: string | number;
  handleApiPolicyClick?: any;
  handleClose: () => void;
}
