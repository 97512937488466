import React, { FC } from "react";
import cn from "classnames";
import Icon from "../Icon/Icon";

import spiner from "../../assets/images/spiner.gif";

import "./Preloader.scss";

const Preloader: FC<IProps> = ({ isLoading }) => {
  return (
    <div className={cn("preloader-wrapper", { active: isLoading })}>
      <div className="preloader">
        <Icon icon={spiner} size="10" />
      </div>
    </div>
  );
};

export default Preloader;

interface IProps {
  isLoading: boolean;
}
