import { FC } from "react";

import logo from "../../../assets/images/logo.svg";

import "./LandingFooter.scss";

const Footer: FC = () => {
  return (
    <div className="footer">
      <div className="footer-header">
        <img className="footer-header__image" src={logo} alt="logo" />
      </div>
      <div className="footer-info">
        <div className="footer-info__contact">
          <h3 className="footer-info__contact__header">Контакты</h3>
          <div className="footer-info__contact__text">office@stl.im</div>
          <div className="footer-info__contact__text">+7 (4012) 38-93-73</div>
          <div>
            Адрес <br />
            236010, РФ, г. Калининград, ул. Комсомольская, д. 85
          </div>
        </div>
        <div className="footer-info__legal">
          Copyright © 2010-2023 «Смарт Техно ЛАБ» <br />
          Все права защищены.
        </div>
      </div>
    </div>
  );
};
export default Footer;
