import React, { FC, createContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./store/store";
import { ToastContainer } from "react-toastify";

import AppRouter from "./components/Routers/AppRouter";
import AuthRouter from "./components/Routers/AuthRouter";
import Preloader from "./components/Preloader/Preloader";

import { initialize } from "./store/reducers/auth/auth-reducer";
import { getIsAuth, getIsLoading } from "./store/reducers/auth/auth-selectors";
import Chatbot from "./components/Chatbot/Chatbot";

import { lang } from "./constants/i18n";
import { getLang } from "./store/functions";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

export const LangContext = createContext({ lang: lang[getLang() ?? "Рус"] });
export const LangProvider = LangContext.Provider;

const App: FC = () => {
  const isAuth = useSelector(getIsAuth);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  return (
    <LangProvider value={{ lang: lang[getLang() ?? "Рус"] }}>
      <div className="App">
        {isAuth ? <AppRouter /> : <AuthRouter />}
        <Preloader isLoading={isLoading} />
        <ToastContainer
          className="custom-toast-container"
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={true}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <Chatbot isAuth={isAuth} />
    </LangProvider>
  );
};

export default App;
