import { FC } from "react";
import cn from "classnames";

import "./InputText.scss";

const InputText: FC<Props> = ({
  label,
  placeholder,
  errorMsg,
  isError,
  value = "",
  onChange = () => {},
  useForm = {},
  type = "text",
}) => {
  return (
    <div className={cn("input-wrapper", { error: isError })}>
      <label className="input-label">{label}</label>
      <input
        {...useForm}
        type={type}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        placeholder={placeholder}
        className="input"
      />
      <div className="error-msg">{isError ? errorMsg : ""}</div>
    </div>
  );
};

export default InputText;

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  label: string;
  placeholder?: string;
  errorMsg?: string;
  isError?: boolean;
  type?: "text" | "password";
  useForm?: any;
}
