import { FC } from "react";

import data from "./Features-data";

import "./Features.scss";

const Features: FC = () => {
  return (
    <div className="features">
      <h2 className="features-header">Что мы предлагаем:</h2>
      <div className="features-container">
        {data.map((feature, index) => (
          <div className="features-item" key={index}>
            <div className="features-item__label">{feature.label}</div>
            <div className="features-item__description">
              {feature.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Features;
