import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Inputs/Button/Button";
import preview from "../../../assets/images/preview.png";
import arrowIcon from "../../../assets/media/icons/arrow-to-rigth.svg";
import "./ServicePreview.scss";

const ServicePreview: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="service-register-section">
        <div className="service-section">
          <h1 className="service-section__header">
            Сервис управления идентификацией и авторизацией
          </h1>
          <div className="service-section__text">
            Обеспечиваем единый доступ ко всем
            <br /> вашим продуктам и пользователям.
          </div>
        </div>
        <div className="register-section">
          <p className="register-section__text">
            Мы решаем проблему единого доступа ко всем продуктам за счет
            объединения всех ваших пользователей в одном месте.
          </p>
          <Button
            onClick={() => {
              navigate("/signup");
            }}
            label="Зарегистрироваться"
            size="big"
            color="blue"
            iconRight={arrowIcon}
          />
        </div>
      </div>
      <div className="preview-section">
        <img className="preview-section__image" src={preview} alt="logo" />
      </div>
    </>
  );
};
export default ServicePreview;
