import { FC } from "react";

import CommonTableHeader from "./CommonTableHeader/CommonTableHeader";
import CommonTableRow from "./CommonTableRow/CommonTableRow";

import "./CommonTable.scss";
import TablePagination from "../TablePagination/TablePagination";

/*
  чтобы ячейки отрисовывались в нужной колонке, 
  ключ поля из tableRows(что пришло с бэка) должен соответсовать
  значению key элемента из headerTitles(константа заголовков).
*/
const CommonTable: FC<IProps> = ({
  headerTitles,
  tableRows,
  pageType,
  pagination,
}) => {
  return (
    <div className="common-table">
      <CommonTableHeader headerTitles={headerTitles} />
      <div className="common-table-body">
        {tableRows?.map((row: any) => {
          return (
            <CommonTableRow
              row={row}
              headerTitles={headerTitles}
              pageType={pageType}
            />
          );
        })}
      </div>

      {pagination && <TablePagination pagination={pagination} />}
    </div>
  );
};

interface IProps {
  headerTitles: any;
  tableRows: any;
  pageType: string;
  pagination: any;
  handlePaginationChangePage?: any;
}
export default CommonTable;
