import { FC } from "react";
import cn from "classnames";

import CustomTableCell from "./CustomTableCell/CustomTableCell";

import "./CommonTableRow.scss";

const CommonTableRow: FC<IProps> = ({ row, headerTitles, pageType }) => {
  const cellsCount = headerTitles.length;

  return (
    <div
      className="common-table-row"
      style={{
        gridTemplateColumns: `repeat(${cellsCount}, minmax(10rem, 1fr)`,
      }}
    >
      {headerTitles?.map((headerTitle: any) => {
        const { key } = headerTitle;
        const isStyleBlackColor = key === "title";
        const isStyleJustifyCenter = key === "users_count";
        const isCellCustom = key === "status";

        return (
          <>
            {isCellCustom ? (
              <CustomTableCell
                rowData={row}
                cellType={key}
                pageType={pageType}
              />
            ) : (
              <div
                className={cn("common-table-cell", {
                  black: isStyleBlackColor,
                  justifyCenter: isStyleJustifyCenter,
                })}
              >
                {row[key]}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

interface IProps {
  row: any;
  headerTitles: any;
  pageType: string;
}
export default CommonTableRow;
