/*
  key заголовка должен соответсвовать ключу поля, 
  которое приходит с бэка
*/

export const poolsTableHeaders: IPoolsTableHeader[] = [
  { label: "Название", key: "title" },
  {
    key: "users_count",
    label: "Количество пользователей",
  },
  {
    key: "status",
    label: "Статус",
  },
];

interface IPoolsTableHeader {
  label: string, key: string
}