import React, { FC, useEffect, useState } from "react";
import cn from "classnames";

import Button from "../../../Inputs/Button/Button";
import ConfigTableField from "./ConfigTableField";
import ModalButton from "./ModalButton";
import ModalInput from "../../../Inputs/ModalInput/ModalInput";

import {
  IHttpPolicy,
  IModifiedHttpMethods,
  IOpenapiPolicyRequest,
  IPoliciesGroupRequest,
  ISwaggerSpec,
} from "../../../../store/reducers/policy/policy-types";
import {
  defaultOpenHttpAndApiMethods,
  inputModalContent,
} from "../../../../constants/constants";
import "../ModalPolicy.scss";

type endpointsOpenApiType = {
  [key: string]: { [key: string]: boolean };
};

const ConfigContent: FC<IProps> = ({
  width = "42.5rem",
  policyItem,
  modalType = "",
  policyItemFromFileOrUrl = null,
  title = null,
  onSubmit,
  handleClose,
}) => {
  const [inputValue, setInputValue] = useState("");

  const [openApiItems, setOpenApiItems] = useState<endpointsOpenApiType>({});
  const [isHttpLoading, setIsHttpLoading] = useState(true);
  const [modifiedHttpMethods, setModifiedHttpMethods] =
    useState<IModifiedHttpMethods>(defaultOpenHttpAndApiMethods);
  const [containerHeight, setContainerHeight] = useState<
    "small" | "middle" | "big"
  >("middle");
  const isOpenApiExist =
    ((policyItem && policyItem?.type !== "http") || !policyItem) &&
    modalType !== "http";
  const openApiFiltered = Object.entries(openApiItems).filter(([key, values]) =>
    key.includes(inputValue),
  );

  const getSortedOpenapiItems = (obj: ISwaggerSpec) => {
    const sortedEntries = Object.entries(obj).sort(([a], [b]) =>
      a.localeCompare(b),
    );
    return Object.fromEntries(sortedEntries);
  };

  useEffect(() => {
    if (policyItemFromFileOrUrl) {
      setOpenApiItems(getSortedOpenapiItems(policyItemFromFileOrUrl));
    }

    if (policyItem && "endpoints" in policyItem)
      setOpenApiItems(getSortedOpenapiItems(policyItem.endpoints));
  }, [policyItemFromFileOrUrl, policyItem]);

  const getCountBooleanOpenApiValues = (obj: endpointsOpenApiType) => {
    return Object.values(obj).reduce((count, innerObj) => {
      return count + Object.values(innerObj).filter((value) => true).length;
    }, 0);
  };

  // !edit state with methods from request
  useEffect(() => {
    if (policyItem && policyItem.type === "http") {
      setModifiedHttpMethods((prev) => {
        const newState = { ...prev };

        for (const key in policyItem) {
          if (policyItem.hasOwnProperty(key) && prev.hasOwnProperty(key)) {
            //todo
            // @ts-ignore
            newState[key] = policyItem[key];
          }
        }

        return newState;
      });
      setIsHttpLoading(false);
    }
    if (modalType === "http") setIsHttpLoading(false);
  }, [policyItem, modalType]);

  // !effect to change modal window height
  useEffect(() => {
    if (
      ((policyItem?.type === "http" || modalType === "http") &&
        Object.values(modifiedHttpMethods).length <= 4) ||
      (Object.values(openApiItems).length > 0 &&
        getCountBooleanOpenApiValues(openApiItems) <= 4) ||
      (Object.values(openApiItems).length === 0 &&
        policyItem &&
        policyItem.type !== "http")
    ) {
      setContainerHeight("small");
    } else if (
      (Object.values(openApiItems).length > 0 &&
        getCountBooleanOpenApiValues(openApiItems) <= 8) ||
      ((policyItem?.type === "http" || modalType === "http") &&
        Object.values(modifiedHttpMethods).length <= 8)
    ) {
      setContainerHeight("middle");
    } else {
      setContainerHeight("big");
    }
  }, [
    openApiItems,
    modifiedHttpMethods,
    policyItem,
    policyItemFromFileOrUrl,
    modalType,
  ]);

  const handleOpenapiRadioButtonChange = (
    label: string,
    title: string,
    newValue: boolean,
  ) => {
    setOpenApiItems((prev) => ({
      ...prev,
      [label]: {
        ...prev[label],
        [title]: newValue,
      },
    }));
  };

  const handleHTTPRadioButtonChange = (
    _: string,
    method: string,
    newValue: boolean,
  ) => {
    setModifiedHttpMethods((prev) => ({
      ...prev,
      [method]: newValue,
    }));
  };

  return (
    <div
      className="modal-policy modal-policy__with-input modal-policy modal-policy__with-input_type_config"
      style={{ width, paddingBottom: "1.25rem" }}
    >
      <h4 className="modal-policy__modal-title">
        {policyItem?.title ?? title}
      </h4>

      {(policyItem?.type === "openapi" ||
        (!policyItem && modalType !== "http")) && (
        <ModalInput
          placeholder={inputModalContent["openApiSettings"]["placeholder"]}
          value={inputValue}
          onChange={setInputValue}
        />
      )}

      <div
        className={`modal-policy__config-table-container ${cn(
          "config-container-height",
          [containerHeight],
        )}`}
      >
        <div className="modal-policy__config-child-container">
          {isOpenApiExist &&
            (openApiItems && Object.keys(openApiItems).length > 0 ? (
              openApiFiltered.map(([endpoint, methods]) => {
                return Object.entries(methods).map(([method, value], index) => {
                  return (
                    <ConfigTableField
                      key={`${endpoint}-${method}-${index}`}
                      title={endpoint}
                      radioInputValue={value}
                      method={method}
                      handleRadioButtonClick={handleOpenapiRadioButtonChange}
                    />
                  );
                });
              })
            ) : (
              <h1 className="modal-policy__waiting-title">
                Здесь пока нет данных
              </h1>
            ))}

          {((policyItem && policyItem?.type === "http") ||
            modalType === "http") &&
            (!isHttpLoading ? (
              Object.entries(modifiedHttpMethods).map(([key, value]) => {
                return (
                  <ConfigTableField
                    key={`${key}-${value}`}
                    title=""
                    method={key}
                    radioInputValue={value}
                    handleRadioButtonClick={handleHTTPRadioButtonChange}
                  />
                );
              })
            ) : (
              <h1 className="modal-policy__waiting-title">
                Загрузка данных...
              </h1>
            ))}
        </div>
      </div>

      <div className="modal-policy__modal-button-container">
        <Button
          onClick={handleClose}
          isDisabled={false}
          label="Отмена"
          color="light-blue"
          width="fit-content"
        />

        <ModalButton
          handleClick={
            policyItem?.type === "http" || modalType === "http"
              ? () => onSubmit(modifiedHttpMethods)
              : () => onSubmit(openApiItems)
          }
          label="Сохранить"
          marginTop="0.625rem"
          alignSelf="end"
        />
      </div>
    </div>
  );
};

export default ConfigContent;
interface IProps {
  width?: string | number;
  onSubmit: (arg: any) => void;
  modalType?: "http" | "";
  policyItem?:
    | IOpenapiPolicyRequest
    | IHttpPolicy
    | IPoliciesGroupRequest
    | null;
  policyItemFromFileOrUrl?: ISwaggerSpec | null;
  title?: string | null;
  handleClose: () => void;
}

//поиск полей в объекте ответа, начинающихся на "/"
// useEffect(() => {
//   const policies = Object.entries(policyItem).filter(([policy, _]) =>
//     policy.startsWith("/"),
//   );
//
//   if (policies.length > 0)
//     policies.forEach((policy) => {
//       setPolicyRoutes((prev) => ({
//         ...prev,
//         [policy[0]]: policy[1],
//       }));
//     });
// }, [policyItem]);
