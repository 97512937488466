import React, { FC, useState } from "react";
import { useAppDispatch } from "../../../../store/store";
import { useForm } from "react-hook-form";

import InputText from "../../../../components/Inputs/InputText/InputText";
import Button from "../../../../components/Inputs/Button/Button";

import { setRegistration } from "../../../../store/reducers/auth/auth-reducer";

import "./SignUpStepOne.scss";

const SignUpStepOne: FC<IPros> = ({ setIsStepTwo }) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur" });

  const handleContinue = () => {
    if (!isSubmitDisabled) {
      setIsStepTwo(true);
      dispatch(
        setRegistration({
          registration: { name, mail, phone },
        })
      );
    }
  };

  const isSubmitDisabled = !!errors.mail || !!errors.name || !!errors.phone;
  const isButtonDisabled = !(!!mail && !!name && !!phone);

  const phoneValidation = { pattern: /^[0-9()+-\s]+$/, required: true };
  const mailValidation = {
    pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
    required: true,
  };
  const nameValidation = {
    pattern: /^[a-zA-Zа-яА-Я\s]+$/,
    required: true,
  };

  return (
    <form onSubmit={handleSubmit(handleContinue)}>
      <InputText
        value={name}
        onChange={setName}
        label="Имя пользователя"
        placeholder="Введите имя пользователя"
        errorMsg="Неверное имя пользователя"
        isError={!!errors.name}
        useForm={{ ...register("name", nameValidation) }}
      />
      <InputText
        value={mail}
        onChange={setMail}
        label="Почта"
        placeholder="Введите рабочую почту"
        errorMsg="Неверная почта"
        isError={!!errors.mail}
        useForm={{ ...register("mail", mailValidation) }}
      />
      <InputText
        value={phone}
        onChange={setPhone}
        label="Телефон"
        placeholder="Введите имя пользователя"
        errorMsg="Неверный номер телефона"
        isError={!!errors.phone}
        useForm={{
          ...register("phone", phoneValidation),
        }}
      />
      <Button
        size="big"
        label="Далее"
        type="submit"
        isDisabled={isButtonDisabled}
      />
    </form>
  );
};

export default SignUpStepOne;

interface IPros {
  setIsStepTwo: (arg: boolean) => void;
}
