import React, { FC } from "react";
import cn from "classnames";
import {
  inputModalContent,
  modalContent,
} from "../../../../constants/constants";
import "../ModalPolicy.scss";

const CheckboxField: FC<IProps> = ({
  modalType,
  radioInputValue,
  handleRadioButtonClick,
  isRadioInput = true,
}) => {
  const content =
    modalType !== "newPolicy"
      ? modalContent[modalType]
      : inputModalContent[modalType];
  const padding = modalType !== "newPolicy" ? "narrow" : "wide";

  return (
    <fieldset
      className={`modal-policy__checkboxes-container ${cn("padding-container", [
        padding,
      ])}`}
    >
      {content?.checkBoxes?.map((item: string) => {
        return (
          <label
            htmlFor={`radioInput${item}`}
            className="modal-policy__label"
            key={item}
          >
            {isRadioInput && (
              <input
                type="radio"
                id={`radioInput${item}`}
                value={item}
                onChange={handleRadioButtonClick}
                checked={radioInputValue === item}
              />
            )}

            {!isRadioInput && typeof radioInputValue === "object" && (
              <input
                type="checkbox"
                id={`radioInput${item}`}
                value={item}
                onChange={handleRadioButtonClick}
                checked={radioInputValue[item] || false}
              />
            )}

            <span className="modal-policy__radiomark">{}</span>
            {item}
          </label>
        );
      })}
    </fieldset>
  );
};

export default CheckboxField;

interface IProps {
  modalType: "apiModal" | "httpModal" | "newPolicy";
  radioInputValue: string | { [key: string]: boolean };
  handleRadioButtonClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  isRadioInput?: boolean;
}
