import { FC, useEffect, useState } from "react";
import cn from "classnames";

import Icon from "../Icon/Icon";
import ModalContainer from "../Modals/ModalContainer";
import Feeback from "./Feeback/Feeback";
import Tickets from "./Tickets/Tickets";

import feedbackIcon from "../../assets/media/icons/feedback.svg";
import contactIcon from "../../assets/media/icons/contactUs.svg";
import rateIcon from "../../assets/media/icons/rateUs.svg";

import "./Chatbot.scss";

const Chatbot: FC<{ isAuth: boolean }> = ({ isAuth }) => {
  const [isOpen, setIsOpen] = useState<any>(false);
  const [active, setActive] = useState<any>(false);
  const [activeModal, setActiveModal] = useState<any>(0);

  const handleOpen = (activeModalNumber: number) => () => {
    setIsOpen(true);
    setActiveModal(activeModalNumber);
  };

  const handleClose = () => {
    setIsOpen(false);
    setActiveModal(0);
  };

  const handleActivate = () => {
    if (isAuth) {
      setActive(!active);
    } else {
      handleOpen(2)();
    }
  };

  const onCloseHandler = ({ target }: any) => {
    if (!(target as Element).closest(".chatbot-btn")) setActive(false);
  };

  window.addEventListener("click", onCloseHandler);
  useEffect(
    () => () => {
      window.removeEventListener("click", onCloseHandler);
    },
    []
  );

  return (
    <>
      <button
        type="button"
        className="chatbot-btn chatbot-btn--chatbot"
        onClick={handleActivate}
      >
        <Icon icon={feedbackIcon} />
      </button>
      <div className={cn("chatbot-container", { active })}>
        <button
          type="button"
          className="chatbot-btn chatbot-btn--rate"
          onClick={handleOpen(1)}
        >
          <Icon icon={rateIcon} />
        </button>
        <button
          type="button"
          className="chatbot-btn chatbot-btn--contact"
          onClick={handleOpen(2)}
        >
          <Icon icon={contactIcon} />
        </button>
        <ModalContainer isOpen={isOpen} handleClose={handleClose}>
          {activeModal === 1 && <Feeback handleClose={handleClose} />}
          {activeModal === 2 && <Tickets handleClose={handleClose} />}
        </ModalContainer>
      </div>
    </>
  );
};

export default Chatbot;
