import { FC, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import "./Navbar.scss";

import dashboardIcon from "../../assets/images/navbar/dashboard.svg";
import userspoolIcon from "../../assets/images/navbar/user-pools.svg";
import usersIcon from "../../assets/images/navbar/users.svg";
import policyIcon from "../../assets/images/navbar/policy.svg";
import docsIcon from "../../assets/images/navbar/docsIcon.svg";
import bindingIcon from "../../assets/media/icons/binding.svg";
import { LangContext } from "../../App";

export const Navbar: FC = () => {
  const { pathname } = useLocation();

  const isDashboardActive = pathname === "/signin";

  const {
    lang: {
      navmenu: { binding, dashboard, documentation, policy, pools, users },
    },
  } = useContext(LangContext);

  return (
    <nav className="navbar">
      <div className="navbar-item">
        <img
          src={dashboardIcon}
          alt="dashboard-icon"
          className="navbar-item__icon"
        />
        <p className="navbar-item__text">
          <NavLink
            to="/dashboard"
            className={cn("navbar-item__text__link", {
              active: isDashboardActive,
            })}
          >
            <span className="navbar-item__text__link__tag"></span>
            {dashboard}
          </NavLink>
        </p>
      </div>
      <div className="navbar-item">
        <img src={userspoolIcon} alt="user-pools-icon" />
        <p className="navbar-item__text">
          <NavLink to="/pools" className="navbar-item__text__link">
            <span className="navbar-item__text__link__tag"></span>
            {pools}
          </NavLink>
        </p>
      </div>
      <div className="navbar-item">
        <img src={usersIcon} alt="users-icon" />
        <p className="navbar-item__text">
          <NavLink to="/users" className="navbar-item__text__link">
            <span className="navbar-item__text__link__tag"></span>
            {users}
          </NavLink>
        </p>
      </div>
      <div className="navbar-item">
        <img src={policyIcon} alt="policy-icon" />
        <p className="navbar-item__text">
          <NavLink to="/policy" className="navbar-item__text__link">
            <span className="navbar-item__text__link__tag"></span>
            {policy}
          </NavLink>
        </p>
      </div>
      <div className="navbar-item">
        <img src={bindingIcon} alt="policy-icon" />
        <p className="navbar-item__text">
          <NavLink to="/binding" className="navbar-item__text__link">
            <span className="navbar-item__text__link__tag"></span>
            {binding}
          </NavLink>
        </p>
      </div>
      <div className="navbar-item">
        <img src={docsIcon} alt="policy-icon" />
        <p className="navbar-item__text">
          <NavLink to="/docs" className="navbar-item__text__link">
            <span className="navbar-item__text__link__tag"></span>
            {documentation}
          </NavLink>
        </p>
      </div>
    </nav>
  );
};

export default Navbar;
