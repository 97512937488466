import { Dispatch, FC, SetStateAction } from "react";
import cn from "classnames";

import "./Input.scss";

const Input: FC<Props> = ({
  className,
  placeholder,
  value,
  onChange,
  type = "text",
  id,
}) => {
  return (
    <input
      className={cn([className, "input-search"])}
      type={type}
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) => onChange(value)}
    />
  );
};

export default Input;

interface Props {
  id?: string;
  className?: string;
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  errorMsg?: string;
  isError?: boolean;
  type?: "text" | "password";
}
