import React, { FC } from "react";
import cn from "classnames";
import { useAppDispatch } from "../../../store/store";

import {
  bindPolicyToUser,
  unBindPolicyFromUser,
} from "../../../store/reducers/policy/policy-reducer";

import { IPoliciesBindPost } from "../../../store/reducers/policy/policy-types";

import "./BindCheckbox.scss";

const BindCheckbox: FC<IProps> = ({ isActive, policy, user, selectedPool }) => {
  const dispatch = useAppDispatch();

  const handleBindPolicy = () => {
    const body: IPoliciesBindPost = { policies: [policy.id], users: [user.id] };
    dispatch(bindPolicyToUser({ id: selectedPool, body }));
  };
  const handleUnbindPolicy = () => {
    const body: IPoliciesBindPost = { policies: [policy.id], users: [user.id] };
    dispatch(unBindPolicyFromUser({ id: selectedPool, body }));
  };

  return (
    <div
      className="bind-checkbox-wrapper"
      onClick={isActive ? handleUnbindPolicy : handleBindPolicy}
    >
      <div className={cn("bind-checkbox", { active: isActive })}></div>
    </div>
  );
};

export default BindCheckbox;

/*
  todo доделать типы
*/
interface IProps {
  isActive: boolean;
  policy: any;
  user: any;
  selectedPool: any;
}
