import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SearchHeader from "../../components/PagesHeader/SearchHeader";
import PageTable from "../../components/PageTable/PageTable";
import ModalNewPolicy from "../../components/Modals/ModalPolicy/ModalNewPolicy";
import Preloader from "../../components/Preloader/Preloader";
import ModalConfigureAndEditPolicy from "../../components/Modals/ModalPolicy/ModalConfigureAndEditPolicy";
import ModalConfigureGroupPolicy from "../../components/Modals/ModalPolicy/ModalConfigureGroupPolicy";
import UpdateOpenapiPolicyModal from "../../components/Modals/ModalPolicy/ModalContent/UpdateOpenapiPolicyModal";

import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  clearPolicyById,
  clearPolicyBySwagger,
  requestPolicies,
  requestPolicyById,
  setFilters,
  setIsOpenApiUpdateSuccess,
  updatePoliciesStatuses,
} from "../../store/reducers/policy/policy-reducer";
import { requestPools } from "../../store/reducers/pools/pools-reducer";
import {
  getIsLoading,
  getPolicies,
  getPolicyById,
} from "../../store/reducers/policy/policy-selectors";
import {
  IOpenapiPolicyRequest,
  IPoliciesGroupRequest,
} from "../../store/reducers/policy/policy-types";
import {
  RowStatus,
  TypePageTable,
  filtersPolicy,
} from "../../constants/constants";

import "./Policy.scss";

const modalInitialState = {
  isNewPolicyModalOpen: false,
  isConfigurePolicyModalOpen: false,
  isEditPolicyModalOpen: false,
  isConfigureGroupPolicyModalOpen: false,
  isUpdateOpenapiPolicyModalOpen: false,
};

const Policy: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const {
    policies: { policies, pagination },
    filters,
  } = useAppSelector(getPolicies);
  const policiesById = useSelector(getPolicyById);
  const [isModalOpen, setIsModalOpen] = useState(modalInitialState);

  const policyPagination = {
    ...pagination,
    current_page: filters.current_page,
    per_page: filters.per_page,
    setFilters,
  };

  const onCreatePolicy = () =>
    setIsModalOpen(() => ({
      ...modalInitialState,
      isNewPolicyModalOpen: true,
    }));

  const handleNewPolicyModalClose = () => {
    setIsModalOpen(modalInitialState);
    dispatch(clearPolicyBySwagger());
  };

  const handleConfigurePolicyModalClose = () => {
    dispatch(clearPolicyById({ policyById: null }));
    setIsModalOpen((prev) => ({
      ...prev,
      isConfigurePolicyModalOpen: false,
    }));
  };

  const handleConfigureGroupPolicyModalClose = () => {
    dispatch(clearPolicyById({ policyById: null }));
    setIsModalOpen((prev) => ({
      ...prev,
      isConfigureGroupPolicyModalOpen: false,
    }));
  };

  const handleUpdateOpenapiPolicyModalClose = () => {
    dispatch(clearPolicyById({ policyById: null }));
    dispatch(setIsOpenApiUpdateSuccess({ isOpenApiUpdateSuccess: false }));

    setIsModalOpen((prev) => ({
      ...prev,
      isUpdateOpenapiPolicyModalOpen: false,
    }));
  };

  const handleEditPolicyModalClose = () => {
    dispatch(clearPolicyById({ policyById: null }));
    setIsModalOpen((prev) => ({
      ...prev,
      isEditPolicyModalOpen: false,
    }));
  };

  const onEditPolicy = (id: string) => {
    setIsModalOpen(() => ({
      ...modalInitialState,
      isEditPolicyModalOpen: true,
    }));

    dispatch(requestPolicyById({ id }));
  };

  const onConfigPolicy = (id: string) => {
    setIsModalOpen(() => ({
      ...modalInitialState,
      isConfigurePolicyModalOpen: true,
    }));

    dispatch(requestPolicyById({ id }));
  };

  const handleUpdateOpenApiFromUrlOrSpec = (
    openApiPolicy: IOpenapiPolicyRequest
  ) => {
    setIsModalOpen(() => ({
      ...modalInitialState,
      isUpdateOpenapiPolicyModalOpen: true,
    }));

    dispatch(requestPolicyById({ id: openApiPolicy.id }));
  };

  const onGroupConfigPolicy = (id: string) => {
    setIsModalOpen(() => ({
      ...modalInitialState,
      isConfigureGroupPolicyModalOpen: true,
    }));

    dispatch(requestPolicyById({ id }));
  };

  const onStatusPolicy = (row: RowStatus) => {
    if (row.policyId) {
      dispatch(
        updatePoliciesStatuses({
          policy_ids: [row.policyId],
          new_status: row.status as "ACTIVE" | "INACTIVE" | "",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(requestPolicies(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch(requestPools({ per_page: 1000 }));
  }, [dispatch]);

  return (
    <div className="policy page">
      {policies && pagination && (
        <>
          <SearchHeader
            primaryFilters={{
              type: "policy_types",
              label: "Фильтр",
              searchParam: "title",
              list: filtersPolicy,
            }}
            setFilters={setFilters}
            button={{
              label: "Создать policy",
              onClick: onCreatePolicy,
            }}
          />

          <PageTable
            dataTable={policies}
            pagination={pagination}
            type={TypePageTable.policy}
            handleEdit={onEditPolicy}
            handleConfig={onConfigPolicy}
            handleGroupConfig={onGroupConfigPolicy}
            handleStatus={onStatusPolicy}
            handleUpdateOpenApiFromUrlOrSpec={handleUpdateOpenApiFromUrlOrSpec}
            policyPagination={policyPagination}
          />

          {/*New policy modals*/}
          <ModalNewPolicy
            isModalOpen={isModalOpen.isNewPolicyModalOpen}
            handleClose={handleNewPolicyModalClose}
          />

          {/*Configure policy modals*/}
          {policiesById && (
            <ModalConfigureAndEditPolicy
              isModalOpen={isModalOpen.isConfigurePolicyModalOpen}
              handleClose={handleConfigurePolicyModalClose}
              modalType="configurePolicy"
              policiesById={policiesById}
            />
          )}

          {/*Configure group policy modal*/}
          {policiesById && policiesById.type === "group" && (
            <ModalConfigureGroupPolicy
              width="36rem"
              handleClose={handleConfigureGroupPolicyModalClose}
              isModalOpen={isModalOpen.isConfigureGroupPolicyModalOpen}
              actualPolicies={policiesById as IPoliciesGroupRequest}
            />
          )}

          {/*Edit policy modals*/}
          {policiesById && (
            <ModalConfigureAndEditPolicy
              isModalOpen={isModalOpen.isEditPolicyModalOpen}
              handleClose={handleEditPolicyModalClose}
              policiesById={policiesById}
              modalType="editPolicy"
            />
          )}

          {/*Update Openapi Policy Modal*/}
          {policiesById && policiesById.type === "openapi" && (
            <UpdateOpenapiPolicyModal
              width="36rem"
              isModalOpen={isModalOpen.isUpdateOpenapiPolicyModalOpen}
              handleClose={handleUpdateOpenapiPolicyModalClose}
            />
          )}
        </>
      )}

      <Preloader isLoading={isLoading} />
    </div>
  );
};

export default Policy;
