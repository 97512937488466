import { FC, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import LoadTable from "./LoadTable/LoadTable";
import DashboardCard from "./DashboardCard/DashboardCard";

import { getMe } from "../../store/reducers/auth/auth-selectors";

import "./Dashboard.scss";
import { IUserInfo } from "../../store/reducers/auth/auth-types";
import { useAppDispatch } from "../../store/store";
import { getMyProfile } from "../../store/reducers/auth/auth-reducer";
import { LangContext } from "../../App";

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();

  const {
    lang: {
      dashboard: { welcomes, policy, users, pools },
    },
  } = useContext(LangContext);

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);

  const profile = useSelector(getMe);

  const { first_name, second_name, policies_count, pools_count, users_count } =
    profile as IUserInfo;

  return (
    <div className="dashboard page">
      <div className="dashboard-header">
        <h3
          style={{ marginBottom: "3rem" }}
        >{`${welcomes}, ${first_name} ${second_name}!`}</h3>
      </div>
      <div className="dashboard-cards">
        <DashboardCard title={pools} value={pools_count} color="blue" />
        <DashboardCard title={users} value={users_count} />
        <DashboardCard title={policy} value={policies_count} />
      </div>
      <LoadTable />
    </div>
  );
};

export default Dashboard;
