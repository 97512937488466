import * as React from "react";
import {
  useAutocomplete,
  UseAutocompleteProps,
} from "@mui/base/useAutocomplete";
import { Button } from "@mui/base/Button";
import { Popper } from "@mui/base/Popper";
import { styled } from "@mui/system";
import { unstable_useForkRef as useForkRef } from "@mui/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import "./Autocomplete.scss";
import { IUserPool } from "../../store/reducers/pools/pools-types";
import { Dispatch, FC, SetStateAction } from "react";
import cn from "classnames";

const Autocomplete = React.forwardRef(function Autocomplete(
  props: any,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    value,
    setValue,
    options,
    disableClearable = false,
    disabled = false,
    readOnly = false,
    isInputValid = false,
    placeholder,
    ...other
  } = props;

  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getClearProps,
    getListboxProps,
    getOptionProps,
    dirty,
    id,
    popupOpen,
    focused,
    anchorEl,
    setAnchorEl,
    groupedOptions,
  } = useAutocomplete({
    ...props,
    options,
    getOptionLabel: (option: any) => option.title ?? option.username,
    value,
    onChange: (event, newValue) => setValue(newValue),
    componentName: "BaseAutocompleteIntroduction",
  });

  const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;

  const rootRef = useForkRef(ref, setAnchorEl);

  return (
    <div className="autocomplete">
      <div
        {...getRootProps(other)}
        ref={rootRef}
        className={`${focused ? "focused" : undefined} autocomplete__root ${cn(
          "input-valid",
          [isInputValid.toString()]
        )}`}
      >
        <input
          className="autocomplete__input"
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          {...getInputProps()}
        />
        {hasClearIcon && (
          <Button className="autocomplete__clearIndicator" {...getClearProps()}>
            <ClearIcon />
          </Button>
        )}
        <Button
          {...getPopupIndicatorProps()}
          className={`${
            popupOpen ? "popupOpen" : undefined
          } autocomplete__popup-indicator`}
        >
          <ArrowDropDownIcon />
        </Button>
      </div>

      <div className="autocomplete__error-container">
        <span
          className={`autocomplete__error-message ${cn(
            "autocomplete-valid-message",
            [isInputValid.toString()]
          )}`}
        >
          Это поле обязательно для заполнения
        </span>
      </div>

      {anchorEl ? (
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          slots={{
            root: StyledPopper,
          }}
          modifiers={[
            { name: "flip", enabled: false },
            { name: "preventOverflow", enabled: false },
          ]}
        >
          <ul className="autocomplete__listbox" {...getListboxProps()}>
            {(groupedOptions as typeof options).map(
              (option: any, index: any) => {
                const optionProps = getOptionProps({ option, index });

                return (
                  <li className="autocomplete__styled-option" {...optionProps}>
                    {option.title ?? option.username}
                  </li>
                );
              }
            )}

            {groupedOptions.length === 0 && (
              <li className="autocomplete__no-options">No results</li>
            )}
          </ul>
        </Popper>
      ) : null}
    </div>
  );
});

const StyledPopper = styled("div")`
  position: absolute;
  z-index: 9999;
  width: 26.25rem;
`;

const AutocompleteIntroduction: FC<IProps> = ({
  values,
  value,
  setValue,
  isInputValid,
  placeholder,
}) => {
  return (
    <Autocomplete
      placeholder={placeholder}
      options={values}
      value={value}
      setValue={setValue}
      isInputValid={isInputValid}
    />
  );
};

export default AutocompleteIntroduction;

interface IProps {
  values: any;
  value: any;
  setValue: Dispatch<SetStateAction<IUserPool | null>>;
  isInputValid?: boolean;
  placeholder?: string;
}
