import { FC } from "react";
import cn from "classnames";

import Icon from "../../../Icon/Icon";

import "./FeedbackButton.scss";

interface IProps {
  icon: any;
  isActive: boolean;
  onClick: any;
}

const FeedbackButton: FC<IProps> = ({ icon, isActive, onClick }) => {
  return (
    <button
      className={cn("feedback-btn", { active: isActive })}
      onClick={onClick}
    >
      <Icon icon={icon} />
    </button>
  );
};

export default FeedbackButton;
