import { FC } from "react";

import "./TriangleCell.scss";

const TriangleCell: FC = () => (
  <div className="binding-table__cell triangle-cell">
    <div className="triangle-cell__policy">
      <p className="triangle-cell__text triangle-cell__text--policy">Policy</p>
      <p className="triangle-cell__text triangle-cell__text--user">
        Пользователь
      </p>
    </div>
  </div>
);

export default TriangleCell;
