const data: ISwiperCard[] = [
  {
    label: "Компаниям, занимающимся web-разработкой, стартаперам",
    description:
      "У них есть шанс упростить управление доступом и сосредоточиться на развитии продукта.",
  },
  {
    label: "Компаниям, работающим с государственными проектами",
    description:
      "Наш продукт подойдет для вас, помогая улучшить безопасность и управление доступом, соответствуя законодательству Российской Федерации",
  },
  {
    label: "Фрилансерам и большим компаниям",
    description: "Наше решение даёт возможность экономить ваши ресурсы.",
  },
  {
    label:
      "Компаниям, предоставляющим облачные решения, а также компаниям с микро-сервисной архитектурой.",
    description:
      "Наш сервис адаптирован к разнообразным архитектурам,гарантируя беспроблемную интеграцию.",
  },
];
export default data;

export interface ISwiperCard {
  label: string;
  description: string;
}
