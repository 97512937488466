import { FC } from "react";
import cn from "classnames";
import "./ModalUserPoolsInputs.scss";
import successIcon from "../../../../assets/media/icons/success.svg";
import { statusParser } from "../../../../constants/constants";
// import Icon from "../../../Icon/Icon";
// import Button from "../../../Inputs/Button/Button";
import { IUserPool } from "../../../../store/reducers/pools/pools-types";
import Icon from "../../../../components/Icon/Icon";
import Button from "../../../../components/Inputs/Button/Button";

const ModalUserPoolsContent: FC<IProps> = ({
  currentUserPool,
  userPool = null,
  width,
  onClick,
  isLoading = false,
}) => {
  let userPoolStatus: string = "";

  if (currentUserPool && "status" in currentUserPool) {
    userPoolStatus = currentUserPool.status;
  }

  const { color, label } = statusParser(userPoolStatus);

  return (
    <div className="modal-content" style={{ width }}>
      {isLoading ? (
        <h1 className="modal-policy__loading">Загрузка данных...</h1>
      ) : (
        <>
          {userPoolStatus && (
            <>
              <h5
                className={`modal-content__modal-title ${cn("modal-title", [
                  color,
                ])}`}
              >
                {label}
              </h5>
              <div className="modal-policy__container">
                <h6 className="modal-content__title">Детали</h6>

                <p className="modal-content__subtitle">
                  {currentUserPool?.status_description ||
                    "User pool успешно создан"}
                </p>
              </div>
              <div className="modal-policy__container">
                <h6 className="modal-content__title">User pool UUID</h6>
                <p className="modal-content__subtitle">{currentUserPool?.id}</p>
              </div>
            </>
          )}

          {userPool && (
            <>
              <h5
                className={`modal-content__modal-title ${cn("modal-title", [
                  "success",
                ])}`}
              >
                User pool успешно создан
                {<Icon icon={successIcon} width={1.25} height={1.25} />}
              </h5>
              <div className="modal-policy__container">
                <h6 className="modal-content__title">
                  UUID созданного user pool
                </h6>
                <p className="modal-content__subtitle">{userPool}</p>
              </div>
            </>
          )}

          <Button
            onClick={onClick}
            color="blue"
            isDisabled={false}
            label="Ok"
            width="fixed"
            alignSelf="end"
          />
        </>
      )}
    </div>
  );
};

export default ModalUserPoolsContent;

interface IProps {
  currentUserPool?: IUserPool | null;
  userPool?: string | null;
  width?: string | number;
  onClick: () => void;
  isLoading?: boolean;
}
