import React, { FC, useEffect, useState } from "react";

import Button from "../../../Inputs/Button/Button";
import ModalButton from "./ModalButton";

import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { GroupSelectedItemsType } from "../../../../store/reducers/policy/policy-types";
import { requestPolicies } from "../../../../store/reducers/policy/policy-reducer";
import { getPolicies } from "../../../../store/reducers/policy/policy-selectors";
import "../ModalPolicy.scss";

const GroupContent: FC<IProps> = ({
  width,
  currentUserPoolId,
  handleClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState<
    GroupSelectedItemsType[] | []
  >([]);
  const {
    policies: { policies },
  } = useAppSelector(getPolicies);
  const actualPolicies = policies?.filter(
    (policy: any) => policy.user_pool_id === currentUserPoolId
  );

  const handleCheckboxChange = (body: GroupSelectedItemsType) => {
    const { id, title, user_pool_id, type } = body;

    if (selectedItems.some((item: any) => item.id === id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== id));
    } else {
      setSelectedItems([...selectedItems, { id, title, user_pool_id, type }]);
    }
  };

  useEffect(() => {
    dispatch(requestPolicies({ per_page: 1000 }));
  }, [dispatch]);

  return (
    <div
      className="modal-policy modal-policy__content modal-policy__content_type_group"
      style={{ width }}
    >
      <h4 className="modal-policy__modal-title">Выберете policy</h4>

      <div className="modal-policy__group-title-container">
        <h6 className="modal-policy__table-title">Название</h6>
        <h6 className="modal-policy__table-title">Тип</h6>
      </div>

      <div className="modal-policy__field-container">
        {actualPolicies && actualPolicies.length > 0 ? (
          actualPolicies.map((policy: any) => {
            const { id, title, user_pool_id, type } = policy;

            return (
              <div className="modal-policy__group-item-container">
                <label
                  htmlFor={`checkbox-${id}-${title}`}
                  className="modal-policy__label modal-policy__label_type_group"
                  key={id}
                >
                  <input
                    type="checkbox"
                    id={`checkbox-${id}-${title}`}
                    value={id}
                    onChange={() =>
                      handleCheckboxChange({
                        id,
                        user_pool_id,
                        title,
                        type,
                      } as GroupSelectedItemsType)
                    }
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === id
                    )}
                  />
                  <span className="modal-policy__radiomark">{}</span>
                  {title}
                </label>
                <p className="modal-policy__policy-type">{type}</p>
              </div>
            );
          })
        ) : (
          <h6 className="modal-policy__waiting-title" style={{ width: "100%" }}>
            Нет доступных policy
          </h6>
        )}
      </div>

      <div className="modal-policy__modal-button-container">
        <Button
          onClick={handleClose}
          isDisabled={false}
          label="Отмена"
          color="light-blue"
          width="fit-content"
        />

        <ModalButton
          handleClick={() => onSubmit(selectedItems)}
          label="Сохранить"
          marginTop="0.625rem"
          alignSelf="end"
        />
      </div>
    </div>
  );
};

export default GroupContent;

interface IProps {
  width: string | number;
  handleClose: () => void;
  onSubmit: (arg: GroupSelectedItemsType[]) => void;
  currentUserPoolId: string;
}
