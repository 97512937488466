import { FC, useState } from "react";

import SignUpStepOne from "./SignUpStepOne/SignUpStepOne";
import SignUpStepTwo from "./SignUpStepTwo/SignUpStepTwo";

import "./SignUnForm.scss";

const SignUnForm: FC = () => {
  const [isStepTwo, setIsStepTwo] = useState<boolean>(false);

  return (
    <div className="sign-up-form">
      <h2>Регистрация</h2>
      {isStepTwo ? (
        <SignUpStepTwo />
      ) : (
        <SignUpStepOne setIsStepTwo={setIsStepTwo} />
      )}
    </div>
  );
};

export default SignUnForm;
